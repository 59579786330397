import { useState, useEffect } from "react";
import JSZip from "jszip";
import axios from "axios";

const getToken = async (retryCount = 0) => {
  try {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser && "token" in storedUser) {
      return storedUser.token;
    } else if (retryCount < 3) {
      console.warn("Retrying to fetch token...");
      return getToken(retryCount + 1);
    }
    console.warn("No token found in localStorage.");
    return null;
  } catch (error) {
    console.error("Error retrieving token from localStorage:", error);
    return null;
  }
};

const getUserId = async (retryCount = 0) => {
  try {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser && "userId" in storedUser) {
      return storedUser.userId;
    } else if (retryCount < 3) {
      console.warn("Retrying to fetch token...");
      return getToken(retryCount + 1);
    }
    console.warn("No token found in localStorage.");
    return null;
  } catch (error) {
    console.error("Error retrieving token from localStorage:", error);
    return null;
  }
};

export const useEditorSave = () => {
  const [editorContent, setEditorContent] = useState(null);
  const [editorModified, setEditorModified] = useState(false);

  const handleEditorData = (data) => {
    setEditorContent(data);
  };

  const handleChangeInEditor = () => {
    setTimeout(() => {
      setEditorModified(true);
  }, 100);   
  }

  const saveContentAsDocx = () => {
    if (editorContent) {
      editorContent.save("document", "Docx");
    }
  };

  const saveContentAsPdf = () => {
    editorContent.saveAsBlob("Docx").then(async (exportedDocument) => {
      // Create a new JSZip instance and load the exported Blob
      const zip = new JSZip();
      const zipData = await zip.loadAsync(exportedDocument);

      const zipBlob = await zipData.generateAsync({ type: "blob" });

      // Create a new FormData object
      const formData = new FormData();
      formData.append("zipFile", zipBlob, "exported_files.zip");
      formData.append("userId", await getUserId());

      const url = `${process.env.REACT_APP_API_URL}convert/pdf`;

      // Make the POST request to the API using Axios
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type to "multipart/form-data"
        },
        responseType: "blob",
      });

      // Check the response status
      if (response.status === 200) {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "content.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      } else {
        console.error("Upload failed. Status:", response.status);
      }
    });
  };

  const saveContentAsHtml = () => {
    //Export the document as Blob object.
    editorContent.saveAsBlob("Docx").then(async (exportedDocument) => {
      // Create a new JSZip instance and load the exported Blob
      const zip = new JSZip();
      const zipData = await zip.loadAsync(exportedDocument);

      const zipBlob = await zipData.generateAsync({ type: "blob" });

      // Create a new FormData object
      const formData = new FormData();
      formData.append("zipFile", zipBlob, "exported_files.zip");
      formData.append("userId", await getUserId());

      const url = `${process.env.REACT_APP_API_URL}convert`;

      // Make the POST request to the API using Axios
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type to "multipart/form-data"
        },
      });

      // Check the response status
      if (response.status === 200) {
        const htmlContent = response.data.html;

        // const sanitizedHTML = sanitizeHtml(htmlContent); // Sanitize the HTML content if needed
        const blob = new Blob([htmlContent], { type: "text/html" });
        const url = URL.createObjectURL(blob);

        // Create a temporary anchor element to trigger the download
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = "generated_html.html";

        // Append the anchor element to the DOM, click it, and remove it after the download
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        // Revoke the Blob URL to release resources
        URL.revokeObjectURL(url);
      } else {
        console.error("Upload failed. Status:", response.status);
      }
    });
  };

  return {
    editorContent,
    handleEditorData,
    saveContentAsDocx,
    saveContentAsPdf,
    saveContentAsHtml,
    handleChangeInEditor,
    editorModified
  };
};
