// socketService.js
import io from "socket.io-client";

const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URI;

// Modified to return both socket and cleanup function
export const initializeSocketConnection = (onConnected, onSomeEvent) => {
  const socket = io(SOCKET_SERVER_URL, {
    transports: ["websocket"],
  });

  socket.on("connect", () => {
    console.log("Successfully connected to the socket server");
  });

  socket.on("connect_error", (error) => {
    console.error("Connection error:", error);
  });

  // Return the socket instance and a cleanup function
  return {
    socket,
    cleanup: () => {
      socket.off("connect");
      if (typeof onSomeEvent === "function") {
        socket.off("someEvent");
      }
      socket.disconnect();
    },
  };
};
