import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getAllChatsByChatId, deleteAssetsFromProject } from "utils/network.js";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CustomizedDialogs from "./CustomizedDilogs"; // Ensure correct import path
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Grid from "@mui/material/Grid";
import BackgroundAnimation from "components/Background/BackgroundAnimations";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SideDrawer from "components/Drawers/SideDrawer";

const VoiceNarrationContents = () => {
  const [narrations, setNarrations] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedNarration, setSelectedNarration] = useState(null);
  const { chatId } = useParams();
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const toggleSideDrawer = () => {
    setSideDrawerOpen((prevState) => !prevState);
  };

  useEffect(() => {
    async function fetchData() {
      const chatResponse = await getAllChatsByChatId(chatId);
      if (chatResponse.data && chatResponse.data[0]) {
        const chatData = chatResponse.data[0];
        const chatNarrations = chatData.chats
          .filter(
            (chat) =>
              chat.audioData &&
              chat.audioData.data &&
              chat.audioData.data.length > 0
          )
          .map((chat, index) => ({
            audioData: { url: chat.audioData.data },
            type: "chat",
            index,
          }));

        const docAudioNarration =
          chatData.docAudioData &&
          chatData.docAudioData.data &&
          chatData.docAudioData.data.length > 0
            ? [
                {
                  audioData: { url: chatData.docAudioData.data },
                  type: "docAudioData",
                },
              ]
            : [];

        const docAudioUrlsNarrations = chatData.docAudioUrls
          .filter((audioUrl) => audioUrl.url && audioUrl.url.length > 0)
          .map((audioUrl, index) => ({
            audioData: { url: audioUrl.url },
            type: "docAudioUrls",
            index,
          }));

        const moreDocAudioUrlsNarrations = chatData.docAudioUrls
          .filter((audioUrl) => audioUrl.url.url && audioUrl.url.url.length > 0)
          .map((audioUrl, index) => ({
            audioData: { url: audioUrl.url.url },
            type: "docAudioUrls",
            index,
          }));

        const combinedNarrations = [
          ...chatNarrations,
          ...docAudioNarration,
          ...docAudioUrlsNarrations,
          ...moreDocAudioUrlsNarrations,
        ];

        // console.log(combinedNarrations);

        setNarrations(combinedNarrations);
      }
    }
    fetchData();
  }, [chatId]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (url) => {
    const type = "audio";
    await deleteAssetsFromProject(url, chatId, type);
    setNarrations((prev) => prev.filter((item) => item.audioData.url !== url));
  };

  return (
    <BackgroundAnimation>
      <div
        style={{ position: "relative", minHeight: "100vh", overflowY: "auto" }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-start"
          padding={2}
          sx={{
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            position: "sticky",
            top: 0,
            zIndex: 1000,
          }}
          className="go-back"
        >
          <Link to={`/c/library/${chatId}`} style={{ color: "#FFFFFF" }}>
            <ArrowBackIcon sx={{ width: "2em", height: "3em" }} />
          </Link>
          <Typography
            variant="h2"
            component="div"
            sx={{ flexGrow: 1, color: "white" }}
          >
            Voice Narrations
          </Typography>
          <IconButton
            aria-label="draw"
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 28 },
              color: "white",
              cursor: "pointer",
            }}
            onClick={toggleSideDrawer}
          >
            <PersonIcon />
          </IconButton>
        </Stack>
        <Grid
          container
          spacing={4}
          style={{
            paddingLeft: "2vw", // Adds left margin to the entire grid container
            paddingRight: "2vw",
            height: "calc(100vh - 80px)",
            paddingBottom: "16px",
            overflowY: "auto",
          }}
        >
          {narrations.length === 0 ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                color: "white",
              }}
            >
              <div>No Data!</div>
            </Grid>
          ) : (
            narrations.map((narration, index) => (
              <Grid item key={index}>
                <Button
                  variant="outlined"
                  style={{
                    borderColor: "#FFFFFF",
                    color: "#FFFFFF",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setOpen(true);
                    setSelectedNarration(narration);
                  }}
                >
                  <VolumeUpIcon
                    style={{
                      color: "#FFFFFF",
                      fontSize: "3em",
                      paddingBottom: "10px",
                    }}
                  />
                  {narration.type} Narration {index + 1}
                </Button>
                {selectedNarration === narration && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 16,
                      left: "50%",
                      transform: "translateX(-50%)",
                      width: "70%",
                    }}
                  >
                    <CustomizedDialogs
                      open={open}
                      handleClose={handleClose}
                      handleDelete={handleDelete}
                      audioData={narration.audioData.url}
                    />
                  </div>
                )}
              </Grid>
            ))
          )}
        </Grid>
        <SideDrawer
          isOpen={sideDrawerOpen}
          onOpen={toggleSideDrawer}
          onClose={toggleSideDrawer}
        />
      </div>
    </BackgroundAnimation>
  );
};

export default VoiceNarrationContents;
