import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import Art from "assets/images/HorizontalScrollBaricons/Picasso.png";
import Image from "assets/images/HorizontalScrollBaricons/AI_image.png";
import Music from "assets/images/HorizontalScrollBaricons/AI_music.png";
import Video from "assets/images/HorizontalScrollBaricons/AI_video.png";
import Voice from "assets/images/HorizontalScrollBaricons/AI_voice.png";
import Writer from "assets/images/HorizontalScrollBaricons/AI_writer.png";

const HorizontalScrollIcons = ({
  handlePromptModal,
  handleVoiceModal,
  handleAIMusic,
  handleImageSettingsModal,
  handleVideoSettingsModal,
  chatId
}) => {
  const handleClick = () => {
    // console.log("chatID", chatId);
  }

  const iconStyle = {
    width: 50,
    height: 50,
    border: '1px solid #011636',
    borderRadius: '50%',
    transition: 'transform 0.3s ease', // Smooth transition for the transform property
  };

  const iconHoverStyle = {
    transform: 'scale(1.4)', // Magnify by 20% on hover
  };

  return (
    <Stack direction="row"  sx={{ overflowX: "auto", p: "2vh 2vw", gap: "0.3vw"  }}>
     <Tooltip
  title={<span style={{ fontSize: '1.2rem', padding: '10px' }}>AI Writer</span>} // Increase font size and padding
  arrow
  
>
  <IconButton onClick={() => handlePromptModal()}>
    <img
      src={Writer}
      alt="Icon 6"
      style={iconStyle}
      onMouseOver={e => e.currentTarget.style.transform = iconHoverStyle.transform}
      onMouseOut={e => e.currentTarget.style.transform = 'scale(1)'}
    />
  </IconButton>
</Tooltip>


      <Tooltip title={<span style={{ fontSize: '1.2rem', padding: '10px' }}>AI Image</span>} arrow>
        <IconButton onClick={() => handleImageSettingsModal("default")}>
          <img
            src={Image}
            alt="Icon 2"
            style={iconStyle}
            onMouseOver={e => e.currentTarget.style.transform = iconHoverStyle.transform}
            onMouseOut={e => e.currentTarget.style.transform = 'scale(1)'}
          />
        </IconButton>
      </Tooltip>

      <Tooltip title={<span style={{ fontSize: '1.2rem', padding: '10px' }}>AI Voice</span>} arrow>
        <IconButton onClick={() => handleVoiceModal()}>
          <img
            src={Voice}
            alt="Icon 5"
            style={iconStyle}
            onMouseOver={e => e.currentTarget.style.transform = iconHoverStyle.transform}
            onMouseOut={e => e.currentTarget.style.transform = 'scale(1)'}
          />
        </IconButton>
      </Tooltip>

      <Tooltip title={<span style={{ fontSize: '1.2rem', padding: '10px' }}>AI Music</span>} arrow>
        <IconButton onClick={() => handleAIMusic()}>
          <img
            src={Music}
            alt="Icon 3"
            style={iconStyle}
            onMouseOver={e => e.currentTarget.style.transform = iconHoverStyle.transform}
            onMouseOut={e => e.currentTarget.style.transform = 'scale(1)'}
          />
        </IconButton>
      </Tooltip>

      <Tooltip title={<span style={{ fontSize: '1.2rem', padding: '10px' }}>AI Video</span>} arrow>
        <IconButton onClick={() => handleVideoSettingsModal("default")}>
          <img
            src={Video}
            alt="Icon 4"
            style={iconStyle}
            onMouseOver={e => e.currentTarget.style.transform = iconHoverStyle.transform}
            onMouseOut={e => e.currentTarget.style.transform = 'scale(1)'}
          />
        </IconButton>
      </Tooltip>

      <Link to={`/picasso/${chatId}`}>
        <Tooltip title={<span style={{ fontSize: '1.2rem', padding: '10px' }}>Picasso AI</span>} arrow>
          <IconButton onClick={() => handleClick()}>
            <img
              src={Art}
              alt="Icon 1"
              style={iconStyle}
              onMouseOver={e => e.currentTarget.style.transform = iconHoverStyle.transform}
              onMouseOut={e => e.currentTarget.style.transform = 'scale(1)'}
            />
          </IconButton>
        </Tooltip>
      </Link>
    </Stack>
  );
};

export default HorizontalScrollIcons;
