import React from 'react';
import { Modal, Typography, Box, IconButton, useTheme, Container } from '@mui/material';
import { Close } from '@mui/icons-material';

function CustomModal({ open, handleClose, title, children, fullscreen }) {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: fullscreen ? '0' : '50%',
          left: '50%',
          transform: fullscreen ? 'none' : 'translate(-50%, -50%)',
          bgcolor: 'rgba(0, 22, 54, 0.9)',
          backgroundImage: 'linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(129, 129, 129, 0.2) 49%, rgba(255, 255, 255, 0.2) 100%)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
          p: 4,
          borderRadius: fullscreen ? 0 : 12,
          color: '#FFFFFF',
          maxWidth: '100%',
          width: fullscreen ? '100vw' : '90vw',
          height: fullscreen ? '90vh' : 'auto',
          maxHeight: '100vh',
          overflowY: 'auto',
          opacity: '0.90',
          [theme.breakpoints.up('xs')]: {
            width: '100vw',
            height: '100vh',
          },
          [theme.breakpoints.up('sm')]: {
            width: '100vw',
            height: '100vh',
          },
          [theme.breakpoints.up('md')]: {
            width: fullscreen ? '100vw' : '80vw',
            height: '90vh',
          },
          [theme.breakpoints.up('lg')]: {
            width: fullscreen ? '100vw' : '90vw',
            height: '90vh',
          }
        }}
      >
        <Typography variant="h3" id="modal-title" sx={{
          fontSize: { xs: 'h4.fontSize', sm: 'h3.fontSize' },
        }}>
          {title}
        </Typography>
        <br />
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
            color: '#FFFFFF'
          }}
          onClick={handleClose}
        >
          <Close style={{fontSize: '36px'}}/>
        </IconButton>
        <Container>{children}</Container>
      </Box>
    </Modal>
  );
}

export default CustomModal;
