import { useEffect, useRef } from "react";
import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";
import { registerLicense } from "@syncfusion/ej2-base";
import { updateSampleSection } from "./base";
import { toolbarListItems } from "utils/constants";

// Register the license for the document editor
registerLicense(process.env.REACT_APP_EDITOR_LICENSE);

// Inject the toolbar into the DocumentEditorContainerComponent
DocumentEditorContainerComponent.Inject(Toolbar);

const Editor = ({ onChangeContent, onEditorContentChange }) => {
  const containerRef = useRef(null); // Using ref to access the DOM node

  useEffect(() => {
    updateSampleSection();
    renderComplete();

    const handleVisibilityChange = () => {
      if (!document.hidden && containerRef.current) {
        containerRef.current.documentEditor.resize();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Remove focus from any element initially
    setTimeout(() => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }, 100); // Delay is necessary to ensure the elements are loaded and can be defocused

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const renderComplete = () => {
    window.onbeforeunload = () => "Want to save your changes?";
    if (containerRef.current) {
      const { documentEditor } = containerRef.current;
      documentEditor.pageOutline = "#E0E0E0";
      documentEditor.acceptTab = true;
      documentEditor.enableAutoFocus = false;
      documentEditor.resize();

      if (documentEditor.selectionPaneModule) {
        documentEditor.selectionPaneModule.showHidePropertiesPane(false);
      }
      onLoadDefault();
      onChangeContent(documentEditor);
    }
  };

  const onLoadDefault = () => {
    if (containerRef.current) {
      const { documentEditor } = containerRef.current;
      let fileData = localStorage.getItem("file");
      if (fileData) documentEditor.open(fileData);
      documentEditor.documentName = "";
      documentEditor.zoomFactor = 1.5;
      // documentEditor.fitPage("FitPageWidth");
      // documentEditor.documentEditorSettings.showRuler = true;
      localStorage.removeItem("file");
    }
  };

  const onDocumentChange = () => {
    const proxy = containerRef.current;
    if (proxy) {
      const isMobileDevice = /Android|Windows Phone|webOS/i.test(navigator.userAgent);
      proxy.restrictEditing = isMobileDevice;
      if (isMobileDevice) {
        setTimeout(() => {
          proxy.documentEditor.fitPage("FitPageWidth");
        }, 50);
      }
    }
  };

  return (
    <div className="documenteditor_container_body custom-scrollbar">
      <DocumentEditorContainerComponent
        id="container"
        ref={containerRef}
        style={{ backgroundColor: "transparent" }}
        height="90vh"
        serviceUrl={process.env.REACT_APP_EDITOR_SERVICE_URL}
        enableSpellCheck={true}
        enableAutoFocus={false}
        showPropertiesPane={false}
        autoResizeOnVisibilityChange={true}
        toolbarItems={toolbarListItems}
        documentChange={onDocumentChange}
        contentChange={onEditorContentChange}
        locale="en-US"
      />
    </div>
  );
};

export default Editor;