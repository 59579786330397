import React, { createContext, useState } from 'react';

const FileContext = createContext();

const FileProvider = ({ children }) => {
  const [fileData, setFileData] = useState({});

  const handleFileUpload = async (data) => {
    // console.log("Uploading", data);
    const uploadData = data;
    setFileData(uploadData);
    localStorage.setItem('file', JSON.stringify(uploadData));
    // console.log("file uploaded ", fileData);
  }

  return (
    <FileContext.Provider value={{ fileData, handleFileUpload }}>
      {children}
    </FileContext.Provider>
  );
};

export {FileContext, FileProvider};