import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog as MuiDialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  styled,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";
import DrawIcon from "@mui/icons-material/Draw";
import WavingHandIcon from "@mui/icons-material/WavingHand";
import { Link } from "react-router-dom";
import Logo from "assets/images/Logo.png";
import CustomModal from "components/Modals/MobileResultModal";
//Chatbox

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Dialog = styled(MuiDialog)(({ theme, isfullscreen }) => ({
  "& .MuiDialog-paper": {
    width: isfullscreen ? "100vw" : "35vw",
    height: isfullscreen ? "130vh" : "100vh",
    background: "#011636",
    backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(129, 129, 129, 0.01) 50%, rgba(255, 255, 255, 0.1) 100%)",
    backdropFilter: "blur(10px)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    right: 0,
    top: isfullscreen ? 0 : "40px",
    bottom: isfullscreen ? 0 : "50px",
    margin: isfullscreen ? 0 : "10px",
    borderRadius: isfullscreen ? 0 : "20px",
    overflow: "auto",
  },
}));

const BigTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    backdropFilter: "blur(100px)", 
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    color: "white",
    border: "1px solid #011636",
    fontSize: theme.typography.pxToRem(22),
    maxWidth: 250,
  },
}));

const GlassEffectAppBar = styled(AppBar)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.1)", // Glass effect background
  backdropFilter: "blur(100px)", // Blur effect
  // borderRadius: "15px",
  // border: "1px solid rgba(255, 255, 255, 0.2)",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
}));

const Chat = ({
  children,
  openi, setOpen
}) => {

  const [isfullscreen, setisfullscreen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [openTooltip, setOpenTooltip] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setOpenTooltip(false), 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  


  const handleClickOpen = () => {
    setOpen(true);
    setOpenTooltip(false);
  };

  const toggleFullscreen = () => {
    setisfullscreen(!isfullscreen);
  };

  const handleClose = () => {
   
    setOpen(false);
    // setTimeout(() => {
    //     console.log("right",openi);
    // })
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  

  return (
    <div>
      <BigTooltip
      title={
        <div style={{ position: "relative" }}>
          {/* <button
            onClick={handleCloseTooltip}
            style={{
              background: "none",
              border: "none",
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
              padding: "5px", // Optional: Adds some padding for better click area
            }}
          >
            x
          </button> */}
          <div 
      style={{
        textShadow: "2px 2px 4px black", // Black text shadow
        padding: "10px" // Adjust padding as needed
      }}
    >
      Welcome to Wishtales.ai, where your imagination becomes the greatest
      story ever told!m
    </div>
    
        </div>
      }
  arrow
  interactive
  placement="left"
  open={openTooltip}
>

        <Button
          variant="contained"
          color="primary"
          style={{
            position: "fixed",
            bottom: "10vw",
            right: "30px",
            zIndex: 1000,
            background: "transparent",
            width: "60px",
            height: "60px",
            background: "rgba(255, 255, 255, 0.1)", // Semi-transparent white background
          backdropFilter: "blur(10px)", // Blur effect
          WebkitBackdropFilter: "blur(10px)", // Blur effect for Safari
          color: "white",
          border: "1px solid rgba(255, 255, 255, 0.18)", // Semi-transparent border
          borderRadius: "30px",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)" // Optional shadow for depth
          }}
          onClick={handleClickOpen}
        >
          <img
            src={Logo}
            style={{ width: "50px", height: "50px" }}
            alt="Chat"
          />{" "}
        </Button>
      </BigTooltip>

      {/* ChatBot Dialog */}
      <CustomModal
      open={openi}
      handleClose={handleClose}>
        {children}
      </CustomModal>
     
    </div>
  );
};

export default Chat;
