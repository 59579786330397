import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip, Box } from "@mui/material";
import "./VideoPlayer.css";

const VideoPlayer = ({ url, regenFunction, regenWithEditFuntion, prompt }) => {
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const reactPlayerRef = useRef(null);

  const [blobUrl, setBlobUrl] = useState(null);

  // Function to fetch the video as a blob
  const fetchVideo = async () => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobObjectUrl = URL.createObjectURL(blob);
      setBlobUrl(blobObjectUrl);
    } catch (error) {
      console.error("Error fetching the video:", error);
    }
  };

  // Fetch the video when the component mounts
  useEffect(() => {
    fetchVideo();
  }, [url]);




  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleProgress = (state) => {
    setProgress(state.played * 100);
    setPlayedSeconds(state.playedSeconds);
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const generateRandomAlphaNum = (length = 2) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleEnded = () => {
    setPlaying(false);
  };

  const formatTime = (seconds) => {
    const rounded = Math.floor(seconds);
    const minutes = Math.floor(rounded / 60);
    const remainingSeconds = rounded - minutes * 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <div className="video-player-wrapper">
      <ReactPlayer
        ref={reactPlayerRef}
        url={url}
        playing={playing}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onEnded={handleEnded}
        width="100%"
        className="react-player"
      />
      <div className="controls-wrapper">
        <IconButton onClick={handlePlayPause} className="play-pause-btn" size="large" sx={{color: "white"}}>
          {playing ? <PauseIcon sx={{ width: 30, height: 30 }} /> : <PlayArrowIcon sx={{ width: 30, height: 30 }} />}
        </IconButton>
        <input
          type="range"
          min="0"
          max="100"
          value={progress}
          onChange={(e) => {
            const seekTo =
              (e.target.value / 100) * reactPlayerRef.current.getDuration();
            reactPlayerRef.current.seekTo(seekTo);
          }}
          className="progress-bar"
        />
        <div className="time-display">
          {formatTime(playedSeconds)} / {formatTime(duration)}
        </div>
      </div>
      <br />
      <div className="ai-buttons">
      <a 
  href={blobUrl} 
  className="ai-button" 
  download={`Wishtales-video-${prompt ? prompt.substring(0, 2) : generateRandomAlphaNum()}.mp4`}
>
  Download
</a>
        {regenFunction && (
          <button
            aria-label="reload-image"
            className="ai-button"
            onClick={() => regenFunction(url, prompt)}
          >
            <Box display="flex" alignItems="center" gap="8px">
              Regenerate
              <Tooltip title="Customize">
                <Box
                  sx={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 24,
                    height: 24,
                    borderRadius: "50%",
                    border: "2px solid currentColor",
                    padding: "4px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    regenWithEditFuntion(url, prompt);
                  }}
                >
                  <EditIcon />
                </Box>
              </Tooltip>
            </Box>
          </button>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
