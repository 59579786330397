import { useState, useCallback } from "react";
import {
  List,
  ListItem,
  Slider,
  MenuItem,
  FormControl,
  Select,
  Typography,
  TextField,
  Stack,
  Button,
  Autocomplete,
  InputAdornment
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  musical_genres,
  moods,
  composers,
  instruments,
} from "utils/constants";

const MusicControllerDrawer = ({
  inputValue,
  onChangeInput,
  onVolumeChange,
  onVariationChange,
  onGenreChange,
  onMoodChange,
  onArtistChange,
  onInstrumentChange,
  getMusic,
  getSoundEffect,
  getSoundEffectFromVideo,
  onSoundEffectDurationChange,
}) => {
  const [volume, setVolume] = useState(35);
  const [selectedVariation, setSelectedVariation] = useState("");
  const [selectedGenre, setSelectedGenre] = useState("");
  const [selectedMood, setSelectedMood] = useState("");
  const [selectedArtist, setSelectedArtist] = useState("Hans-Zimmer");
  const [selectedInstrument, setSelectedInstrument] = useState("");
  const [showMusicControls, setShowMusicControls] = useState(false);
  const [duration, setDuration] = useState(5);
  const [showSoundEffectsControls, setSoundEffectsControls] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [videos, setVideos] = useState([]);

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    onVolumeChange(newValue);
  };

  const handleVariationChange = (event) => {
    setSelectedVariation(event.target.value);
    onVariationChange(event.target.value);
  };

  const handleGenreChange = (event, newValue) => {
    setSelectedGenre(newValue);
    onGenreChange(newValue);
  };

  const handleMoodChange = (event, newValue) => {
    setSelectedMood(newValue);
    onMoodChange(newValue);
  };

  const handleArtistChange = (event, newValue) => {
    setSelectedArtist(newValue);
    onArtistChange(newValue);
  };

  const handleInstrumentChange = (event, newValue) => {
    setSelectedInstrument(newValue);
    onInstrumentChange(newValue);
  };

  const handleChangeDuration = (event, newValue) => {
    setDuration(newValue);
    onSoundEffectDurationChange(newValue);
  };

  const showMusic = () => {
    setSelectedModel("music")
    setSoundEffectsControls(false);
    setShowMusicControls(true);
  };
  const showSoundEffects = () => {
    setSelectedModel("effects");
    setSoundEffectsControls(true);
    setShowMusicControls(false);
  };

  const handleVideoFileSelect = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const selectedFiles = e.target.files;
      if (selectedFiles && selectedFiles.length > 0) {
        const videoFiles = Array.from(selectedFiles).filter(
          (file) =>
            file.type.match("video/mp4") ||
            file.type.match("video/avi") ||
            file.type.match("video/mkv") ||
            file.type.match("video/webm") // Add more video types if needed
        );
        if (videoFiles.length > 0) {
          // Update parent component's state
          setVideos((prevFiles) => [...prevFiles, ...videoFiles]);
        } else {
          alert("Only MP4, AVI, MKV, and WEBM files are allowed");
        }
      }
    },
    [setVideos]
  );

  return (
    <div>
      <div>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-around"
          flexWrap="wrap"
          spacing={{ xs: 1, sm: 1, md: 2 }}
          marginBottom="30px"
          useFlexGap
          sx={{ml: {lg: 6, xl: 6}}}
        >
          <Button
            variant="outlined"
            aria-haspopup="true"
            size="large"
            sx={{
              border: "1px solid white",
              backgroundColor:
                selectedModel === "music" ? "white" : "transparent",
              color: selectedModel === "music" ? "#011636" : "white",
              fontSize: "16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "white",
                borderColor: "white",
                color: "#011636",
                opacity: 0.9,
              },
            }}
            onClick={showMusic}
          >
            Generate Music
          </Button>
          <Button
            variant="outlined"
            aria-haspopup="true"
            size="large"
            sx={{
              border: "1px solid white",
              backgroundColor:
                selectedModel === "effects" ? "white" : "transparent",
              color: selectedModel === "effects" ? "#011636" : "white",
              fontSize: "16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "white",
                borderColor: "white",
                color: "#011636",
                opacity: 0.9,
              },
            }}
            onClick={showSoundEffects}
          >
            Generate Sound Effects
          </Button>
        </Stack>
      </div>

      {showMusicControls && (
        <div>
          <div>
  <ListItem
    sx={{
      display: "flex",
      alignItems: {
        xs: "flex-start",
        lg: "center",
      },
      flexDirection: {
        xs: "column",
        lg: "row",
      },
    }}
  >
    <Typography
      variant="h4"
      sx={{
        width: {
          xs: "100%",
          lg: "200px",
        },
        marginRight: {
          xs: 0,
          lg: "16px",
        },
      }}
    >
      Prompt*
    </Typography>
    <FormControl fullWidth sx={{ flex: 1 }}>
      <TextField
        label="Prompt"
        variant="outlined"
        value={inputValue}
        onChange={onChangeInput}
        fullWidth
        InputLabelProps={{
          style: { color: "white", fontSize: "18px" },
        }}
        InputProps={{
          style: {
            color: "white",
            fontSize: "18px",
          },
        }}
        sx={{
          marginBottom: "16px",
          "& label.Mui-focused": {
            color: "white",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
          },
          "& .MuiOutlinedInput-input": {
            paddingTop: "10px",
            paddingBottom: "10px",
          },
        }}
        required
        multiline
        maxRows={20}
      />
    </FormControl>
  </ListItem>

  {/* Volume Control */}
  <ListItem
    sx={{
      display: "flex",
      alignItems: {
        xs: "flex-start",
        lg: "center",
      },
      flexDirection: {
        xs: "column",
        lg: "row",
      },
    }}
  >
    <Typography
      variant="h4"
      sx={{
        width: {
          xs: "100%",
          lg: "200px",
        },
        marginRight: {
          xs: 0,
          lg: "16px",
        },
      }}
    >
      Volume
    </Typography>
    <Slider
      value={volume}
      onChange={handleVolumeChange}
      aria-labelledby="volume-slider"
      valueLabelDisplay="auto"
      min={0}
      max={100}
      required
      sx={{ flex: 1, color: "white", marginRight: "1px" }}
    />
  </ListItem>

  {/* Variations */}
  <ListItem
  sx={{
    display: "flex",
    alignItems: {
      xs: "flex-start",
      lg: "center",
    },
    flexDirection: {
      xs: "column",
      lg: "row",
    },
  }}
>
  <Typography
    variant="h4"
    sx={{
      width: {
        xs: "100%",
        lg: "200px",
      },
      marginRight: {
        xs: 0,
        lg: "16px",
      },
    }}
  >
    Variations*
  </Typography>
  <FormControl fullWidth sx={{ flex: 1 }}>
    <Select
      value={selectedVariation}
      onChange={handleVariationChange}
      required
      displayEmpty
      sx={{
        '.MuiMenuItem-root': {
          fontSize: '1.5rem',
        },
        '.MuiSelect-select': {
        fontSize: '1.5rem',
      },
      }}
    >
      <MenuItem value="" sx={{ fontSize: '1.5rem' }}>Select Variation</MenuItem>
      <MenuItem value="Tune 1" sx={{ fontSize: '1.5rem' }}>Tune 1</MenuItem>
      <MenuItem value="Tune 2" sx={{ fontSize: '1.5rem' }}>Tune 2</MenuItem>
      <MenuItem value="Tune 3" sx={{ fontSize: '1.5rem' }}>Tune 3</MenuItem>
      <MenuItem value="Tune 4" sx={{ fontSize: '1.5rem' }}>Tune 4</MenuItem>
    </Select>
  </FormControl>
</ListItem>

  {/* Genre */}
  <ListItem
    sx={{
      display: "flex",
      alignItems: {
        xs: "flex-start",
        lg: "center",
      },
      flexDirection: {
        xs: "column",
        lg: "row",
      },
    }}
  >
    <Typography
      variant="h4"
      sx={{
        width: {
          xs: "100%",
          lg: "200px",
        },
        marginRight: {
          xs: 0,
          lg: "16px",
        },
      }}
    >
      Genre
    </Typography>
    <FormControl fullWidth sx={{ flex: 1 }}>
      <Autocomplete
        value={selectedGenre}
        onChange={handleGenreChange}
        options={musical_genres}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search..."
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                </InputAdornment>
              ),
              style: {
                color: "white",
                fontSize: "2rem", // Increase the font size of the input text
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
                fontSize: "2rem", // Increase the font size of the placeholder text
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                color: "white",
              },
            }}
          />
        )}
        ListboxProps={{
          sx: {
            "& .MuiAutocomplete-option": {
              fontSize: "1.5rem", // Increase the font size of the options in the dropdown
              color: "black", // Set text color for the options
              "&[aria-selected='true']": {
                backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
              },
              "&:hover": {
                backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
              },
            },
          },
        }}
      />
    </FormControl>
  </ListItem>

  {/* Mood */}
  <ListItem
    sx={{
      display: "flex",
      alignItems: {
        xs: "flex-start",
        lg: "center",
      },
      flexDirection: {
        xs: "column",
        lg: "row",
      },
    }}
  >
    <Typography
      variant="h4"
      sx={{
        width: {
          xs: "100%",
          lg: "200px",
        },
        marginRight: {
          xs: 0,
          lg: "16px",
        },
      }}
    >
      Mood
    </Typography>
    <FormControl fullWidth sx={{ flex: 1 }}>
      <Autocomplete
        value={selectedMood}
        onChange={handleMoodChange}
        options={moods}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search..."
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                </InputAdornment>
              ),
              style: {
                color: "white",
                fontSize: "2rem", // Increase the font size of the input text
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
                fontSize: "2rem", // Increase the font size of the placeholder text
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                color: "white",
              },
            }}
          />
        )}
        ListboxProps={{
          sx: {
            "& .MuiAutocomplete-option": {
              fontSize: "1.5rem", // Increase the font size of the options in the dropdown
              color: "black", // Set text color for the options
              "&[aria-selected='true']": {
                backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
              },
              "&:hover": {
                backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
              },
            },
          },
        }}
      />
    </FormControl>
  </ListItem>

  {/* Artist */}
  <ListItem
    sx={{
      display: "flex",
      alignItems: {
        xs: "flex-start",
        lg: "center",
      },
      flexDirection: {
        xs: "column",
        lg: "row",
      },
    }}
  >
    <Typography
      variant="h4"
      sx={{
        width: {
          xs: "100%",
          lg: "200px",
        },
        marginRight: {
          xs: 0,
          lg: "16px",
        },
      }}
    >
      Artist
    </Typography>
    <FormControl fullWidth sx={{ flex: 1 }}>
      <Autocomplete
        value={selectedArtist}
        onChange={handleArtistChange}
        options={composers}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search..."
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                </InputAdornment>
              ),
              style: {
                color: "white",
                fontSize: "2rem", // Increase the font size of the input text
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
                fontSize: "2rem", // Increase the font size of the placeholder text
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                color: "white",
              },
            }}
          />
        )}
        ListboxProps={{
          sx: {
            "& .MuiAutocomplete-option": {
              fontSize: "1.5rem", // Increase the font size of the options in the dropdown
              color: "black", // Set text color for the options
              "&[aria-selected='true']": {
                backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
              },
              "&:hover": {
                backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
              },
            },
          },
        }}
      />
    </FormControl>
  </ListItem>
</div>


          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-around"
            flexWrap="wrap"
            spacing={{ xs: 1, sm: 1, md: 2 }}
            useFlexGap
          >
            <Button
              id="basic-button"
              variant="outlined"
              aria-haspopup="true"
              size="large"
              style={{
                border: "1px solid white",
                borderRadius: "20px",
                backgroundColor: "transparent",
                color: "white",
                fontSize: "16px",
                marginTop: "16px",
            "&:hover": {  
              backgroundColor: "white",
              color: "#011636",
            },
              }}
              onClick={getMusic}
            >
              Compose
            </Button>
          </Stack>
        </div>
      )}

      {showSoundEffectsControls && (
        <div>
          <List style={{ color: "white", borderRadius: "20px" }}>
            <ListItem
              sx={{
                display: "flex",
                alignItems: {
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "flex-start",
                  lg: "center",
                  xl: "center",
                },
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                  xl: "row",
                },
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "200px",
                    xl: "200px",
                  },
                  marginRight: {
                    xs: "0",
                    sm: "0",
                    md: "0",
                    lg: "16px",
                    xl: "16px",
                  },
                }}
              >
                Prompt*
              </Typography>
              <FormControl fullWidth sx={{ flex: 1 }}>
                <TextField
                  label="Prompt"
                  variant="outlined"
                  value={inputValue}
                  onChange={onChangeInput}
                  fullWidth
                  InputLabelProps={{
                    style: { color: "white", fontSize: "18px" },
                  }}
                  InputProps={{
                    style: {
                      color: "white",
                      fontSize: "18px",
                    },
                  }}
                  sx={{
                    marginBottom: "16px",
                    "& label.Mui-focused": {
                      color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    },
                    "& .MuiInputLabel-outlined": {
                      transform: "translate(14px, 14px) scale(1)",
                    },
                    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                      transform: "translate(14px, -6px) scale(0.75)",
                    },
                  }}
                  required
                  multiline
                  maxRows={20}
                />
              </FormControl>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                alignItems: {
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "flex-start",
                  lg: "center",
                  xl: "center",
                },
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                  xl: "row",
                },
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "200px",
                    xl: "200px",
                  },
                  marginRight: {
                    xs: "0",
                    sm: "0",
                    md: "0",
                    lg: "16px",
                    xl: "16px",
                  },
                }}
              >
                Duration*
              </Typography>
              <FormControl fullWidth sx={{ flex: 1 }}>
                <Slider
                  value={duration}
                  onChange={handleChangeDuration}
                  aria-labelledby="duration-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={1}
                  max={30}
                  defaultValue={5}
                  sx={{
                    color: "#DBC55B",
                    "& .MuiSlider-thumb": {
                      color: "white",
                    },
                    "& .MuiSlider-track": {
                      color: "white",
                    },
                    "& .MuiSlider-rail": {
                      color: "white",
                    },
                  }}
                />
              </FormControl>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                alignItems: {
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "flex-start",
                  lg: "center",
                  xl: "center",
                },
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                  xl: "row",
                },
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "200px",
                    xl: "200px",
                  },
                  marginRight: {
                    xs: "0",
                    sm: "0",
                    md: "0",
                    lg: "16px",
                    xl: "16px",
                  },
                }}
              >
                Video
              </Typography>
              <FormControl fullWidth sx={{ flex: 1 }}>
                <TextField
                  type="file"
                  inputProps={{ accept: "video/*" }}
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    style: { color: "red", fontSize: "18px" },
                  }}
                  InputProps={{
                    style: {
                      color: "white",
                      fontSize: "18px",
                    },
                  }}
                  sx={{
                    marginBottom: "16px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                  }}
                  onChange={handleVideoFileSelect}
                />
              </FormControl>
            </ListItem>
          </List>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-around"
            flexWrap="wrap"
            spacing={{ xs: 1, sm: 1, md: 2 }}
            useFlexGap
          >
            <Button
              id="basic-button"
              variant="outlined"
              aria-haspopup="true"
              size="large"
              style={{
                border: "1px solid white",
                borderRadius: "20px",
                backgroundColor: "transparent",
                color: "white",
                fontSize: "16px",
                marginTop: "16px",
            "&:hover": {  
              backgroundColor: "white",
              color: "#011636",
            },
              }}
              onClick={() => (videos && videos.length > 0 ? getSoundEffectFromVideo(videos) : getSoundEffect())}            >
              Compose
            </Button>
          </Stack>
        </div>
      )}
    </div>
  );
};

export default MusicControllerDrawer;
