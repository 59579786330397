import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Outlet,
} from "react-router-dom";
import { useAuth } from "hooks/User";
import { useEffect } from "react";
import Home from "./Home";
import IntroScreen from "./Assistive/Intro";
import Compose from "./Assistive/Compose";
import Train from "./Assistive/Train";
import Domain from "views/Assistive/Domain.jsx";
import Login from "./Login/Login";
import Library from "./Library/Library.jsx";
import FolderContents from "components/Folders/FolderContents";
import DocumentContents from "components/Folders/DocumentContents";
import ImageContents from "components/Folders/ImageContents";
import AnimationContents from "components/Folders/AnimationContents";
import VoiceNarrationContents from "components/Folders/VoiceNarrationContent";
import MusicContents from "components/Folders/MusicContents";
import ArtContents from "components/Folders/ArtContents";
import Canvas from "./Canvas/Paint";

const PrivateRoute = ({ children }) => {
  let auth = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    if (!auth.user.token) {
      navigate("/login");
    }
  }, [auth.user, navigate]);

  return auth.user ? <Outlet /> : null;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route index element={<IntroScreen />} />
        </Route>
        <Route path="/compose" element={<PrivateRoute />}>
          <Route index element={<Compose />} />
        </Route>
        <Route path="/train" element={<PrivateRoute />}>
          <Route index element={<Train />} />
        </Route>
        {/* <Route path="/domain" element={<PrivateRoute />}>
          <Route index element={<Domain />} />
        </Route> */}
        <Route path="/c" element={<PrivateRoute />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/c/:chatId?" element={<PrivateRoute />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/c/:chatId?/library" element={<PrivateRoute />}>
          <Route index element={<Library />} />
        </Route>
        <Route
          path="/c/library/:folderName/:projectName?"
          element={<PrivateRoute />}
        >
          <Route index element={<FolderContents />} />
        </Route>
        <Route path="/c/library/:chatId/01_Documents" element={<PrivateRoute />}>
          <Route index element={<DocumentContents />} />
        </Route>
        <Route path="/c/library/:chatId/02_Images" element={<PrivateRoute />}>
          <Route index element={<ImageContents />} />
        </Route>
        <Route
          path="/c/library/:chatId/03_Voice_Narration"
          element={<PrivateRoute />}
        >
          <Route index element={<VoiceNarrationContents />} />
        </Route>
        <Route path="/c/library/:chatId/04_PicassoAI" element={<PrivateRoute />}>
          <Route index element={<ArtContents />} />
        </Route>
        <Route path="/c/library/:chatId/05_Animations" element={<PrivateRoute />}>
          <Route index element={<AnimationContents />} />
        </Route>
        <Route path="/c/library/:chatId/06_Music" element={<PrivateRoute />}>
          <Route index element={<MusicContents />} />
        </Route>
        <Route path="/picasso" element={<PrivateRoute />}>
          <Route index element={<Canvas />} />
        </Route>
        <Route path="/picasso/:chatId" element={<PrivateRoute />}>
          <Route index element={<Canvas />} />
        </Route>
        <Route path="*" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default App;
