import React from 'react';
import Markdown from 'markdown-to-jsx';
import DOMPurify from 'dompurify';

const RenderMarkdown = ({ markdown, fontColor = '#ffffff' }) => {
  try {
    if (typeof markdown !== 'string') {
      throw new Error('Invalid markdown input');
    }

    let trimmedMarkdown = markdown.trim();

    // Check if string starts with ```markdown and trim it
    if (trimmedMarkdown.startsWith('```markdown')) {
      trimmedMarkdown = trimmedMarkdown.replace(/^```markdown\s+/, '');
    }

    // Check if string ends with ``` and trim it
    if (trimmedMarkdown.endsWith('```')) {
      trimmedMarkdown = trimmedMarkdown.replace(/\s*```$/, '');
    }

    // Remove the leading and trailing ```
    const cleanedMarkdown = trimmedMarkdown.replace(/^\s*```|```\s*$/g, '');

    // Sanitize the cleaned markdown
    const sanitizedMarkdown = DOMPurify.sanitize(cleanedMarkdown);

    // Apply font color to the rendered markdown
    const markdownStyle = { color: fontColor };

    return <div style={markdownStyle}><Markdown>{sanitizedMarkdown}</Markdown></div>;
  } catch (error) {
    console.error('Error rendering markdown:', error);
    return null; // Return null to avoid rendering the component in case of an error
  }
};

export default RenderMarkdown;
