import React, { useState } from "react";
import { Link, useParams } from 'react-router-dom';
import FolderIcon from "@mui/icons-material/Folder";
import Stack from "@mui/material/Stack";

const Folder = ({ name, chatId, isProject }) => {
  const params = useParams();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // Using chatId from params if it's not available as a prop
  const currentChatId = chatId || params.folderName;

  const folderLink = isProject
    ? `/c/library/${currentChatId}`
    : `/c/library/${currentChatId}/${name}`;

  return (
    <div className="folder">
      <div className="folder-header">
        <Link to={folderLink} onClick={handleToggle}>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '100%',
              height: '100%',
              textAlign: 'center',
              '@media (max-width: 600px)': {
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }
            }}
          >
            <FolderIcon sx={{ width: "6em", height: "6em" }} />
            <span
              style={{
                fontSize: "1.5rem",
                display: 'block', // Ensures text is on a new line
                marginTop: '0.5rem' // Adds spacing between icon and text
              }}
            >
              {name}
            </span>
          </Stack>
        </Link>
      </div>
      {isOpen && <div className="folder-children"></div>}
    </div>
  );
};

export default Folder;
