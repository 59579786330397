import React, { useState, useEffect, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Folder from "./Folder";
import {
  getAllChatsByChatId,
  deleteChat,
  renameProject,
} from "utils/network.js";
import { AuthContext } from "context/User";
import ExportDialog from "./MediaExport";
import SideDrawer from "components/Drawers/SideDrawer";
import PersonIcon from "@mui/icons-material/Person";
import BackgroundAnimation from "components/Background/BackgroundAnimations";
import "./folders.css";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const GlassmorphicIconButton = styled(IconButton)(({ theme }) => ({
  width: "3em",
  height: "3em",
  backgroundColor: "transparent",
  backgroundImage:
    "linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(129, 129, 129, 0.2) 49%, rgba(255, 255, 255, 0.2) 100%)",
  backdropFilter: "blur(10px)",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  color: "white",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.2)",
  },
}));

const FolderContents = () => {
  const { user, selectedChatId } = useContext(AuthContext);
  const { folderName, projectName } = useParams();
  let navigate = useNavigate();

  const linkPath = selectedChatId
    ? `/c/${selectedChatId}/library`
    : "/c/library";

  const [chats, setChats] = useState([]);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState(
    projectName || `Project-${folderName.slice(-4, -1)}`
  );

  const toggleSideDrawer = () => {
    setSideDrawerOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const chat = await getAllChatsByChatId(folderName);
        setChats(chat.data[0].chats);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [folderName]);

  const handleExportClick = () => {
    setIsExportDialogOpen(true);
  };

  const handleRenameFolder = async () => {
    try {
      const response = await renameProject({
        userId: user.userId,
        chatId: folderName,
        projectName: newFolderName,
      });
      console.log("Renamed chat successfully", response);
    } catch (error) {
      console.error("Error renaming chat:", error);
    } finally {
      setRenameDialogOpen(false);
    }
  };

  const openRenameDialog = () => {
    setRenameDialogOpen(true);
  };

  const closeRenameDialog = () => {
    setRenameDialogOpen(false);
  };

  return (
    <BackgroundAnimation>
      <NavigationStack
        folderName={folderName}
        userId={user?.userId}
        navigate={navigate}
        toggleSideDrawer={toggleSideDrawer}
        editableFolderName={newFolderName}
        linkPath={linkPath}
      />
      <FixedDeleteButton
        userId={user?.userId}
        folderName={folderName}
        navigate={navigate}
        openRenameDialog={openRenameDialog}
        onExportClick={handleExportClick}
      />
      <ContentStack folderName={folderName} />
      <ExportDialog
        open={isExportDialogOpen}
        folderName={folderName}
        user={user}
        onClose={() => setIsExportDialogOpen(false)}
      />

      <SideDrawer
        isOpen={sideDrawerOpen}
        onOpen={toggleSideDrawer}
        onClose={toggleSideDrawer}
      />

      <Dialog
        open={renameDialogOpen}
        onClose={closeRenameDialog}
        PaperProps={{
          sx: {
            background: "rgba(255, 255, 255, 0.1)", // Light translucent background
            backdropFilter: "blur(10px)", // Frosted glass effect
            boxShadow: "0 8px 32px rgba(31, 38, 135, 0.37)", // Soft shadow
            borderRadius: "16px", // Rounded corners
            border: "1px solid rgba(255, 255, 255, 0.18)", // Thin white border
            color: "white", // Text color set to white
          },
        }}
      >
        <DialogTitle sx={{ color: "white" }}>Rename Folder</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "white" }}>
            Please enter the new name for the selected folder.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="New Folder Name"
            type="text"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            sx={{
              background: "rgba(255, 255, 255, 0.15)",
              borderRadius: "8px",
              "& .MuiInputBase-input": {
                color: "white", // Input text color
              },
              "& .MuiInputLabel-root": {
                color: "white", // Label text color by default
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "white", // Label text color when focused
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white", // Border color
                },
                "&:hover fieldset": {
                  borderColor: "white", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white", // Border color when focused
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRenameDialog} sx={{ color: "white" }}>
            Cancel
          </Button>
          <Button onClick={handleRenameFolder} sx={{ color: "white" }}>
            Rename
          </Button>
        </DialogActions>
      </Dialog>
    </BackgroundAnimation>
  );
};

const NavigationStack = ({
  folderName,
  userId,
  navigate,
  toggleSideDrawer,
  editableFolderName,
  linkPath,
}) => (
  <Stack
    direction="row"
    spacing={1}
    alignItems="center"
    justifyContent="flex-start"
    padding={2}
    sx={{
      background: "rgba(255, 255, 255, 0.1)",
      backdropFilter: "blur(10px)",
      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
      position: "sticky",
      top: 0,
      zIndex: 1000,
    }}
    className="go-back"
  >
    <Link to={linkPath} style={{ color: "white" }}>
      <ArrowBackIcon sx={{ width: "2em", height: "3em" }} />
    </Link>
    <Typography
      variant="h2"
      component="div"
      sx={{ flexGrow: 1, color: "white" }}
    >
      Project Explorer - {editableFolderName}
    </Typography>
    <IconButton
      aria-label="draw"
      sx={{
        "& .MuiSvgIcon-root": { fontSize: 28 },
        color: "white",
        cursor: "pointer",
      }}
      onClick={toggleSideDrawer}
    >
      <PersonIcon />
    </IconButton>
  </Stack>
);

const ContentStack = ({ folderName }) => {
  const folderNames = [
    "01_Documents",
    "02_Images",
    "03_Voice_Narration",
    "04_PicassoAI",
    "05_Animations",
    "06_Music",
  ];

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      sx={{
        color: "white",
        width: "100vw",
        marginTop: "160px",
        "@media (max-width: 600px)": {
          padding: "0 20px",
        },
      }}
    >
      {folderNames.map((item, index) => (
        <Grid
          item
          xs={6} // Full width on mobile
          sm={6} // 2 columns on small screens
          md={4} // 3 columns on medium screens
          lg={2} // 6 columns on large screens
          key={index}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Folder name={item} />
        </Grid>
      ))}
    </Grid>
  );
};

const FixedDeleteButton = ({
  userId,
  folderName,
  navigate,
  openRenameDialog,
  onExportClick,
}) => (
  <div
    style={{
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "20px",
      position: "fixed",
      marginTop: "10px",
      right: "20px",
      width: "auto",
    }}
  >
    <GlassmorphicIconButton
      onClick={async () => {
        await deleteChat({ userId: userId, chatId: folderName });
        navigate("/c/library/");
      }}
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(10px)",
        color: "white",
        fontSize: "14px",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        borderRadius: "15px",
        padding: "8px",
        minWidth: "4em",
        height: "2.5em",
      }}
    >
      <Tooltip title="Delete selected">
        <DeleteIcon sx={{ width: "2em", height: "2em" }} />
      </Tooltip>
    </GlassmorphicIconButton>

    <Link
      to={`/c/${folderName}`}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <GlassmorphicIconButton
        title="Open in editor"
        aria-label="edit"
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          backdropFilter: "blur(10px)",
          color: "white",
          fontSize: "14px",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          borderRadius: "15px",
          padding: "8px",
          minWidth: "4em",
          height: "2.5em",
        }}
      >
        <Tooltip title="Open in editor">
          <DriveFileMoveIcon sx={{ width: "2em", height: "2em" }} />
        </Tooltip>
      </GlassmorphicIconButton>
    </Link>

    <GlassmorphicIconButton
      title="Rename folder"
      onClick={openRenameDialog}
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(10px)",
        color: "white",
        fontSize: "14px",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        borderRadius: "15px",
        padding: "8px",
        minWidth: "4em",
        height: "2.5em",
      }}
    >
      <Tooltip title="Rename folder">
        <EditIcon sx={{ width: "2em", height: "2em" }} />
      </Tooltip>
    </GlassmorphicIconButton>

    <GlassmorphicIconButton
      title="Export to DANK AI Web"
      onClick={onExportClick}
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(10px)",
        color: "white",
        fontSize: "14px",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        borderRadius: "15px",
        padding: "8px",
        minWidth: "4em",
        height: "2.5em",
      }}
    >
      <Tooltip title="Export to DANK AI Web">
        <FileUploadIcon sx={{ width: "2em", height: "2em" }} />
      </Tooltip>
    </GlassmorphicIconButton>
  </div>
);

export default FolderContents;