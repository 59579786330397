import Main from  "./Main/Main";
import {FileProvider} from "context/File";
import { UploadSourceProvider } from "context/UploadSource";
import BackgroundAnimation from "components/Background/BackgroundAnimations";

const Home = () => {

    return (
       
        <UploadSourceProvider>
        <FileProvider>
            <Main />
        </FileProvider>
        </UploadSourceProvider>
    )
}

export default Home;