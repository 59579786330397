import React, { createContext, useState } from "react";
import { loginAuth } from "utils/network.js";

const AuthContext = createContext();

function ProvideAuth({ children }) {
  // const [user, setUser] = useState({
  //   token: null,
  //   userId: null,
  //   userName: null,
  //   chatId: null,
  // });

  const storedUser = localStorage.getItem("user");
  const initialUser = storedUser
    ? JSON.parse(storedUser)
    : {
        token: null,
        userId: null,
        userName: null,
        chatId: null,
      };
  const [user, setUser] = useState(initialUser);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const setChatId = (chatId) => {
    setSelectedChatId(chatId);
  } 
  const [selectedPersonaFromTrain, setselectedPersonaFromTrain] =
    useState(null);
  const [selectedDomain, setselectedDomain] = useState(null);

  const login = async (username, password) => {
    try {
      const response = await loginAuth({ username, password });

      if (response.data && response.data.token) {
        const loggedInUser = {
          token: response.data.token,
          userId: response.data.account?.id,
          userName: response.data.account?.username,
          chatId: null,
        };
        setUser(loggedInUser);

        localStorage.setItem("user", JSON.stringify(loggedInUser));

        return [true, "Login Successful"];
      } else {
        return [false, "Incorrect Credentials"];
      }
    } catch (err) {
      console.error("Error during login:", err);

      if (err.code === "ERR_NETWORK") {
        return [false, "Something went wrong.. Please try again later.."];
      }

      if (err.response.status == 401) {
        return [false, "Incorrect Credentials"];
      }
    }
  };

  const logout = () => {
    localStorage.removeItem("user");
    setUser({ token: null, userId: null, userName: null, chatId: null });
  };

  const setPersona = (persona) => {
    setselectedPersonaFromTrain(persona);
  };

  const setDomain = (domain) => {
    setselectedDomain(domain);
  };

  

  return (
    <AuthContext.Provider
      value={{
        user,
        selectedPersonaFromTrain,
        selectedDomain,
        selectedChatId,
        setChatId,
        setPersona,
        setDomain,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, ProvideAuth };
