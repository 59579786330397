import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AudioPlayer from 'components/AudioPlayer/AudioPlayer';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton'; 
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete'; // Importing the DeleteIcon
import { deleteAssetsFromProject } from "utils/network.js";
import RenderMarkdown from 'components/Markdown/MarkdownRenderer';




export default function CustomizedDialogs({ open, handleClose, handleDelete, content, audioData, animeUrl ,prompt = ''}) {

  const handleAudioDownload = async (audioData) => {
    console.log("handleAudioDownload", audioData);
    const handleError = (message) => {
      console.error(message);
    };
    // console.log("audioData", audioData);
  
    const initiateDownload = (blob, filename) => {
      console.log("blob", blob);
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = filename;
      document.body.appendChild(anchor); // Necessary for Firefox
      anchor.click();
      window.URL.revokeObjectURL(url);
      anchor.remove();
    };
  
    if (typeof audioData === "string") {
      if (audioData.startsWith("http://") || audioData.startsWith("https://")) {
        try {
          console.log("reached try catch");
          const response = await fetch(audioData);
          console.log("fetched", response);
          if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.statusText}`);
          }
          const blob = await response.blob();
          initiateDownload(blob, "audio.mp3");
        } catch (error) {
          handleError(`Error downloading audio from URL: ${error.message}`);
        }
      } else if (audioData.startsWith("data:audio/mp3;base64,")) {
        try {
          const audioSource = audioData;
          const anchor = document.createElement("a");
          anchor.href = audioSource;
          anchor.download = "audio.mp3";
          anchor.click();
        } catch (error) {
          handleError("Error processing Base64 audio data.");
        }
      } else {
        handleError("Unsupported audio data format.");
      }
    } else {
      handleError("Invalid audio data provided.");
    }
  };

  return (
    <>
     {open && (
       <div style={{ 
         position: 'fixed', 
         bottom: 20, // Adjust this value as needed for spacing from the bottom
         left: '50%',
         transform: 'translateX(-50%)',
         width: '100%',
         maxWidth: '40vw',
         zIndex: 1300, // Ensure it appears above other elements
       }}>
         <div style={{
           backdropFilter: 'blur(10px)', // Glassmorphic effect
           background: 'rgba(255, 255, 255, 0.2)',
           borderRadius: '15px',
           padding: '20px',
           boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
           border: '1px solid rgba(255, 255, 255, 0.3)',
           width: '100%',
         }}>
           {animeUrl ? (
             <VideoPlayer url={animeUrl} prompt={prompt} />
           ) : audioData ? (
             <div style={{
               display: 'flex',
               alignItems: 'center',
               width: '100%',
             }}>
               <AudioPlayer 
                 audioData={audioData} 
                 audioDataForDownload={audioData} 
                 handleAudioDownload={handleAudioDownload} 
                 sx={{ flex: 1 }}
               />
               {handleDelete && (
                <IconButton
                aria-label="delete"
                onClick={() => handleDelete(animeUrl || audioData)}
                sx={{
                  color: (theme) => theme.palette.common.white,
                  backgroundColor: 'rgba(255, 255, 255, 0.2)', // Semi-transparent background
                  border: '1px solid rgba(255, 255, 255, 0.3)', // Light border for a glass effect
                  borderRadius: '50%',
                  marginLeft: 2,
                  backdropFilter: 'blur(10px)', // Blurring the background behind the button
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Optional: Adds subtle shadow
                  transition: 'background-color 0.3s, border-color 0.3s', // Smooth transitions
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.3)', // Slightly more opaque on hover
                    borderColor: 'rgba(255, 255, 255, 0.5)', // Darker border on hover
                  },
                }}
              >
                <DeleteIcon sx={{ fontSize: 30 }} />
              </IconButton>
               )}
             </div>
           ) : (
            <Typography 
  gutterBottom 
  variant="h6"
  sx={{
    maxHeight: '20vh', // Set a fixed height, adjust as needed
    maxWidth: '40vw',
    overflowY: 'auto',  // Enable vertical scrolling
    paddingRight: '10px', // Optional: Add some padding to prevent scrollbar overlap
    padding: '2vh'
  }}
>
<RenderMarkdown markdown={content} /> 
</Typography>
           )}
           <IconButton
             aria-label="close"
             onClick={handleClose}
             sx={{
               position: 'absolute',
               top : 0, // Adjust as needed
               left: '97%',
               transform: 'translateX(-50%)',
               color: (theme) => theme.palette.common.white,
              //  backgroundColor: 'rgba(0, 0, 0, 0.6)',
               borderRadius: '50%',
             }}
           >
             <CloseIcon />
           </IconButton>
         </div>
       </div>
     )}
    </>
  );
}