import { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Document, Packer, Paragraph, TextRun } from "docx";
import axios from "axios";
import JSZip from "jszip";
import mammoth from "mammoth";
import { Circles, ThreeDots } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Box, Stack, Grid, Button, Tooltip, styled } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MicIcon from "@mui/icons-material/Mic";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import SendIcon from "@mui/icons-material/Send";
import { saveAs } from "file-saver";
import MultiImageFileUploader from "components/ImageUploader/multipleImagesUploader";
import PersonaHandler from "components/Drawers/PersonaHandler";
import ImageSettingsDrawer from "components/Drawers/ImageSettingsDrawer";
import ImageFromTextDrawer from "components/Drawers/ImageFromTextDrawer";
import VoiceSettingsDrawer from "components/Drawers/VoiceSettingsDrawer";
import VideoSettingsDrawer from "components/Drawers/VideoSettingsDrawer";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import AudioPlayer from "components/AudioPlayer/AudioPlayer";
import CustomModal from "components/Modals/Modal";
import MusicControllerDrawer from "components/Drawers/MusicControllerDrawer";
import SideDrawer from "components/Drawers/SideDrawer";
import TopAppBar from "components/AppBar/AppBar";
import OptionsMenu from "components/ChatBox/Options";
import RenderMarkdown from "components/Markdown/MarkdownRenderer";
import Chat from "components/ChatBox/ChatButton";
import SettingsMenu from "components/Menu/SettingsMenu";
import CustomAvatar from "components/CustomAvatar/index";
import Logo from "assets/images/Logo.png";
import Loader from "components/Common/Loaders/Loader";
import LinearProgressWithLabel from "components/Common/Progress/ProgressBar";
import HorizontalScrollIcons from "components/Common/AiModels/HorizontalScrollIcons";
import CustomSnackbar from "components/Common/Snackbar/Snackbar";
import Editor from "components/Editor/Editor";
import SpinnerLoader from 'components/Common/Loaders/SpinnerLoader';
import { useUploadSource } from "context/UploadSource";
import { initializeSocketConnection } from "utils/socket-service";
import BackgroundAnimation from "components/Background/BackgroundAnimations";
import {
  sendImageRequest,
  sendImageRequestv2,
  sendImageRequestv3,
  sendImageRequestv4,
  sendImageRequestv5,
  sendImageRequestv6,
  sendImageRequestv7,
  sendImageRequestv8,
  sendImageRequestv9,
  sendAddonImageRequest,
  generateVoice,
  getBackgroundMusic,
  getMusicUrl,
  getSoundEffectsUrl,
  getPromptsFromEngine,
  getEduPromptsFromEngine,
  getAnimatedVideo,
  getImageAnimations,
  getAllTransformedChatsByChatId,
  deleteMessageFromChat,
  getImagesDirectly,
  getImagesDirectlyWithCSRF,
  getAnimationsDirectly,
  getRegeneratedImage,
  getDirectAnimatedVideo,
  getSoundEffectsFromVideo,
} from "utils/network.js";
import { generateUniqueNumber } from "utils/helper.js";
import { AuthContext } from "context/User";
import { persona_mapping, mappedVoices } from "utils/constants.js";
import { useQueryParams } from "hooks/GetParams";
import { useEditorSave } from "hooks/Editor";
import useSnackbar from "hooks/Snackbar";
import "./Main.css";
//Main.

const Main = () => {
  const { user, selectedPersonaFromTrain, selectedDomain, setChatId } =
    useContext(AuthContext);
  const getQueryParam = useQueryParams();
  const trainGPT = getQueryParam("t");
  const [token, setToken] = useState(user.token);
  const { chatId } = useParams();
  const [currentUserId, setCurrentUserId] = useState(user.userId);
  const [currentChatId, setCurrentChatId] = useState(
    chatId ? chatId : currentUserId + "t" + generateUniqueNumber()
  );
  const navigate = useNavigate();
  const [currentMsgId, setCurrentMsgId] = useState("");
  const [message, setMessage] = useState({
    messageId: null,
    text: "",
    isHuman: false,
    imageData: null,
    selectedImageUrl: null,
    audioData: null,
    audioDataForDownload: null,
    animeUrl: null,
    buttonMessageId: null,
  });
  const [messages, setMessages] = useState([]);
  const [chatLoader, setChatLoader] = useState(true);
  const [socket, setSocket] = useState([]);
  const { uploadSource, setUploadSource } = useUploadSource();
  const location = useLocation();
  const [openi, setOpen] = useState(false);

  const {
    editorContent,
    handleEditorData,
    saveContentAsDocx,
    saveContentAsPdf,
    saveContentAsHtml,
    handleChangeInEditor,
    editorModified,
  } = useEditorSave();
  // const { snackbarOpen, snackbarSeverity, snackbarContent, showSnackbar } = useSnackbar();

  const fetchData = useCallback(async () => {
    try {
      setMessages([]);
      const chats = await getAllTransformedChatsByChatId(currentChatId);
      if (chats.status !== 200 && chats.status !== 201) {
        throw new Error(`Request failed with status code: ${chats.status}`);
      }

      if (chats && chats.data.chats.length > 0) {
        setChatLoader(true);
        console.log(chats.data.chats);
        chats.data.chats.map((element, index) => {
          setMessages((prevMessages) => {
            if (!element.messageType) {
              const messageHuman = {
                messageId: element.messageId,
                text: element.prompt,
                isHuman: true,
                type: "text",
              };
              const messageAI = {
                messageId: element.messageId,
                text: element.response,
                isHuman: false,
                imageData: null,
                selectedImageUrl: null,
                additionalImages: element.imageUrl,
                imageBasedAnimations: element.videoUrl,
                buttonMessageId: element.imageButtonId,
                imagineId: element.imagineId,
                audioData: element.audioData.data,
                audioDataForDownload: element.audioData.data,
                animeUrl:
                  Object.keys(element.animeUrl).length === 0
                    ? null
                    : element.animeUrl,
                type: "text",
              };
              return [...prevMessages, messageHuman, messageAI];
            } else {
              let this_message;
              if (element.messageType === "image") {
                this_message = {
                  imageUrls: element.url[0].url,
                  prompt: element.url[0].prompt,
                  type: "image",
                };
                // }
              } else if (element.messageType === "video") {
                this_message = {
                  videoUrls: element.url[0].url,
                  prompt: element.url[0].prompt,
                  type: "video",
                };
              } else if (element.messageType === "audio64") {
                this_message = {
                  audioData: element.url[0].url,
                  audioDataForDownload: element.url[0].url,
                  type: "audio64",
                };
              } else if (element.messageType === "audio") {
                this_message = {
                  audioData: element.url[0].url,
                  audioDataForDownload: element.url[0].url,
                  type: "audio",
                };
              } else if (element.messageType === "music") {
                this_message = {
                  audioData: element.url[0].url,
                  audioDataForDownload: element.url[0].url,
                  type: "audio",
                };
              }
              return [...prevMessages, this_message];
            }
          });
          if (element.imageButtonId) {
            setButtonMessageId(element.imageButtonId);
          }
          setCurrentMsgId(element.messageId);
        });

        setChatLoader(false);
      }
      setChatLoader(false);
    } catch (err) {
      console.error(err);
      setChatLoader(false);
    }
  });

  useEffect(() => {
    setChatId(currentChatId);
  }, [currentChatId]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const source = searchParams.get("source");
    const type = searchParams.get("type");
    setUploadSource(source);

    if (source) {
      setOpen(true);
      if (type == "video") {
        handleVideoSettingsModal(source);
      } else if (type == "image") {
        handleImageSettingsModal(source);
      }
    }
  }, [location]);

  useEffect(() => {
    const { socket, cleanup: cleanupSocket } = initializeSocketConnection(
      () => {
        // Handle socket connected
        console.log("Socket connected from MyComponent");
      },
      (data) => {
        // Handle 'someEvent' event
        console.log("Data from someEvent:", data);
      }
    );

    setSocket(socket);

    // Cleanup function for disconnection and removing event listeners
    return cleanupSocket;
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (trainGPT === "1") {
      setDocumentButtons(true);
      setDocumentUpload(true);
    } else if (editorModified) {
      setDocumentButtons(true);
      setDocumentUpload(true);
    } else {
      setDocumentButtons(false);
      setDocumentUpload(false);
    }
  }, [editorModified, trainGPT]);

  const fetchLastNonHumanMessageAndUpdate = async (data) => {
    // If there are no messages, exit the function
    if (messages.length === 0) return;

    // Find the index of the last message where isHuman is false
    const lastIndex = [...messages].reverse().findIndex((msg) => !msg.isHuman);

    if (lastIndex !== -1) {
      const actualIndex = messages.length - 1 - lastIndex;

      const lastNonHumanMessage = messages[actualIndex];

      setMessage(lastNonHumanMessage);

      const updatedMessage = {
        ...lastNonHumanMessage,
        ...data,
      };

      const updatedMessages = [
        ...messages.slice(0, actualIndex),
        updatedMessage,
        ...messages.slice(actualIndex + 1),
      ];

      setMessages(updatedMessages);
    } else {
      console.warn("No non-human message found.");
    }
  };

  const [inputValue, setInputValue] = useState("");

  const [newMessage, setNewMessage] = useState("");
  const [promptForEngine, setPromptForEngine] = useState("");
  const [inQuery, setInQuery] = useState(false);
  const [ifImage, setIfImage] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [docImageLoader, setDocImageLoader] = useState(false);
  const [docVideoLoader, setDocVideoLoader] = useState(false);
  const [docVoiceLoader, setDocVoiceLoader] = useState(false);

  const [streamedData, setStreamedData] = useState("");

  const [docImageUrls, setDocImageUrls] = useState([]);
  const [docVideoUrls, setDocVideoUrls] = useState([]);

  const [buttonMessageId, setButtonMessageId] = useState("");

  const [selectedImageLoader, setSelectedImageLoader] = useState(false);

  const [isVoiceGenerating, setIsVoiceGenerating] = useState(false);
  const [audioData, setAudioData] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const [voice, setVoice] = useState(mappedVoices[14].voice);
  const [voiceDisplay, setVoiceDisplay] = useState(mappedVoices[14].display);

  const [inputValueAIVoice, setInputValueAIVoice] = useState("");

  const [isMultilingual, setMultilingual] = useState(true); //default is true
  const [internetQuery, setInternetQuery] = useState(false);

  const [inputValueAIMusic, setInputValueMusic] = useState("");

  const [fetchingMusicInBackground, setFetchingMusicInBackground] =
    useState(false);
  const [fetchSoundEffectsInBackground, setFetchSoundEffectsInBackground] =
    useState(false);

  const [effectDuration, setEffectDuration] = useState(5);

  const [musicDrawer, setMusicDrawer] = useState(false);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const [volume, setVolume] = useState(35); // Initial volume value
  const [selectedVariation, setSelectedVariation] = useState("");
  const [selectedGenre, setSelectedGenre] = useState("");
  const [selectedMood, setSelectedMood] = useState("");
  const [selectedArtist, setSelectedArtist] = useState("Hans-Zimmer");
  const [selectedInstrument, setSelectedInstrument] = useState("Piano");

  const [drawModalOpen, setLeodrawModalOpen] = useState(false);

  // INFO: Here we set files for image to image model, if files == 0 then we use prompt otherwise we loop through files
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedImageGenerationModel, setImageGenerationModel] =
    useState(null);

  // ai models
  const [promptModalOpen, setPromptModalOpen] = useState(false);
  const [voiceModalOpen, setVoiceModalOpen] = useState(false);
  const [imageSettingModalOpen, setImageSettingModalOpen] = useState(false);
  const [imageGeneratorModalOpen, setImageGeneratorModalOpen] = useState(false);
  const [videoSettingModalOpen, setVideoSettingModalOpen] = useState(false);

  //video settings
  const [videoAspectRatio, setVideoAspectRatio] = useState("");
  const [animationStyle, setAnimationStyle] = useState("");
  const [colorAndLighting, setColorAndLighting] = useState("");
  const [animationMoods, setAnimationMoods] = useState("");
  const [animationPrompt, setAnimationPrompt] = useState("");
  const [motionStrength, setMotionStrength] = useState(null);

  //image settings
  const [aspectRatio, setAspectRatio] = useState("");
  const [imageArtStyle, setImageArtStyle] = useState("");
  const [colorScheme, setColorScheme] = useState("");
  const [textStyle, setTextStyle] = useState("");
  const [imageTone, setImageTone] = useState("");
  const [seedValue, setSeedValue] = useState(null);
  const [presetStyle, setPresetStyle] = useState("DYNAMIC");
  const [characterRetention, setCharacterRetention] = useState(false);
  const [upscale, setUpscale] = useState("");
  const [outpaint, setOutpaint] = useState(false);
  const [removeBackground, setRemoveBackground] = useState(false);
  const [outpaintValues, setOutpaintValues] = useState({
    top: 100,
    bottom: 100,
    left: 100,
    right: 100,
  });

  const [musicUrl, setMusicUrl] = useState("");

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTone, setSelectedTone] = useState("");
  const [selectedPromptGenre, setSelectedPromptGenre] = useState("");
  const [selectedPersonality, setSelectedPersonality] = useState("");
  const [selectedWritingStyles, setSelectedWritingStyles] = useState("");
  const [selectedPersona, setSelectedPersona] = useState(
    selectedPersonaFromTrain ? selectedPersonaFromTrain : ""
  );
  const [showPromptButtons, setShowPromptButtons] = useState(false);
  const [promptLoading, setPromptLoading] = useState(false);
  const [prompts, setPrompts] = useState([]);
  const [docButtons, setDocButtons] = useState([
    {
      functionality: "gpt prompt",
      prompt:
        "Imagine that you are a professional document analysis research expert. Now that you have read the full text go through all its details and provide a succinct explanation of all the concepts covered in details for the user to understand easily. Your tone should be professional. Language should be simple. Use native script to respond if the text provided is not in English, otherwise ignore this instruction and provide the best explanation possible to the user.",
      name: "Explain Doc",
    },
    {
      functionality: "gpt prompt",
      prompt:
        "Imagine you are a document summary expert summarizing entire documents clearly and professionally with an overall summary followed by bullet points for each of the concepts mentioned in the text provided to you. Now go through the entire text and provide a general summary in a few sentences followed by bullet points to cover each and every topic mentioned in the document provided to you. Use native script to respond if the text provided is not in English, otherwise ignore this instruction. Keep the tone professional and the summary easy to understand for every user.",
      name: "Summarize Doc",
    },
  ]);
  const [promptShow, setPromptShow] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(null);

  const divRef = useRef(null);
  const messageRef = useRef([]);
  const menuRef = useRef(null);
  const imageRef = useRef(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarContent, setSnackbarContent] = useState("");

  const [animeUrl, setAnimeUrl] = useState("");
  const [isAnimeLoading, setIsAnimeLoading] = useState(false);

  const [microphoneIsOn, setMicrophoneIsOn] = useState(true);

  const [documentUpload, setDocumentUpload] = useState(trainGPT === "1");
  const [documentButtons, setDocumentButtons] = useState(trainGPT === "1");
  const [showDocIllustratorModal, setShowDocIllustratorModal] = useState(false);
  const [documentIllustrationText, setDocumentIllustrationText] = useState("");
  const [docNarrationModal, setDocumentNarrationModal] = useState(false);
  const [documentNarrationText, setDocumentNarrationText] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5000);

  const [isEditing, setIsEditing] = useState(
    new Array(messages.length).fill(false)
  );
  const [editedText, setEditedText] = useState(
    new Array(messages.length).fill("")
  );

  const [animateFromEdit, setAnimateFromEdit] = useState(false);

  const handleKeyDown = async (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      const msg = newMessage;
      setNewMessage(msg);
      setInputValue("");
      await onQuery();
    }
  };

  const directQuery = async (value) => {
    setPromptModalOpen(false);
    await onQuery();
  };

  const handleCopyPrompt = () => {
    const divElement = divRef.current;
    if (divElement) {
      const range = document.createRange();
      range.selectNode(divElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);

      try {
        document.execCommand("copy");
        setSnackbarOpen((prevState) => !prevState);
        setSnackbarSeverity("success");
        setSnackbarContent("Copied to clipboard!");
      } catch (error) {
        setSnackbarOpen((prevState) => !prevState);
        setSnackbarSeverity("error");
        setSnackbarContent("Failed to copy to clipboard");
      } finally {
        window.getSelection().removeAllRanges();
      }
    }
  };

  const handleGPTResponseCopy = (index) => {
    const divElement = messageRef.current[index];
    if (divElement) {
      const range = document.createRange();
      range.selectNode(divElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);

      try {
        document.execCommand("copy");
        setSnackbarOpen((prevState) => !prevState);
        setSnackbarSeverity("success");
        setSnackbarContent("Copied to clipboard!");
      } catch (error) {
        setSnackbarOpen((prevState) => !prevState);
        setSnackbarSeverity("error");
        setSnackbarContent("Failed to copy to clipboard");
      } finally {
        window.getSelection().removeAllRanges();
      }
    }
  };

  const handleGPTResponseDownload = (index) => {
    // Create a new document
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [new TextRun(messages[index].text)],
            }),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "text.docx");
    });
  };

  const handlePersonaChange = (newValue) => {
    try {
      setSelectedPersona(newValue);

      const persona_now = newValue;

      const mapping_object = persona_mapping[persona_now];

      setSelectedWritingStyles(mapping_object["writing-style"]);
      setSelectedPersonality(mapping_object.personality);
      setSelectedPromptGenre(mapping_object.genre);
      setSelectedTone(mapping_object.tone);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent("Oops! Something went wrong. Please try again later.");
      console.error(error);
    }
  };

  const handleWritingStylesChange = (newValue) => {
    setSelectedWritingStyles(newValue);
  };

  const handlePersonalityChange = (newValue) => {
    setSelectedPersonality(newValue);
  };

  const handlePromptGenreChange = (newValue) => {
    setSelectedPromptGenre(newValue);
  };

  const handleToneChange = (newValue) => {
    setSelectedTone(newValue);
  };

  const handleCategoryChange = (newValue) => {
    setSelectedCategory(newValue);
  };

  const handlePicassoModal = () => {
    setLeodrawModalOpen((prevState) => !prevState);
  };

  const handlePromptModal = () => {
    setPromptModalOpen((prevState) => !prevState);
  };

  const handleVoiceModal = () => {
    setVoiceModalOpen((prevState) => !prevState);
  };

  const handleImageSettingsModal = () => {
    console.log("reached imagesettingmodal")
    setImageSettingModalOpen((prevState) => !prevState);
  };

  const handleImageSettingsModalClose = () => {
    setImageSettingModalOpen((prevState) => !prevState);
    // setImages([]);
    navigate(`/c/${currentChatId}`);
  };

  const handleVideoSettingsModal = () => {
    setVideoSettingModalOpen((prevState) => !prevState);
  };

  const handleVideoSettingsModalClose = () => {
    setVideoSettingModalOpen((prevState) => !prevState);
    navigate(`/c/${currentChatId}`);
  };

  // image settings

  const handleImageModelSelection = (model) => {
    setImageGenerationModel(model);
  };

  const handleImageAspectRatio = (newValue) => {
    setAspectRatio(newValue);
  };

  const handleImageArtStyle = (newValue) => {
    setImageArtStyle(newValue);
  };

  const handleColorScheme = (newValue) => {
    setColorScheme(newValue);
  };

  const handleTextStyleChange = (newValue) => {
    setTextStyle(newValue);
  };

  const handleImageTone = (newValue) => {
    setImageTone(newValue);
  };

  const handleSeedValue = (newValue) => {
    setSeedValue(newValue);
  };

  const handlePresetStyle = (newValue) => {
    setPresetStyle(newValue);
  };

  const handleCharacterRetention = (newValue) => {
    setCharacterRetention(newValue);
  };

  const handleUpscaleChange = (newValue) => {
    setUpscale(newValue);
  };

  const handleOutpaintChange = (newValue) => {
    setOutpaint(newValue);
  };

  const handleRemoveBackgroundChange = (newValue) => {
    setRemoveBackground(newValue);
  };

  const handleOutpaintValuesChange = (direction, value) => {
    setOutpaintValues((prevValues) => ({
      ...prevValues,
      [direction]: value,
    }));
  };

  // video settings

  const handleVideoAspectRatio = (newValue) => {
    setVideoAspectRatio(newValue);
  };

  const handleMotionStrengthChange = (newValue) => {
    setMotionStrength(newValue);
  };

  const handleAnimationStyle = (newValue) => {
    setAnimationStyle(newValue);
  };

  const handleVideoColorAndLighting = (newValue) => {
    setColorAndLighting(newValue);
  };

  const handleAnimationMoods = (newValue) => {
    setAnimationMoods(newValue);
  };

  const handleAnimationPrompt = (e) => {
    setAnimationPrompt(e.target.value);
  };

  const handleVolumeChange = (newValue) => {
    setVolume(newValue);
  };

  const handleVariationChange = (newValue) => {
    setSelectedVariation(newValue);
  };

  const handleGenreChange = (newValue) => {
    setSelectedGenre(newValue);
  };

  const handleMoodChange = (newValue) => {
    setSelectedMood(newValue);
  };

  const handleArtistChange = (newValue) => {
    setSelectedArtist(newValue);
  };

  const handleInstrumentChange = (newValue) => {
    setSelectedInstrument(newValue);
  };

  const handleEffectDurationChange = (newValue) => {
    setEffectDuration(newValue);
  };

  const handleMusicDrawertoggle = () => {
    setMusicDrawer((prevState) => !prevState);
  };

  const toggleSideDrawer = () => {
    setSideDrawerOpen((prevState) => !prevState);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setVoice(e.target.id);
    setVoiceDisplay(e.target.innerText);
    setAnchorEl(null);
  };

  function listenToAnimationImagesSocket(
    messageId,
    updateImagesCallback,
    setLoading
  ) {
    const event = `additional video event:${messageId}`;

    socket.on(event, (data) => {
      updateImagesCallback(data.message.url);
      fetchData();
    });

    return () => {
      socket.off(event);
      setLoading(false);
    };
  }

  // INFO: handleSubmitForVideoModel for video model here...
  const handleSubmitForVideoModel = async () => {
    if (videos.length > 0) {
      await handleDirectAnimaion();
    } else {
      await handleDirectGetAnime();
    }
    navigate(`/c/${currentChatId}`);
  };

  const handleDirectAnimaion = async () => {
    try {
      if (animationPrompt === "" || !animationPrompt) {
        throw new Error(`Please enter a prompt`);
      }

      setDocVideoLoader(true);
      setVideoSettingModalOpen(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarContent(
        "Animation is being generated. You will be notified once it's ready."
      );

      const response = await getAnimationsDirectly(
        videos,
        currentUserId,
        currentChatId,
        animationPrompt,
        animateFromEdit ? "url" : "file", //uploadType
        videoAspectRatio,
        animationStyle,
        colorAndLighting,
        animationMoods,
        motionStrength
      );

      if (response.status !== 200) {
        throw new Error(`Oops! Something went wrong. Please try again later.`);
      } else {
        setDocVideoUrls(response.data.urls);
        setDocVideoLoader(false);
        setVideos([]);
        setMotionStrength(null);

        await fetchData();
      }
    } catch (error) {
      console.error("Error sending image request:", error);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(error.message);
      setDocVideoLoader(false);
      setVideoSettingModalOpen(false);
      setVideos([]);
      setMotionStrength(null);
    } finally {
      localStorage.removeItem("Image");
      localStorage.removeItem("CanvasImage");
      setAnimateFromEdit(false);
      setVideos([]);
      setMotionStrength(null);
    }
  };

  const handleAnimationOfImage = async (url, prompt) => {
    setUploadSource("canvas");
    setAnimationPrompt(prompt);
    setAnimateFromEdit(true);
    localStorage.setItem("CanvasImage", url);
    handleVideoSettingsModal("canvas");
  };

  const handleDirectAnimationFromImage = async (url, prompt) => {
    try {
      setDocVideoLoader(true);
      setVideoSettingModalOpen(false);

      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarContent(
        "Animation is being generated. You will be notified once it's ready."
      );

      const response = await getAnimationsDirectly(
        url,
        currentUserId,
        currentChatId,
        prompt,
        "url", //uploadType
        null, //aspectRatio
        animationStyle,
        colorAndLighting,
        animationMoods
      );

      if (response.status !== 200) {
        throw new Error(`Request failed with status code: ${response.status}`);
      } else {
        setDocVideoUrls(response.data.urls);
        setDocVideoLoader(false);

        await fetchData();
      }
    } catch (error) {
      console.error("Error sending image request:", error);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent("Oops! Something went wrong. Please try again later.");
      setDocVideoLoader(false);
      setVideoSettingModalOpen(false);
    } finally {
      localStorage.removeItem("Image");
      localStorage.removeItem("CanvasImage");
      setAnimateFromEdit(false);
      setVideos([]);
    }
  };

  const handleImageRegenerationPopup = async (url, prompt) => {
    setUploadSource("canvas");
    setDocumentIllustrationText(prompt);
    localStorage.setItem("CanvasImage", url);
    handleImageSettingsModal("canvas");
  };

  const handleImageRegeneration = async (url, prompt) => {
    try {
      setDocVideoLoader(true);
      setVideoSettingModalOpen(false);

      // Adding a step to search in messages and delete the matched image message
      setMessages((prevMessages) =>
        prevMessages.filter(
          (message) =>
            !(message.messageType === "image" && message.imageUrls === url)
        )
      );

      const response = await getRegeneratedImage(
        url,
        currentUserId,
        currentChatId,
        prompt
      );

      if (response.status !== 200) {
        throw new Error(`Request failed with status code: ${response.status}`);
      } else {
        setDocVideoUrls(response.data.urls);
        setDocVideoLoader(false);

        await fetchData();
      }
    } catch (error) {
      console.error("Error sending image request:", error);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent("Oops! Something went wrong. Please try again later.");
      setDocVideoLoader(false);
      setVideoSettingModalOpen(false);
    }
  };

  async function handleDirectGetAnime() {
    try {
      if (animationPrompt === "" || !animationPrompt) {
        throw new Error(`Please enter a prompt`);
      }
      setDocVideoLoader(true);
      setVideoSettingModalOpen(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarContent(
        "Animation is being generated. You will be notified once it's ready."
      );

      const response = await getDirectAnimatedVideo(
        animationPrompt,
        currentUserId,
        currentChatId,
        videoAspectRatio,
        animationStyle,
        animationMoods,
        colorAndLighting
      );

      if (response.status !== 200) {
        throw new Error(
          `We encountered an unexpected issue. Try again in a moment.`
        );
      }

      setDocVideoUrls([response.data.animeUrl]);
      setDocVideoLoader(false);

      await fetchData();
    } catch (err) {
      setDocVideoLoader(false);
      setVideoSettingModalOpen(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(err.message);
    }
  }

  async function handleTextToAnimateRegenerationOnEdit(url, prompt) {
    // setDocumentIllustrationText(prompt);
    setAnimationPrompt(prompt);
    handleVideoSettingsModal("canvas");
  }

  async function handleTextToAnimateRegeneration(url, prompt) {
    try {
      setDocVideoLoader(true);
      setVideoSettingModalOpen(false);

      const response = await getDirectAnimatedVideo(
        prompt,
        currentUserId,
        currentChatId,
        videoAspectRatio,
        animationStyle,
        animationMoods,
        colorAndLighting,
        url,
        true //regenFlag
      );

      if (response.status !== 200) {
        throw new Error(`Request failed with status code: ${response.status}`);
      }

      setDocVideoUrls([response.data.animeUrl]);
      setDocVideoLoader(false);

      await fetchData();
      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarContent(
        "Generation successful. Check Library for more details."
      );
    } catch (err) {
      setDocVideoLoader(false);
      setVideoSettingModalOpen(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(
        "We encountered an unexpected issue. Try again in a moment."
      );
    }
  }

  const handleSubmitForAIVoice = async () => {
    try {
      setAudioData("");
      setDocVoiceLoader(true);
      setVoiceModalOpen(false);

      const finalText = inputValueAIVoice;

      const response = await generateVoice({
        text: JSON.stringify(finalText),
        voice: voice,
        widgetId: currentChatId,
        isMultilingual: isMultilingual,
      });

      if (response.status !== 200) {
        throw new Error(`Request failed with status code: ${response.status}`);
      }

      if (response?.data?.status === "success") {
        const this_message = {
          audioData: `${process.env.REACT_APP_API_URL}text-to-speech/fetch-audio-stream/${currentChatId}?uid=${currentUserId}&cid=${currentChatId}&dm=${documentUpload}&dgm=true`,
          audioDataForDownload: `${process.env.REACT_APP_API_URL}text-to-speech/fetch-audio-download/${currentChatId}?uid=${currentUserId}&cid=${currentChatId}&dm=${documentUpload}&dgm=true`,
          type: "audio",
        };
        setMessages((prevMessages) => [...prevMessages, this_message]);
        setDocVoiceLoader(false);
      }
    } catch (err) {
      setDocVoiceLoader(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(
        "We encountered an unexpected issue. Try again in a moment."
      );
      setVoiceModalOpen(false);
    }
  };

  const handleimageanimations = async (messageId) => {
    try {
      setIsAnimeLoading(true);

      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarContent(
        "Animation is being generated. You will be notified once it's ready."
      );

      const stopListening = listenToAnimationImagesSocket(
        messageId,
        (newImageUrl) => {
          fetchLastNonHumanMessageAndUpdate({
            imageBasedAnimations: [newImageUrl],
          });
        },
        setIsAnimeLoading
      );

      const response = await getImageAnimations(
        currentUserId,
        currentChatId,
        messageId
      );

      if (response.status !== 200) {
        throw new Error(`Request failed with status code: ${response.status}`);
      }

      fetchLastNonHumanMessageAndUpdate({
        imageBasedAnimations: response?.data?.urls,
      });
      setIsAnimeLoading(false);
      stopListening();

      await fetchData();
    } catch (err) {
      setIsAnimeLoading(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(
        "We encountered an unexpected issue. Try again in a moment."
      );
    }
  };

  function listenToAdditionalImagesSocket(
    messageId,
    updateImagesCallback,
    setLoading
  ) {
    const event = `additional image event:${messageId}`;

    socket.on(event, (data) => {
      updateImagesCallback(data.message.url);
      fetchData();
    });

    return () => {
      socket.off(event);
      setLoading(false);
    };
  }

  const handleaddonimagegen = async (messageId) => {
    try {
      setImageLoader(true);

      const stopListening = listenToAdditionalImagesSocket(
        messageId,
        (newImageUrl) => {
          fetchLastNonHumanMessageAndUpdate({ addtionalImages: [newImageUrl] });
        },
        setImageLoader
      );

      const lastMessage = messages.slice(-1)[0];
      const message = !lastMessage.isHuman ? lastMessage.text : "";
      const length = message.length;

      const response = await sendAddonImageRequest(
        message,
        lastMessage.additionalImages[0]?.url,
        currentUserId,
        currentChatId,
        currentMsgId
      );

      if (response.status !== 200) {
        throw new Error(`Request failed with status code: ${response.status}`);
      } else {
        setIfImage((prevState) => !prevState);
        setImageLoader((prevState) => !prevState);

        fetchLastNonHumanMessageAndUpdate({
          addtionalImages: response?.data?.urls,
        });
        fetchData();
        stopListening();
      }
    } catch (error) {
      console.error("Error sending image request:", error);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(
        "We encountered an unexpected issue. Try again in a moment."
      );
      setImageLoader(false);
    }
  };

  const handleimagegen = async (messageId) => {
    try {
      setImageLoader(true);
      setImageGeneratorModalOpen(false); // for text to image ; slow gen

      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarContent(
        "Images are being generated. You will be notified once it's ready."
      );

      const lastMessage = messages.slice(-1)[0];
      const message = !lastMessage.isHuman ? lastMessage.text : "";

      const response = await sendImageRequest(
        message,
        currentUserId,
        currentChatId,
        currentMsgId,
        documentUpload,
        aspectRatio,
        imageArtStyle,
        colorScheme,
        imageTone
      );

      if (response.status !== 200) {
        throw new Error(`Request failed with status code: ${response.status}`);
      } else {
        setIfImage((prevState) => !prevState);
        setImageLoader((prevState) => !prevState);
        fetchData();
      }
    } catch (error) {
      console.error("Error sending image request:", error);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(
        "We encountered an unexpected issue. Try again in a moment."
      );
      setImageLoader(false);
    }
  };

  const handleimagegenv2 = async (messageId) => {
    try {

      if (!documentIllustrationText || documentIllustrationText === "") {
        throw new Error("Please enter some text to generate images.");
      }
      
      setImageLoader(true);
      setImageGeneratorModalOpen(false); // for text to image ; slow gen; model 2
      setImageSettingModalOpen(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarContent(
        "Images are being generated. You will be notified once it's ready."
      );

      const response = await sendImageRequest(
        documentIllustrationText,
        currentUserId,
        currentChatId,
        currentMsgId,
        true,
        aspectRatio,
        imageArtStyle,
        colorScheme,
        imageTone
      );

      if (response.status !== 200) {
        throw new Error(`We encountered an unexpected issue. Try again in a moment.`);
      } else {
        setIfImage((prevState) => !prevState);
        setImageLoader((prevState) => !prevState);
        await fetchData();
      }
    } catch (error) {
      console.error("Error sending image request:", error);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(error.message);
      setImageLoader(false);
    }
  };

  const handleimagegenv3 = async (messageId) => {
    try {
      if (!documentIllustrationText || documentIllustrationText === "") {
        throw new Error("Please enter some text to generate images.");
      }
      
      setImageLoader(true);
      setImageGeneratorModalOpen(false); // for text to image ; slow gen; model 2
      setImageSettingModalOpen(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarContent(
        "Images are being generated. You will be notified once it's ready."
      );

      const response = await sendImageRequestv5(
        documentIllustrationText,
        currentUserId,
        currentChatId,
        true,
        aspectRatio,
        imageArtStyle,
        colorScheme,
        imageTone,
        textStyle
      );

      if (response.status !== 200) {
        throw new Error("We encountered an unexpected issue. Try again in a moment.");
      } else {
        setIfImage((prevState) => !prevState);
        setImageLoader((prevState) => !prevState);
        await fetchData();
      }
    } catch (error) {
      console.error("Error sending image request:", error);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(error.message);
      setImageLoader(false);
    }
  };

  const handlePromptClick = (e) => {
    setPromptShow((prevState) => !prevState);

    setSelectedPrompt(e.target.name);
  };

  const handleAudioDownload = async (audioData) => {
    setSnackbarOpen(true);
    setSnackbarSeverity("info");
    setSnackbarContent("Preparing download...");

    // Error handling function
    const handleError = (message) => {
      console.error(message);
      setSnackbarSeverity("error");
      setSnackbarContent(message);
    };

    // Function to initiate download
    const initiateDownload = (blob, filename) => {
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = filename;
      document.body.appendChild(anchor); // Necessary for Firefox
      anchor.click();
      window.URL.revokeObjectURL(url);
      anchor.remove();
    };

    if (typeof audioData === "string") {
      if (audioData.startsWith("http://")) {
        // Fetch audio data from URL
        try {
          const response = await fetch(audioData);
          if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.statusText}`);
          }
          const blob = await response.blob();
          initiateDownload(blob, "Wishtales-audio.mp3");
          setSnackbarSeverity("success");
          setSnackbarContent("Download started...");
        } catch (error) {
          handleError(`Error downloading audio: ${error.message}`);
        }
      } else if (audioData.startsWith("https://")) {
        // Handle Base64 encoded audio string
        try {
          const audioSource = audioData;
          const anchor = document.createElement("a");
          anchor.href = audioSource;
          anchor.download = "Wishtales-audio.mp3";
          anchor.click();
          setSnackbarSeverity("success");
          setSnackbarContent("Download started...");
        } catch (error) {
          handleError("Error processing base64 audio data.");
        }
      } else {
        // Handle Base64 encoded audio string
        try {
          const audioSource = `data:audio/mp3;base64,${audioData}`;
          const anchor = document.createElement("a");
          anchor.href = audioSource;
          anchor.download = "Wishtales-audio.mp3";
          anchor.click();
          setSnackbarSeverity("success");
          setSnackbarContent("Download started...");
        } catch (error) {
          handleError("Error processing base64 audio data.");
        }
      }
    } else {
      handleError("Invalid audio data provided.");
    }
  };

  const handleChatImageDownload = (imageUrl) => {
    setSnackbarOpen(true);
    setSnackbarSeverity("success");
    setSnackbarContent("Downloading..");

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      canvas.toBlob((blob) => {
        saveAs(blob, "Wishtales-image.png");
      });
    };
    image.src = imageUrl;
  };

  const handleMultilingual = () => {
    setMultilingual((prevState) => !prevState);
  };

  const handleInternetQuery = () => {
    setInternetQuery((prevState) => !prevState);
  };

  const handleVoiceGeneration = async (messageId) => {
    try {
      setIsVoiceGenerating(true);

      const lastMessage = messages.slice(-1)[0];
      const message = !lastMessage.isHuman ? lastMessage.text : "";

      const formattedMessage = message.replace(/[#*_>\[\]()\-+!|`]/g, "");
      const widgetId = messageId;

      const response = await generateVoice({
        text: formattedMessage,
        voice: voice,
        widgetId: widgetId,
        isMultilingual: isMultilingual,
      });

      if (response.status !== 200) {
        throw new Error(`Request failed with status code: ${response.status}`);
      }

      if (response?.data?.status === "success") {
        await fetchLastNonHumanMessageAndUpdate({
          audioData: `${process.env.REACT_APP_API_URL}text-to-speech/fetch-audio-stream/${widgetId}?cid=${currentChatId}&msgId=${widgetId}&dm=${documentUpload}&dgm=false`,
          audioDataForDownload: `${process.env.REACT_APP_API_URL}text-to-speech/fetch-audio-download/${widgetId}?cid=${currentChatId}&msgId=${widgetId}&dm=${documentUpload}&dgm=false`,
        });
        setIsVoiceGenerating((prevState) => !prevState);
      }
    } catch (err) {
      setIsVoiceGenerating(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(
        "We encountered an unexpected issue. Try again in a moment."
      );
    }
  };

  const generatePrompts = async () => {
    try {
      const data = {
        prompt: promptForEngine,
        persona: selectedPersona,
        genre: selectedPromptGenre,
        writingStyle: selectedWritingStyles,
        tone: selectedTone,
        personalize: selectedPersonality,
      };

      setPromptLoading((prevState) => !prevState);

      let response;
      console.log(selectedCategory);
      if (selectedCategory === "entertainment") {
        response = await getPromptsFromEngine(data);
      } else {
        response = await getEduPromptsFromEngine(data);
      }

      if (response.status !== 200) {
        throw new Error(`Request failed with status code: ${response.status}`);
      }
      const prompts = [];

      Object.keys(response.data).forEach((key) => {
        prompts.push(response.data[key]);
      });

      setPrompts(prompts);

      setShowPromptButtons(true);
      setPromptLoading(false);
    } catch (error) {
      setPromptLoading(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(
        "We encountered an unexpected issue. Try again in a moment."
      );
    }
  };

  const getMusic = async () => {
    try {
      const data = {
        prompt: inputValueAIMusic ? inputValueAIMusic : "",
        composer: selectedArtist,
        genre: selectedGenre,
        mood: selectedMood,
        instruments: selectedInstrument,
        chatId: currentChatId,
      };
      if (
        selectedArtist === "" ||
        selectedGenre === "" ||
        selectedMood === "" ||
        selectedVariation === ""
      ) {
        throw new Error(`Please fill all the fields to generate music.`);
      }

      setFetchingMusicInBackground(true);
      handleMusicDrawertoggle();
      const response = await getBackgroundMusic(data);

      if (response.status !== 200 || response.data === null) {
        throw new Error(
          `An error occurred while generating music. Please try again later.`
        );
      }

      let promptVersion = "";
      if (selectedVariation === "Tune 1") promptVersion = "prompt_1";
      if (selectedVariation === "Tune 2") promptVersion = "prompt_2";
      if (selectedVariation === "Tune 3") promptVersion = "prompt_3";
      if (selectedVariation === "Tune 4") promptVersion = "prompt_4";

      const prompt = response.data[promptVersion];

      const urlResponse = await getMusicUrl(
        prompt,
        currentUserId,
        currentChatId
      );

      if (urlResponse.status !== 200) {
        throw new Error(
          `An error occurred while generating music. Please try again later.`
        );
      }
      setMusicUrl(urlResponse?.data?.musicUrl);
      setFetchingMusicInBackground((prevState) => !prevState);
      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarContent("Music ready to play.");

      await fetchData();
    } catch (error) {
      console.error(error);
      setFetchingMusicInBackground(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(error.message);
      setMusicDrawer(false);
    }
  };

  const getSoundEffect = async () => {
    try {
      const prompt = inputValueAIMusic ? inputValueAIMusic : "";
      const duration = effectDuration ? effectDuration : 5;

      if (inputValueAIMusic === "" || !inputValueAIMusic) {
        throw new Error(`Please enter a prompt generate sound effect.`);
      }

      setFetchSoundEffectsInBackground(true);
      handleMusicDrawertoggle();

      const urlResponse = await getSoundEffectsUrl(
        prompt,
        duration,
        currentUserId,
        currentChatId
      );

      if (urlResponse.status !== 200) {
        throw new Error(
          `An error occurred while generating music. Please try again later.`
        );
      }
      setMusicUrl(urlResponse?.data?.url);
      setFetchSoundEffectsInBackground(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarContent("Sound effect ready to play.");

      await fetchData();
    } catch (error) {
      console.error(error);
      setFetchSoundEffectsInBackground(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(error.message);
      setMusicDrawer(false);
    }
  };

  const getSoundEffectFromVideo = async (videos) => {
    try {
      const prompt = inputValueAIMusic ? inputValueAIMusic : "";
      const duration = effectDuration ? effectDuration : 5;

      if (inputValueAIMusic === "" || !inputValueAIMusic) {
        throw new Error(`Please enter a prompt generate sound effect.`);
      }

      setFetchSoundEffectsInBackground(true);
      handleMusicDrawertoggle();

      const urlResponse = await getSoundEffectsFromVideo(
        prompt,
        duration,
        currentUserId,
        currentChatId,
        videos
      );

      if (urlResponse.status !== 200) {
        throw new Error(
          `An error occurred while generating music. Please try again later.`
        );
      }
      setMusicUrl(urlResponse?.data?.url);
      setFetchSoundEffectsInBackground(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarContent("Sound effect ready to play.");

      await fetchData();
    } catch (error) {
      console.error(error);
      setFetchSoundEffectsInBackground(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(error.message);
      setMusicDrawer(false);
    }
  };

  const getAnime = async (messageId) => {
    try {
      const lastMessage = messages.slice(-1)[0];
      const message = !lastMessage.isHuman ? lastMessage.text : "";

      setIsAnimeLoading(true);
      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarContent(
        "Animation is being generated. You will be notified once it's ready."
      );

      const response = await getAnimatedVideo(
        message,
        currentUserId,
        messageId,
        currentChatId,
        null, //videoAspectRatio
        animationStyle,
        animationMoods,
        colorAndLighting
      );

      if (response.status !== 200) {
        throw new Error(`Request failed with status code: ${response.status}`);
      }

      setAnimeUrl(response?.data?.animeUrl);
      fetchLastNonHumanMessageAndUpdate({
        animeUrl: response?.data?.animeUrl,
      });
      setIsAnimeLoading(false);
      await fetchData();
    } catch (err) {
      setIsAnimeLoading(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(
        "We encountered an unexpected issue. Try again in a moment."
      );
    }
  };

  const extractTextFromBlob = async (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        const arrayBuffer = reader.result;
        const buffer = new Buffer.from(arrayBuffer);
        try {
          const result = await mammoth.extractRawText({ arrayBuffer: buffer });
          resolve(result.value);
        } catch (error) {
          setSnackbarOpen(true);
          setSnackbarSeverity("error");
          setSnackbarContent(
            "We encountered an unexpected issue. Try again in a moment."
          );
          reject(error);
        }
      };
      reader.readAsArrayBuffer(blob);
    });
  };

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    console.error("Browser doesn't support speech recognition");
  }

  const handleListeningFromMic = async () => {
    setMicrophoneIsOn((prevState) => !prevState);

    if (microphoneIsOn) {
      if (!browserSupportsSpeechRecognition) {
        console.log("Browser doesn't support speech recognition");
        setSnackbarOpen(true);
        setSnackbarSeverity("warning");
        setSnackbarContent("Voice input isn't supported on this browser.");
        setMicrophoneIsOn((prevState) => !prevState);
        return;
      }
      //start
      SpeechRecognition.startListening({ continuous: true });

      setSnackbarOpen(true);
      setSnackbarSeverity("warning");
      setSnackbarContent("Mic is on");
    } else {
      SpeechRecognition.stopListening();
      setSnackbarOpen(true);
      setSnackbarSeverity("warning");
      setSnackbarContent("Mic is off");
      setNewMessage(transcript);
    }

    resetTranscript();
  };

  const handleDocButtonClick = async (e) => {
    setNewMessage(e.target.name);
    setInputValue(e.target.name);
  };

  const onQuery = async (
    editIndex = null,
    editText = null,
    micMode = false
  ) => {
    try {
      setInputValue("");
      if (
        promptForEngine &&
        newMessage.trim() === "" &&
        editIndex == null &&
        (transcript === "" || transcript == undefined || !transcript)
      ) {
        await generatePrompts();
        return;
      }

      if (!editIndex && !editText && !newMessage && !transcript) {
        throw new Error("Please enter a message to query.");
      }

      const newMessageId = `${currentUserId}m${generateUniqueNumber()}`;

      const inputMessage = {
        messageId: newMessageId,
        text:
          editIndex >= 0 && editText
            ? editText
            : transcript === "" || transcript == undefined || !transcript
            ? newMessage
            : transcript,
        editText: editText,
        isHuman: true,
        type: "text",
      };
      setMessages((prevMessages) => [...prevMessages, inputMessage]);
      setInQuery((prevState) => !prevState);
      if (showPromptButtons) setShowPromptButtons((prevState) => !prevState);

      const exportedDocument = await editorContent.saveAsBlob("Docx");
      let text = await extractTextFromBlob(exportedDocument);
      text = text.trim(); // Normalize the text by trimming whitespace

      if (documentUpload && !trainGPT) {
        if (text === "") {
          // When documentUpload is true and text is empty, throw an error
          setMessages((prevMessages) => prevMessages.slice(0, -1));
          throw new Error("Document is empty. Please enter some text.");
        }
      } else if (trainGPT) {
        // If trainGPT is true, set text to a default value when empty and continue processing
        if (text === "") {
          text = "Training on document content.";
        }
      }

      const finalText = `${inputMessage.text}`;

      const apiUrl = `${process.env.REACT_APP_API_URL}chat/query`;

      setCurrentMsgId(newMessageId);

      const requestBody = {
        persona: selectedPersona,
        tone: selectedTone,
        writingStyle: selectedWritingStyles,
        genre: selectedPromptGenre,
        famousPersonality: selectedPersonality,
        content: documentUpload ? text : "",
        trainGPT: trainGPT === "1" ? true : false,
        domain: selectedDomain ? selectedDomain : false,
        userId: currentUserId,
        chatId: currentChatId,
        internetQuery: internetQuery ? true : false,
        messageId: newMessageId,
        chat: {
          query: finalText,
          modelVersion: "four",
        },
        edit: editIndex === 0 ? true : !editIndex ? false : true,
        index:
          editIndex === 0
            ? messages[0].messageId
            : !editIndex
            ? null
            : messages[editIndex].messageId,
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      let streamData = "";
      let iterationCount = 0;

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }

        const decodedText = new TextDecoder().decode(value);
        streamData += decodedText;
        setStreamedData((prevData) => prevData + decodedText);

        const message = {
          messageId: newMessageId, // Ensure this is defined or updated elsewhere as needed
          imageData: null,
          selectedImageUrl: null,
          audioData: null,
          animeUrl: null,
          text: streamData,
          isHuman: false,
          type: "text",
        };

        setMessages((prevMessages) => {
          const lastMessage = prevMessages[prevMessages.length - 1];
          if (iterationCount > 0 && (!lastMessage || !lastMessage.isHuman)) {
            const updatedMessages = [...prevMessages];
            updatedMessages[updatedMessages.length - 1] = message;
            return updatedMessages;
          }
          return [...prevMessages, message];
        });

        iterationCount++;
      }

      setCurrentChatId(currentChatId);
      setInQuery((prevState) => !prevState);
      setNewMessage("");
      setPromptForEngine("");
      resetTranscript();
    } catch (error) {
      console.error("Error:", error);
      setInQuery(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(
        error.message ||
          "We encountered an unexpected issue. Try again in a moment."
      );
      resetTranscript();
    }
  };
  const onChangeInputAIVoice = (event) => {
    setInputValueAIVoice(event.target.value);
  };
  const onChangeInputAIMusic = (event) => {
    setInputValueMusic(event.target.value);
  };

  const onSaveToCloud = () => {
    editorContent.saveAsBlob("Docx").then(async (exportedDocument) => {
      // Create a new JSZip instance and load the exported Blob
      const zip = new JSZip();
      const zipData = await zip.loadAsync(exportedDocument);

      const zipBlob = await zipData.generateAsync({ type: "blob" });

      // Create a new FormData object
      const formData = new FormData();
      formData.append("zipFile", zipBlob, "exported_files.zip");
      formData.append("userId", currentUserId);
      formData.append("chatId", currentChatId);

      const url = `${process.env.REACT_APP_API_URL}save`;

      // Make the POST request to the API using Axios
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        responseType: "blob",
      });

      // Check the response status
      if (response.status === 200) {
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarContent("Uploaded successfully");
      } else {
        console.error("Upload failed. Status:", response.status);
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setSnackbarContent(
          "We encountered an unexpected issue. Try again in a moment."
        );
      }
    });
  };

  const onUploadToGpt = async () => {
    setDocumentUpload((prevState) => !prevState);
    setDocumentButtons((prevState) => !prevState);
  };

  const handleDocNarrate = async () => {
    try {
      setAudioData("");
      setIsVoiceGenerating(true);
      setDocumentNarrationModal(false);

      const finalText = documentNarrationText;

      const response = await generateVoice({
        text: JSON.stringify(finalText),
        voice: voice,
        widgetId: currentChatId,
        isMultilingual: isMultilingual,
      });

      if (response.status !== 200) {
        throw new Error(`Request failed with status code: ${response.status}`);
      }

      if (response?.data?.status === "success") {
        const this_message = {
          audioData: `${process.env.REACT_APP_API_URL}text-to-speech/fetch-audio-stream/${currentChatId}?uid=${currentUserId}&cid=${currentChatId}&dm=${documentUpload}&dgm=false`,
          audioDataForDownload: `${process.env.REACT_APP_API_URL}text-to-speech/fetch-audio-download/${currentChatId}?uid=${currentUserId}&cid=${currentChatId}&dm=${documentUpload}&dgm=false`,
          type: "audio",
        };
        setMessages((prevMessages) => [...prevMessages, this_message]);
        setIsVoiceGenerating((prevState) => !prevState);
      }
    } catch (err) {
      setIsVoiceGenerating(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(
        "We encountered an unexpected issue. Try again in a moment."
      );
    }
  };

  const handleSubmitForImageModal = async () => {
    if (images.length > 0) {
      await handleDirectIllustration();
    } else {
      switch (selectedImageGenerationModel) {
        case "model_1":
          await handleDocIllustration();
          break;
        case "model_2":
          await handleimagegenv2();
          break;
        case "model_3":
          await handleimagegenv3();
          break;
        default:
          console.log("No valid model selected");
          break;
      }
    }
    setImages([]);
    navigate(`/c/${currentChatId}`);
  };

  const handleDirectIllustration = async () => {
    try {
      if (
        (!documentIllustrationText || documentIllustrationText === "") &&
        selectedImageGenerationModel !== "model_4"
      ) {
        throw new Error("Please enter some text to generate images.");
      }
      setDocImageLoader(true);
      setImageSettingModalOpen(false);

      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarContent(
        "Images are being generated. You will be notified once it's ready."
      );

      const message = documentIllustrationText;

      let response;

      if (characterRetention || selectedImageGenerationModel === "model_2") {
        response = await getImagesDirectlyWithCSRF(
          images,
          currentUserId,
          currentChatId,
          message,
          aspectRatio,
          imageArtStyle,
          colorScheme,
          imageTone
        );
      } else if (selectedImageGenerationModel === "model_3") {
        response = await sendImageRequestv4(
          images,
          currentUserId,
          currentChatId,
          documentIllustrationText,
          aspectRatio,
          imageArtStyle,
          colorScheme,
          imageTone
        );
      } else if (
        selectedImageGenerationModel === "model_4" &&
        upscale === "Creative Upscale"
      ) {
        response = await sendImageRequestv6(
          images,
          currentUserId,
          currentChatId
        );
      } else if (
        selectedImageGenerationModel === "model_4" &&
        upscale === "Conservative Upscale"
      ) {
        response = await sendImageRequestv7(
          images,
          currentUserId,
          currentChatId
        );
      } else if (
        selectedImageGenerationModel === "model_4" &&
        removeBackground
      ) {
        response = await sendImageRequestv8(
          images,
          currentUserId,
          currentChatId
        );
      } else if (selectedImageGenerationModel === "model_4" && outpaint) {
        response = await sendImageRequestv9(
          images,
          currentUserId,
          currentChatId,
          outpaintValues
        );

        setOutpaintValues({ left: 100, right: 100, top: 100, bottom: 100 });
      } else {
        response = await getImagesDirectly(
          images,
          currentUserId,
          currentChatId,
          message,
          aspectRatio,
          imageArtStyle,
          colorScheme,
          imageTone
        );
      }

      if (response.status !== 200) {
        throw new Error(`Oops! Something went wrong. Please try again later.`);
      } else {
        setDocImageUrls(response.data.urls);
        setDocImageLoader(false);
        setImages([]);

        await fetchData();
      }
    } catch (error) {
      console.error("Error sending image request:", error);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(error.message);
      setDocImageLoader(false);
      setImageSettingModalOpen(false);
      setImages([]);
    } finally {
      localStorage.removeItem("Image");
      localStorage.removeItem("CanvasImage");
      setImages([]);
      setRemoveBackground(false);
      setOutpaint(false);
    }
  };

  const handleDocIllustration = async () => {
    try {
      if (!documentIllustrationText || documentIllustrationText === "") {
        throw new Error("Please enter some text to generate images.");
      }
      setDocImageLoader(true);
      setImageSettingModalOpen(false);
      setShowDocIllustratorModal(false);

      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarContent(
        "Images are being generated. You will be notified once it's ready."
      );

      const message = documentIllustrationText;

      const response = await sendImageRequestv2(
        message,
        currentUserId,
        currentChatId,
        aspectRatio,
        imageArtStyle,
        colorScheme,
        imageTone,
        seedValue,
        presetStyle
      );

      if (response.status !== 200) {
        throw new Error(`Oops! Something went wrong. Please try again later.`);
      } else {
        setDocImageLoader(false);
        await fetchData();
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarContent(
          "Generation successful. Check Library for more details."
        );
      }
    } catch (error) {
      console.error("Error sending image request:", error);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent(error.message);
      setImageLoader(false);
      setDocImageLoader(false);
      setImageSettingModalOpen(false);
    }
  };

  const handleFastIllustration = async () => {
    try {
      setDocImageLoader(true);
      setImageGeneratorModalOpen(false);

      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarContent(
        "Images are being generated. You will be notified once it's ready."
      );

      const lastMessage = messages.slice(-1)[0];
      const message = !lastMessage.isHuman ? lastMessage.text : "";

      const response = await sendImageRequestv3(
        message,
        currentUserId,
        currentChatId,
        currentMsgId,
        documentUpload
      );

      if (response.status !== 200) {
        throw new Error(`Request failed with status code: ${response.status}`);
      } else {
        setDocImageLoader(false);

        await fetchData();
      }
    } catch (error) {
      console.error("Error sending image request:", error);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent("Oops! Something went wrong. Please try again later.");
      setImageLoader(false);
      setDocImageLoader(false);
      setImageGeneratorModalOpen(false);
    }
  };

  const handleDocIllustrationClick = () => {
    setShowDocIllustratorModal((prevState) => !prevState);
  };

  const handleDocIllustrationText = (e) => {
    setDocumentIllustrationText(e.target.value);
  };

  const handleDocNarrationText = (e) => {
    setDocumentNarrationText(e.target.value);
    const text = e.target.value;
    setStartIndex(text.length);
  };

  const handleDocNarrationClick = () => {
    setDocumentNarrationModal((prevState) => !prevState);
    setStartIndex(0);
  };

  const handleDeleteMessage = async (id, index) => {
    const response = await deleteMessageFromChat({
      chatId: currentChatId,
      messageId: id,
    });
    if (response.status === 200) {
      const indexToRemove = index * 2;
      const updatedMessages = [...messages];
      // Check if the index is valid
      if (indexToRemove >= 0 && indexToRemove < updatedMessages.length) {
        updatedMessages.splice(indexToRemove, 1);
        updatedMessages.splice(indexToRemove, 1);
        setMessages(updatedMessages);
      }
    }
  };

  const handleTextChange = (index, newText) => {
    const newEditedText = [...editedText];
    newEditedText[index] = newText;
    setEditedText(newEditedText);
  };

  const toggleEdit = (index, message) => {
    const newEditingState = [...isEditing];
    newEditingState[index] = !newEditingState[index];
    setIsEditing(newEditingState);
    handleTextChange(index, message);
  };

  const handleSave = async (index) => {
    try {
      const newEditingState = [...isEditing];
      newEditingState[index] = false;
      setIsEditing(newEditingState);

      let updatedMessages = [...messages];
      updatedMessages[index].text = editedText[index];
      updatedMessages = updatedMessages.slice(0, index);
      setMessages(updatedMessages);

      await onQuery(index, editedText[index], false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCopyImage = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      if (!window.ClipboardItem) {
        setSnackbarOpen(true);
        setSnackbarSeverity("warning");
        setSnackbarContent(
          "Clipboard functionality is not supported in this browser."
        );
        return;
      }

      if (blob.type === "image/jpeg" || blob.type === "image/jpg") {
        // Convert JPEG to PNG using a canvas
        const img = await createImageBitmap(blob);
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const pngBlob = await new Promise((resolve) =>
          canvas.toBlob(resolve, "image/png")
        );

        await navigator.clipboard.write([
          new ClipboardItem({
            "image/png": pngBlob,
          }),
        ]);
      } else {
        // For supported types, use directly
        await navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob,
          }),
        ]);
      }

      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarContent("Image copied to clipboard.");
    } catch (err) {
      console.error(err);
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setSnackbarContent("Oops.. something went wrong.");
    }
  };

  const shouldAutoScroll =
    inQuery ||
    docImageLoader ||
    docVideoLoader ||
    docVoiceLoader ||
    isAnimeLoading ||
    selectedImageLoader ||
    imageLoader ||
    isVoiceGenerating ||
    fetchingMusicInBackground ||
    fetchSoundEffectsInBackground;

  let endOfMessagesRef = useRef();
  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [shouldAutoScroll, messages]);

  return (
    <div>
      <TopAppBar toggleSideDrawer={toggleSideDrawer} />
      <div className="main-container custom-scrollbar">
        <Grid container spacing={2}>
          <Grid
            className="custom-scrollbar"
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Editor
              onChangeContent={handleEditorData}
              onEditorContentChange={handleChangeInEditor}
            />
          </Grid>
          <Chat
            onSaveToCloud={onSaveToCloud}
            onSave={saveContentAsDocx}
            onSaveAsPDF={saveContentAsPdf}
            onSaveAsBlob={saveContentAsHtml}
            handleModal={handlePicassoModal}
            openi={openi}
            setOpen={setOpen}
          >
            <div className="chatbox">
              {showPromptButtons ? (
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ padding: "2%" }}
                >
                  {prompts.map((prompt, i) => (
                    <Button
                      id="basic-button"
                      variant="outlined"
                      aria-haspopup="true"
                      size="large"
                      name={prompt}
                      style={{ border: "1px solid white", color: "white" }}
                      onClick={handlePromptClick}
                    >
                      {`P${i + 1}`}
                    </Button>
                  ))}
                </Stack>
              ) : (
                ""
              )}

              {documentButtons ? (
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ padding: "2%" }}
                >
                  {docButtons.map((item, i) => (
                    <Button
                      id="basic-button"
                      variant="outlined"
                      aria-haspopup="true"
                      size="large"
                      name={item.prompt}
                      key={i}
                      style={{ border: "1px solid white", color: "white" }}
                      onClick={handleDocButtonClick}
                    >
                      {item.name}
                    </Button>
                  ))}
                  <Button
                    id="basic-button"
                    variant="outlined"
                    aria-haspopup="true"
                    size="large"
                    style={{ border: "1px solid white", color: "white" }}
                    onClick={handleDocNarrationClick}
                  >
                    {`Narrate Doc`}
                  </Button>
                  <Button
                    id="basic-button"
                    variant="outlined"
                    aria-haspopup="true"
                    size="large"
                    style={{ border: "1px solid white", color: "white" }}
                    onClick={handleDocIllustrationClick}
                  >
                    {`Illustrate Doc`}
                  </Button>
                </Stack>
              ) : (
                ""
              )}

              {!chatLoader ? (
                <>
                  <div className="chatbox-container">
                    {messages.map((message, index) => (
                      <div
                        key={index}
                        style={{
                          clear: "both",
                          marginBottom: "10px",
                          textAlign: "left",
                        }}
                      >
                        {/* message */}
                        <div
                          className="message-view"
                          ref={(el) => (messageRef.current[index] = el)}
                          key={index}
                        >
                          <div className="message-avatar">
                            {message.isHuman ? (
                              <CustomAvatar
                                color="orange"
                                name={
                                  user ? user?.userName[0].toUpperCase() : "U"
                                }
                              />
                            ) : (
                              <CustomAvatar
                                color="white"
                                name="WI"
                                imgUrl={Logo}
                              />
                            )}
                          </div>
                          <div className="message">
                            <>
                              {isEditing[index] ? (
                                <textarea
                                  value={editedText[index]}
                                  onChange={(e) =>
                                    handleTextChange(index, e.target.value)
                                  }
                                  className="edit-input"
                                  rows="4" // Initial number of rows
                                ></textarea>
                              ) : (
                                <RenderMarkdown markdown={message.text} />
                              )}
                            </>
                          </div>
                          {message.isHuman && message.type === "text" ? (
                            <div>
                              <button
                                style={{
                                  border: "none",
                                  backgroundColor: "transparent",
                                  marginRight: "8px",
                                  cursor: "pointer",
                                }}
                                onClick={() => toggleEdit(index, message.text)}
                              >
                                {isEditing[index] ? (
                                  "Cancel"
                                ) : (
                                  <EditIcon style={{ fontSize: "14px" }} />
                                )}
                              </button>
                              {isEditing[index] ? (
                                <button
                                  style={{
                                    border: "none",
                                    backgroundColor: "transparent",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleSave(index)}
                                >
                                  Save & Submit
                                </button>
                              ) : (
                                <button
                                  style={{
                                    border: "none",
                                    backgroundColor: "transparent",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleDeleteMessage(
                                      message.messageId,
                                      Math.floor(index / 2)
                                    )
                                  }
                                >
                                  <DeleteIcon style={{ fontSize: "14px" }} />
                                </button>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        {/* additional images */}

                        {message?.additionalImages
                          ? message?.additionalImages.map((ele, i) => (
                              <>
                                <img
                                  ref={imageRef}
                                  className="image"
                                  src={ele.url}
                                  crossOrigin="anonymous"
                                />
                                <div className="ai-buttons">
                                  <button
                                    className="ai-button"
                                    onClick={() =>
                                      handleChatImageDownload(ele.url)
                                    }
                                  >
                                    Download
                                  </button>
                                  <button
                                    aria-label="copy-image"
                                    className="ai-button"
                                    onClick={() => handleCopyImage(ele.url)}
                                  >
                                    Copy
                                  </button>
                                  <button
                                    aria-label="animate-image"
                                    className="ai-button"
                                    onClick={() =>
                                      handleDirectAnimationFromImage(
                                        ele.url,
                                        ele.prompt
                                      )
                                    }
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      gap="8px"
                                    >
                                      Animate
                                      <Tooltip title="Customize">
                                        <Box
                                          sx={{
                                            display: "inline-flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: 24,
                                            height: 24,
                                            borderRadius: "50%",
                                            border: "2px solid currentColor",
                                            padding: "4px",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleAnimationOfImage(
                                              ele.url,
                                              ele.prompt
                                            );
                                          }}
                                        >
                                          <EditIcon />
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </button>
                                  <button
                                    aria-label="reload-image"
                                    className="ai-button"
                                    onClick={() =>
                                      handleImageRegeneration(
                                        ele.url,
                                        ele.prompt
                                      )
                                    }
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      gap="8px"
                                    >
                                      Regenerate
                                      <Tooltip title="Customize">
                                        <Box
                                          sx={{
                                            display: "inline-flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: 24,
                                            height: 24,
                                            borderRadius: "50%",
                                            border: "2px solid currentColor",
                                            padding: "4px",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleImageRegenerationPopup(
                                              ele.url,
                                              ele.prompt
                                            );
                                          }}
                                        >
                                          <EditIcon />
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </button>
                                </div>
                              </>
                            ))
                          : ""}

                        {message?.imageBasedAnimations
                          ? message?.imageBasedAnimations.map((ele, i) => (
                              <div>
                                <div className="video-player">
                                  <VideoPlayer
                                    regenFunction={
                                      handleTextToAnimateRegeneration
                                    }
                                    regenWithEditFuntion={
                                      handleTextToAnimateRegenerationOnEdit
                                    }
                                    url={ele.url}
                                    prompt={ele.prompt}
                                  />
                                </div>
                              </div>
                            ))
                          : ""}

                        {typeof message.audioData === "string" &&
                        message.audioData &&
                        message.type === "text" ? (
                          <div className="audio-player">
                            <AudioPlayer
                              audioData={message.audioData}
                              audioDataForDownload={
                                message.audioDataForDownload
                              }
                              handleAudioDownload={handleAudioDownload}
                            />
                          </div>
                        ) : isVoiceGenerating ? (
                          ""
                        ) : (
                          ""
                        )}

                        {/*other images*/}
                        {/* additional images */}

                        {typeof message.imageUrls === "string" ? (
                          <>
                            <img
                              ref={imageRef}
                              className="image"
                              src={message.imageUrls}
                              crossOrigin="anonymous"
                            />
                            <div className="ai-buttons">
                              <button
                                className="ai-button"
                                onClick={() =>
                                  handleChatImageDownload(message.imageUrls)
                                }
                              >
                                {/* <DownloadIcon /> */}
                                Download
                              </button>
                              <button
                                aria-label="copy-image"
                                className="ai-button"
                                onClick={() =>
                                  handleCopyImage(message.imageUrls)
                                }
                              >
                                Copy
                              </button>
                              <button
                                aria-label="animate-image"
                                className="ai-button"
                                onClick={() =>
                                  handleDirectAnimationFromImage(
                                    message.imageUrls,
                                    message.prompt
                                  )
                                }
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  gap="8px"
                                >
                                  Animate
                                  <Tooltip title="Customize">
                                    <Box
                                      sx={{
                                        display: "inline-flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: 24,
                                        height: 24,
                                        borderRadius: "50%",
                                        border: "2px solid currentColor",
                                        padding: "4px",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleAnimationOfImage(
                                          message.imageUrls,
                                          message.prompt
                                        );
                                      }}
                                    >
                                      <EditIcon />
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </button>
                              <button
                                aria-label="reload-image"
                                className="ai-button"
                                onClick={() =>
                                  handleImageRegeneration(
                                    message.imageUrls,
                                    message.prompt
                                  )
                                }
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  gap="8px"
                                >
                                  Regenerate
                                  <Tooltip title="Customize">
                                    <Box
                                      sx={{
                                        display: "inline-flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: 24,
                                        height: 24,
                                        borderRadius: "50%",
                                        border: "2px solid currentColor",
                                        padding: "4px",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleImageRegenerationPopup(
                                          message.imageUrls,
                                          message.prompt
                                        );
                                      }}
                                    >
                                      <EditIcon />
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {message.imageUrls?.url ? (
                          <>
                            <img
                              ref={imageRef}
                              className="image"
                              src={message.imageUrls.url}
                              crossOrigin="anonymous"
                            />
                            <div className="ai-buttons">
                              <button
                                className="ai-button"
                                onClick={() =>
                                  handleChatImageDownload(message.imageUrls.url)
                                }
                              >
                                {/* <DownloadIcon /> */}
                                Download
                              </button>
                              <button
                                aria-label="copy-image"
                                className="ai-button"
                                onClick={() =>
                                  handleCopyImage(message.imageUrls.url)
                                }
                              >
                                Copy
                              </button>
                              <button
                                aria-label="animate-image"
                                className="ai-button"
                                onClick={() =>
                                  handleDirectAnimationFromImage(
                                    message.imageUrls.url,
                                    message.imageUrls.prompt
                                  )
                                }
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  gap="8px"
                                >
                                  Animate
                                  <Tooltip title="Customize">
                                    <Box
                                      sx={{
                                        display: "inline-flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: 24,
                                        height: 24,
                                        borderRadius: "50%",
                                        border: "2px solid currentColor",
                                        padding: "4px",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleAnimationOfImage(
                                          message.imageUrls.url,
                                          message.imageUrls.prompt
                                        );
                                      }}
                                    >
                                      <EditIcon />
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </button>
                              <button
                                aria-label="reload-image"
                                className="ai-button"
                                onClick={() =>
                                  handleImageRegeneration(
                                    message.imageUrls.url,
                                    message.imageUrls.prompt
                                  )
                                }
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  gap="8px"
                                >
                                  Regenerate
                                  <Tooltip title="Customize">
                                    <Box
                                      sx={{
                                        display: "inline-flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: 24,
                                        height: 24,
                                        borderRadius: "50%",
                                        border: "2px solid currentColor",
                                        padding: "4px",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleImageRegenerationPopup(
                                          message.imageUrls.url,
                                          message.imageUrls.prompt
                                        );
                                      }}
                                    >
                                      <EditIcon />
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        {message.videoUrls ? (
                          <div>
                            <div className="video-player">
                              <VideoPlayer
                                regenFunction={handleTextToAnimateRegeneration}
                                regenWithEditFuntion={
                                  handleTextToAnimateRegenerationOnEdit
                                }
                                url={message.videoUrls}
                                prompt={message.prompt}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {/*video */}
                        {typeof message.animeUrl?.url === "string" ? (
                          <div>
                            <div className="video-player">
                              <VideoPlayer
                                regenFunction={handleTextToAnimateRegeneration}
                                regenWithEditFuntion={
                                  handleTextToAnimateRegenerationOnEdit
                                }
                                url={message.animeUrl?.url}
                                prompt={message.animeUrl?.prompt}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {/*audio*/}
                        {typeof message.audioData === "string" &&
                        message.audioData !== undefined &&
                        (message.type === "audio" ||
                          message.type === "audio64") ? (
                          <div className="audio-player">
                            <AudioPlayer
                              audioData={message.audioData}
                              audioDataForDownload={
                                message.audioDataForDownload
                              }
                              handleAudioDownload={handleAudioDownload}
                            />
                          </div>
                        ) : isVoiceGenerating ? (
                          ""
                        ) : (
                          ""
                        )}
                        <div>
                          {!message.isHuman &&
                          index === messages.length - 1 &&
                          message.type === "text" ? (
                            <div className="chat-options" ref={menuRef}>
                              <OptionsMenu
                                messageId={message.messageId}
                                handleImageGeneration={
                                  setImageGeneratorModalOpen
                                }
                                handleAdditionalImages={handleaddonimagegen}
                                handleImageAnimations={handleimageanimations}
                                handleVoiceGeneration={handleVoiceGeneration}
                                handleGPTResponseCopy={handleGPTResponseCopy}
                                handleGPTResponseDownload={
                                  handleGPTResponseDownload
                                }
                                handleAnimeGeneration={getAnime}
                                index={index}
                                imageModuleFlag={
                                  message.additionalImages &&
                                  message.additionalImages.length > 0
                                    ? true
                                    : false
                                }
                                addonImageModuleFlag={
                                  message.additionalImages &&
                                  message.additionalImages.length > 0
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div>
                          {message.isHuman ? (
                            <div></div>
                          ) : (
                            <div>
                              <Divider
                                sx={{
                                  marginY: 2,
                                  marginRight: 3,
                                  marginLeft: 2,
                                  borderColor: "white",
                                  opacity: 0.5,
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div ref={endOfMessagesRef} />
                      </div>
                    ))}

                    {/*Loader*/}
                    {docImageLoader ||
                    docVideoLoader ||
                    docVoiceLoader ||
                    isAnimeLoading ||
                    selectedImageLoader ||
                    imageLoader ||
                    isVoiceGenerating ||
                    fetchingMusicInBackground ||
                    fetchSoundEffectsInBackground ? (
                      <>
                        <Loader className="loader-container" />
                        <LinearProgressWithLabel />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="chatbox-controls">
                    <Grid
                      container
                      spacing={{ xs: 1, sm: 1, md: 3 }}
                      alignItems="center"
                      justifyContent="center"
                      columns={16}
                    >
                      <HorizontalScrollIcons
                        handlePromptModal={handlePromptModal}
                        handleVoiceModal={handleVoiceModal}
                        handleAIMusic={handleMusicDrawertoggle}
                        handleImageSettingsModal={handleImageSettingsModal}
                        handleVideoSettingsModal={handleVideoSettingsModal}
                        chatId={currentChatId}
                      />
                    </Grid>
                    <Grid
                      container
                      spacing={{ xs: 1, sm: 1, md: 3 }}
                      alignItems="center"
                      justifyContent="center"
                      columns={16}
                    >
                      <Grid item xs={12}>
                        <TextField
                          placeholder="Type your message..."
                          style={{
                            width: "100%",
                            background: "transparent",
                            color: "white",
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="listen"
                                  onClick={handleListeningFromMic}
                                  sx={{
                                    color: "white",
                                  }}
                                >
                                  {!microphoneIsOn ? (
                                    <MicIcon style={{ fontSize: "2rem" }} />
                                  ) : (
                                    <MicNoneOutlinedIcon
                                      style={{ fontSize: "2rem" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            style: { color: "white" },
                            classes: {
                              notchedOutline: { borderColor: "white" },
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white",
                                borderRadius: "15px",
                              },
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              fontSize: "16px",
                            },
                          }}
                          onChange={(e) => {
                            setInputValue(e.target.value);
                            setNewMessage(e.target.value);
                          }}
                          onKeyDown={handleKeyDown}
                          value={listening ? transcript : inputValue}
                          multiline
                          maxRows={5}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <SettingsMenu
                          handleMultilingual={handleMultilingual}
                          isMultilingual={isMultilingual}
                          handleInternetQuery={handleInternetQuery}
                          internetQuery={internetQuery}
                          onUploadToGpt={onUploadToGpt}
                          documentButtons={documentButtons}
                          handlePromptModal={handlePromptModal}
                          promptModalOpen={promptModalOpen}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        {inQuery ||
                        promptLoading ||
                        isAnimeLoading ||
                        isVoiceGenerating ||
                        imageLoader ? (
                          <SpinnerLoader />
                        ) : (
                          <button
                            style={{
                              padding: "5px",
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                              color: "white",
                            }}
                            onClick={() =>
                              onQuery(
                                null,
                                null,
                                (transcript && transcript.length > 0)
                                  ? true
                                  : false
                              )
                            }
                          >
                            <SendIcon
                              style={{
                                fontSize: "28px",
                                alignSelf: "center",
                              }}
                            />
                          </button>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              )}
            </div>
          </Chat>
          <div>
            {/* ai music  */}
            <CustomModal
              open={musicDrawer}
              handleClose={handleMusicDrawertoggle}
              title="AI Music"
            >
              <MusicControllerDrawer
                inputValue={inputValueAIMusic}
                onChangeInput={onChangeInputAIMusic}
                onVolumeChange={handleVolumeChange}
                onVariationChange={handleVariationChange}
                onGenreChange={handleGenreChange}
                onMoodChange={handleMoodChange}
                onArtistChange={handleArtistChange}
                onInstrumentChange={handleInstrumentChange}
                getMusic={getMusic}
                getSoundEffect={getSoundEffect}
                getSoundEffectFromVideo={getSoundEffectFromVideo}
                onSoundEffectDurationChange={handleEffectDurationChange}
              />
            </CustomModal>

            {/* menu  */}
            <SideDrawer
              isOpen={sideDrawerOpen}
              onOpen={toggleSideDrawer}
              onClose={toggleSideDrawer}
            />
            {/* ai writer */}
            <CustomModal
              open={promptModalOpen}
              handleClose={handlePromptModal}
              title="AI Writer"
            >
              <PersonaHandler
                onPersonaChange={handlePersonaChange}
                onGenreChange={handlePromptGenreChange}
                onWritingsChange={handleWritingStylesChange}
                onPersonalityChange={handlePersonalityChange}
                onToneChange={handleToneChange}
                onCategoryChange={handleCategoryChange}
                currentPersona={selectedPersona}
                setNewMessage={setPromptForEngine}
                selectedCategory={selectedCategory}
                onSubmit={directQuery}
              />
            </CustomModal>

            {/* ai voice  */}
            <CustomModal
              open={voiceModalOpen}
              handleClose={handleVoiceModal}
              title="AI Voice"
            >
              <VoiceSettingsDrawer
                handleClick={handleClick}
                voice={voice}
                voiceDisplay={voiceDisplay}
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                mappedVoices={mappedVoices}
                inputValue={inputValueAIVoice}
                onChangeInput={onChangeInputAIVoice}
                onSubmit={handleSubmitForAIVoice}
              />
            </CustomModal>

            {/* ai image  */}
            <CustomModal
              open={imageSettingModalOpen}
              handleClose={handleImageSettingsModalClose}
              title="AI Image"
            >
              
              <MultiImageFileUploader setFiles={setImages} images={images} uploadSource={uploadSource}/>
              
              <ImageSettingsDrawer
                images={images}
                onAspectRatioChange={handleImageAspectRatio}
                onImageArtStyleChange={handleImageArtStyle}
                onColoSchemeChange={handleColorScheme}
                onImageToneChange={handleImageTone}
                onImageSeedChange={handleSeedValue}
                onPresetStyleChange={handlePresetStyle}
                onCharacterRetention={handleCharacterRetention}
                characterRetention={characterRetention}
                upscale={upscale}
                outpaint={outpaint}
                outpaintValuesProp={outpaintValues}
                removeBackground={removeBackground}
                onUpscaleChange={handleUpscaleChange}
                onOutpaintChange={handleOutpaintChange}
                onOutpaintValuesChange={handleOutpaintValuesChange}
                onRemoveBackgroundChange={handleRemoveBackgroundChange}
                onChangePromptText={handleDocIllustrationText}
                promptText={documentIllustrationText}
                onModelSelection={handleImageModelSelection}
                onTextStyleChange={handleTextStyleChange}
                onSubmit={handleSubmitForImageModal}
              />
            </CustomModal>

            {/* text to image  */}
            <CustomModal
              open={imageGeneratorModalOpen}
              handleClose={() =>
                setImageGeneratorModalOpen((prevState) => !prevState)
              }
              title="Image Generator"
            >
              <ImageFromTextDrawer
                handleSlowerImageGen={handleimagegen}
                handleFasterImageGen={handleFastIllustration}
              />
            </CustomModal>

            {/* ai video  */}

            <CustomModal
              open={videoSettingModalOpen}
              handleClose={handleVideoSettingsModalClose}
              title="AI Video"
            >
              <MultiImageFileUploader
                setFiles={setVideos}
                images={videos}
                uploadSource={uploadSource}
              />
              <VideoSettingsDrawer
                onAspectRatioChange={handleVideoAspectRatio}
                onMotionStrengthChange={handleMotionStrengthChange}
                onAnimationStyleChange={handleAnimationStyle}
                onColorLightingChange={handleVideoColorAndLighting}
                onAnimationMoodChange={handleAnimationMoods}
                onDirectVideoPromptChange={handleAnimationPrompt}
                promptText={animationPrompt}
                imagesUploaded={videos}
                onSubmit={handleSubmitForVideoModel}
              />
            </CustomModal>

            <CustomModal
              open={showDocIllustratorModal}
              handleClose={handleDocIllustrationClick}
              title="Paste Text for Illustration"
            >
              <Stack direction="column" spacing={3}>
                <TextField
                  placeholder="Paste your selection..."
                  InputLabelProps={{
                    style: { color: "white", fontSize: "14px" },
                  }}
                  InputProps={{
                    style: {
                      color: "white",
                      fontSize: "16px",
                    },
                  }}
                  sx={{
                    "& label.Mui-focused": {
                      color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      paddingTop: "16px",
                      paddingBottom: "16px",
                    },
                    "& .MuiInputLabel-outlined": {
                      transform: "translate(16px, 16px) scale(1)",
                    },
                    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                      transform: "translate(16px, -6px) scale(0.75)",
                    },
                  }}
                  onChange={handleDocIllustrationText}
                  multiline
                  maxRows={6}
                />
                <Button
                  id="basic-button"
                  variant="outlined"
                  aria-haspopup="true"
                  size="large"
                  sx={{
                    border: "1px solid white",
                    backgroundColor: "transparent",
                    color: "white",
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "transparent",
                      borderColor: "white",
                      color: "white",
                      opacity: 0.9,
                    },
                  }}
                  onClick={handleDocIllustration}
                >
                  Illustrate
                </Button>
              </Stack>
            </CustomModal>
            <CustomModal
              open={docNarrationModal}
              handleClose={handleDocNarrationClick}
              title="Paste Text for Narration"
            >
              <Stack direction="column" spacing={3}>
                <TextField
                  placeholder="5000 characters only..."
                  InputLabelProps={{
                    style: { color: "white", fontSize: "14px" },
                  }}
                  InputProps={{
                    style: {
                      color: "white",
                      fontSize: "16px",
                    },
                  }}
                  sx={{
                    "& label.Mui-focused": {
                      color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      paddingTop: "16px",
                      paddingBottom: "16px",
                    },
                    "& .MuiInputLabel-outlined": {
                      transform: "translate(16px, 16px) scale(1)",
                    },
                    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                      transform: "translate(16px, -6px) scale(0.75)",
                    },
                  }}
                  onChange={handleDocNarrationText}
                  // value={documentNarrationText}
                  multiline
                  maxRows={6}
                  inputProps={{ maxLength: 5000 }}
                />
                <span>
                  {startIndex}/{endIndex}
                </span>
                {startIndex >= 5000 ? (
                  <>
                    <span style={{ color: "red" }}>
                      Please paste 5000 characters at a time
                    </span>
                  </>
                ) : (
                  ""
                )}
                <Button
                  id="basic-button"
                  variant="outlined"
                  aria-haspopup="true"
                  size="large"
                  onClick={handleClick}
                  sx={{
                    border: "1px solid white",
                    backgroundColor: "transparent",
                    color: "white",
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#446597",
                      borderColor: "white",
                      opacity: 0.9,
                    },
                  }}
                >
                  {voice ? `Voice : ${voiceDisplay}` : "Voices"}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "voice-button",
                  }}
                  PaperProps={{
                    style: {
                      backgroundColor: "trasparent",
                      color: "#011636",
                    },
                  }}
                >
                  {mappedVoices.map((voice, index) => (
                    <MenuItem
                      onClick={handleClose}
                      key={index}
                      id={voice.voice}
                      style={{ color: "#011636", fontSize: "16px" }}
                    >
                      {voice.display}
                    </MenuItem>
                  ))}
                </Menu>
                <Button
                  id="basic-button"
                  variant="outlined"
                  aria-haspopup="true"
                  size="large"
                  sx={{
                    border: "1px solid white",
                    backgroundColor: "transparent",
                    color: "white",
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "transparent",
                      borderColor: "white",
                      color: "white",
                      opacity: 0.9,
                    },
                  }}
                  onClick={handleDocNarrate}
                >
                  Narrate
                </Button>
              </Stack>
            </CustomModal>
            {promptShow ? (
              <CustomModal open={promptShow} handleClose={handlePromptClick}>
                <div ref={divRef}>{selectedPrompt}</div>
                <span
                  style={{
                    color: "#011636",
                    fontSize: "36px",
                    padding: "10px 0",
                    cursor: "pointer",
                  }}
                  onClick={handleCopyPrompt}
                >
                  <ContentCopyIcon style={{color: "white", marginTop: "3px"}} />
                </span>
              </CustomModal>
            ) : (
              ""
            )}

            {snackbarOpen ? (
              <CustomSnackbar
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                snackbarSeverity={snackbarSeverity}
                snackbarContent={snackbarContent}
              />
            ) : (
              ""
            )}
          </div>
        </Grid>
      </div>
    </div>
  );
};
export default Main;