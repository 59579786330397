import { useContext } from "react";
import FileUploader from "components/FileUploader/FileUploader";
import { FileProvider } from "context/File";
import BackgroundAnimation from "components/Background/BackgroundAnimations";
import { Box, Button, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AuthContext } from "context/User";
import { generateUniqueNumber } from "utils/helper";

const GlassmorphicBox = styled(Box)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.1)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  backdropFilter: "blur(5px)",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  borderRadius: "5px",
  width: "70vw",
  color: "white",
  cursor: "pointer",
  transition: "background 0.3s ease, border-color 0.3s ease",
  padding: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0)",
  color: "white",
  border: "2px solid rgba(255, 255, 255, 0.3)",
  borderRadius: "25px",
  padding: "5px 10px",
  width: "150px",
  fontSize: "16px", // Adjust the font size as needed
  transition: "background 0.3s ease, border-color 0.3s ease",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.2)",
    borderColor: "rgba(255, 255, 255, 0.5)",
  },
}));

const Compose = () => {
  const { user } = useContext(AuthContext);
  const chatId = user.userId + "t" + generateUniqueNumber();

  const isSmallScreen = useMediaQuery("(max-width:1200px)");
  const isIpad = useMediaQuery(
    "(min-width: 1024px) and (orientation: portrait)"
  );
  return (
    <BackgroundAnimation>
      <div style={{ overflowY: "auto", maxHeight: "100vh" }}>
        <Box
          sx={{ position: "fixed", top: "20px", left: "20px", zIndex: 1200 }}
        >
          <Link to="/dashboard">
            <IconButton aria-label="back">
              <ArrowBackIcon
                style={{ width: "2em", height: "2em", color: "white" }}
              />
            </IconButton>
          </Link>
        </Box>
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <img src="Logo.png" alt="Logo" className="logo" />
        </Box>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <FileProvider>
            {isSmallScreen ? (
              <GlassmorphicBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "90vw",
                  // maxWidth: "800px",
                  m: "25px auto",
                  marginTop: "140px",
                  p: 4,
                  borderRadius: "40px",
                  minHeight: isIpad ? "10vh" : "20vh",
                  gap: 2,
                }}
              >
                <h1
                  style={{
                    color: "white",
                    fontFamily: "Inter, sans-serif",
                    margin: 0,
                  }}
                >
                  <b>Compose</b>
                </h1>
                <Box sx={{ mt: 2 }}>
                  <FileUploader task={"COMPOSE"} />
                </Box>
              </GlassmorphicBox>
            ) : (
              <GlassmorphicBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  m: "25px auto",
                  marginTop: "150px",
                  borderRadius: "40px",
                  minHeight: "60vh",
                }}
              >
                <h1 style={{ color: "white", fontFamily: "Inter, sans-serif" }}>
                  <b>Compose</b>
                </h1>
                <FileUploader task={"COMPOSE"} />
              </GlassmorphicBox>
            )}
          </FileProvider>
          <Box sx={{ mt: 5, textAlign: "center" }}>
            <Link to={`/c/${chatId}`}>
              <StyledButton variant="contained">Skip</StyledButton>
            </Link>
          </Box>
        </div>
      </div>
    </BackgroundAnimation>
  );
};

export default Compose;
