import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFileContext } from "hooks/File";
import { useMediaQuery } from "@mui/material";

const glassmorphicStyle = {
  background: "rgba(255, 255, 255, 0.1)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  // backdropFilter: 'blur(10px)',
  borderRadius: "10px",
  background: "rgba(255, 255, 255, 0.1)",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  // backdropFilter: "blur(10px)",
  textAlign: "center",
  color: "white",
};

const buttonHoverStyle = {
  background: "rgba(255, 255, 255, 0.2)",
  borderColor: "rgba(255, 255, 255, 0.5)",
};

const FileUploader = (props) => {
  const [dragging, setDragging] = useState(false);
  const fileHandler = useFileContext();
  const [file, setFile] = useState(null);
  let navigate = useNavigate();
  const isSmallScreen = useMediaQuery(
    "(max-width: 768px) and (orientation: portrait)"
  );
  const isIpad = useMediaQuery(
    "(min-width: 1024px) and (orientation: portrait)"
  );
  const isShortPhone = useMediaQuery(
    "(max-width: 768px) and (max-height: 730px)"
  );
  const isLandscape = useMediaQuery(
    "(max-width: 1000px) and (orientation: landscape)"
  );

  const handleHover = (isHovering) => {
    const event = new CustomEvent("animateGradient", { detail: isHovering });
    window.dispatchEvent(event);
  };

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  }, []);

  const handleDragOver = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!dragging) {
        setDragging(true);
      }
    },
    [dragging]
  );

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      const docxFile = Array.from(files).find(
        (file) =>
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      );
      if (docxFile) {
        setFile(docxFile);
      } else {
        alert("Only .docx files are allowed");
      }
    }
  }, []);

  const handleFileSelect = useCallback((e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const docxFile = Array.from(files).find(
        (file) =>
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      );
      if (docxFile) {
        setFile(docxFile);
      } else {
        alert("Only .docx files are allowed");
      }
    }
  }, []);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post(
          process.env.REACT_APP_SF_CONVERT_TO_SFDT,
          formData
        );

        // console.log(response.data);
        fileHandler.handleFileUpload(response.data);
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    } finally {
      // console.log("Redirecting to dashboard");
      navigate("/c");
    }
  };

  return (
    <>
      {isSmallScreen ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "80vw",
            height: "40vh",
            margin: "auto",
            borderRadius: "40px",
            background: "rgba(255, 255, 255, 0.1)",
            textAlign: "center",
          }}
        >
          <div
            className={`dropzone ${dragging ? "dragging" : ""}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
            style={{
              border: dragging ? "2px dashed #333" : "2px dashed transparent",
              borderRadius: "10px",
              padding: "1px",
              width: "70vw",
              height: "20vh", // Correct the typo here ("2y0vh" -> "20vh")
              margin: "0 auto",
              transition: "border-color 0.3s ease",
            }}
          >
            {file ? (
              <div className="ddtext">File selected: {file.name}</div>
            ) : (
              <div className="ddtext">
                Drag and drop a .docx file here (single file)
              </div>
            )}
            <div className="ddtext-2">OR</div>

            <form onSubmit={handleSubmit}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <input
                  type="file"
                  id="file-upload"
                  accept=".docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  className="ai-button"
                  onChange={handleFileSelect}
                  style={{
                    ...glassmorphicStyle,
                    width: "70vw",
                    height: "6vh",
                    textAlign: "center",
                    marginBottom: "2vh",
                  }}
                  required={file === null}
                />
              </div>

              <button
                type="submit"
                style={{
                  ...glassmorphicStyle,
                  padding: "5px 15px",
                  ...buttonHoverStyle,
                  width: "50%",
                  textAlign: "center",
                  borderRadius: "20px",
                  color: "white",
                  marginTop: "1vh",
                }}
              >
                Go To Editor
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: isLandscape ? "80vw" : "60vw",
            height: isLandscape ? "60vh" : isIpad ? "20vh" : "38vh",
            marginTop: "1vh",
            borderRadius: "40px",
            background: "rgba(255, 255, 255, 0.1)",
            textAlign: "center",
          }}
        >
          <div
            className={`dropzone ${dragging ? "dragging" : ""}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
            style={{
              border: dragging ? "2px dashed #333" : "2px dashed transparent",
              borderRadius: "10px",
              padding: "1px",
              margin: "0 auto",
              transition: "border-color 0.3s ease",
              position: "relative",
              overflow: "hidden",
            }}
          >
            {file ? (
              <div className="ddtext">File selected: {file.name}</div>
            ) : (
              <div className="ddtext">
                Drag and drop a .docx file here (single file)
              </div>
            )}
            <div className="ddtext-2">OR</div>
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "1vh",
                  marginBottom: isLandscape ? "2vh" : "3vh",
                }}
              >
                <input
                  type="file"
                  id="file-upload"
                  accept=".docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onChange={handleFileSelect}
                  required={file === null}
                  style={{
                    ...glassmorphicStyle,
                    width: isLandscape ? "45vw" : isIpad ? "80%" : "100%", // Responsiveness for file input
                    height: isLandscape ? "10vh" : isIpad ? "3vh" : "6vh",
                    textAlign: "center",
                    padding: "10px",
                  }}
                />
              </div>
              <button
                type="submit"
                style={{
                  ...glassmorphicStyle,
                  padding: "10px 20px",
                  ...buttonHoverStyle,
                  width: "50%",
                  textAlign: "center",
                  borderRadius: "20px",
                  color: "white",
                }}
              >
                Go To Editor
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default FileUploader;
