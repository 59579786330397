import React, {
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fabric } from "fabric";
import {
  Box,
  IconButton,
  Slider,
  Grid,
  Container,
  Tooltip,
  Typography,
  Paper,
  Button,
} from "@mui/material";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import UndoIcon from "@mui/icons-material/Undo"; // Import Undo icon
import RedoIcon from "@mui/icons-material/Redo";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BrushIcon from "assets/images/picasso_ai/Paint_Brush.png";
import FormatPaintIcon from "assets/images/picasso_ai/Paint_Roller.png";
import eraserIcon from "assets/images/picasso_ai/Eraser.png";
import AIImageIcon from "assets/images/picasso_ai/Image_AI.png";
import AIVideoIcon from "assets/images/picasso_ai/Video_AI.png"; // Import AI Video icon
import CustomSnackbar from "components/Common/Snackbar/Snackbar";
import Loader from "components/Common/Loaders/StarLoader";
import Chat from "components/ResultBox/ResultModal";
import { AuthContext } from "context/User";
import { generateUniqueNumber } from "utils/helper";
import { useMediaQuery } from "@mui/material";

const getCanvasDimensions = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  return { width: width, height: height + 200 };
};

const generateEraserCursor = (size) => {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${
    size * 2
  }" height="${
    size * 2
  }"><circle cx="${size}" cy="${size}" r="${size}" stroke="black" stroke-width="2" fill="none" /></svg>`;
  return `url('data:image/svg+xml;utf8,${encodeURIComponent(
    svg
  )}') ${size} ${size}, auto`;
};

const PaintApp = ({ background }) => {
  const canvasRef = useRef(null);
  const lastObjectRef = useRef(null);
  const [canvas, setCanvas] = useState(null);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [canvasDimensions, setCanvasDimensions] = useState(getCanvasDimensions);
  const [color, setColor] = useState("#111111");
  const [strokeWidth, setStrokeWidth] = useState(1);
  const [eraserWidth, setEraserWidth] = useState(10);
  const [isEraser, setIsEraser] = useState(false);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [fillMode, setFillMode] = useState(false);
  const [drawingMode, setDrawingMode] = useState(true);
  const [predictedImageData, setPredictedImageData] = useState([]);
  const [predictingImage, setPredictingImage] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarContent, setSnackbarContent] = useState("");
  const [predictedImageBox, setPredictedImageBox] = useState(false);
  const { user, selectedPersonaFromTrain, selectedDomain } =
    useContext(AuthContext);

  const [openi, setOpen] = useState(false);

  const { chatId } = useParams();

  const [currentUserId, setCurrentUserId] = useState(user.userId);

  const [currentChatId, setCurrentChatId] = useState(
    chatId ? chatId : currentUserId + "t" + generateUniqueNumber()
  );

  const navigate = useNavigate();

  const [currentImageIndex, setCurrentImageIndex] = useState(-1);

  const [expanded, setExpanded] = useState(false);

  const isSmallScreen = useMediaQuery(
    "(max-width: 768px) and (orientation: portrait)"
  );
  const isLandscape = useMediaQuery(
    "(max-width: 1000px) and (orientation: landscape)"
  );

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleButtonClick = async (source, type, index) => {
    if (source == "canvas") {
      try {
        const canvas = document.querySelector("canvas");
        if (!canvas) {
          console.error("Canvas element not found");
          return;
        }

        const dataURL = canvas.toDataURL("image/png");
        const blob = await (await fetch(dataURL)).blob(); // Convert dataURL to a blob

        const urlforcanvas = URL.createObjectURL(blob);
        localStorage.setItem("CanvasImage", urlforcanvas);
      } catch (error) {
        console.error("Error exporting canvas as image:", error);
      }
    } else {
      localStorage.setItem(
        "CanvasImage",
        predictedImageData[index]
      );
    }
    navigate(`/c?source=${source}&type=${type}`); // Navigate to the main page with source and type as query parameters
  };

  useEffect(() => {
    const newCanvas = new fabric.Canvas(canvasRef.current, {
      isDrawingMode: true,
      backgroundColor: "white",
    });

    canvasRef.current = newCanvas;

    newCanvas.on("object:added", (options) => {
      lastObjectRef.current = options.target;
    });
    setCanvas(newCanvas);

    return () => {
      newCanvas.dispose();
    };
  }, []);

  const saveCanvasState = (currentCanvas) => {
    const currentState = JSON.stringify(currentCanvas.toDatalessJSON());
    setUndoStack((prev) => [...prev, currentState]);
    setRedoStack([]);
  };

  const handleUndo = () => {
    if (undoStack.length > 1) {
      try {
        const currentState = JSON.stringify(canvas.toDatalessJSON());
        const prevState = undoStack[undoStack.length - 2]; // Get the state before the last
        canvas.loadFromJSON(prevState, () => {
          canvas.renderAll();
          setUndoStack((prev) => prev.slice(0, -2)); // Remove the last two states (including the one just added)
          setRedoStack((prev) => [...prev, currentState]);
        });
      } catch (error) {
        console.error("Error undoing action:", error);
      }
    }
  };

  const handleRedo = () => {
    if (redoStack.length > 0) {
      try {
        const nextState = redoStack.pop();
        canvas.loadFromJSON(nextState, () => {
          canvas.renderAll();
          setUndoStack((prev) => [...prev, nextState]);
          setRedoStack([...redoStack]);
        });
      } catch (error) {
        console.error("Error redoing action:", error);
      }
    }
  };

  useEffect(() => {
    if (canvas) {
      canvas.on("object:added", () => {
        saveCanvasState(canvas);
      });
      canvas.on("object:modified", () => {
        saveCanvasState(canvas);
      });
      canvas.on("object:removed", () => {
        saveCanvasState(canvas);
      });
    }
  }, [canvas]);

  useEffect(() => {
    if (canvas) {
      canvas.isDrawingMode = drawingMode;

      canvas.selection = isSelectionMode;
      canvas.forEachObject((obj) => {
        obj.selectable = isSelectionMode;
      });

      if (isEraser) {
        canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
        canvas.freeDrawingBrush.color = "white";
        canvas.freeDrawingBrush.width = eraserWidth;
        canvas.freeDrawingBrush.globalCompositeOperation = "destination-out";
        canvas.defaultCursor = "default";
        canvas.freeDrawingCursor = generateEraserCursor(eraserWidth);
        canvas.defaultCursor = "default";
        canvas.freeDrawingCursor = generateEraserCursor(eraserWidth);
      } else {
        canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
        canvas.freeDrawingBrush.color = color;
        canvas.freeDrawingBrush.width = strokeWidth;
        canvas.freeDrawingBrush.globalCompositeOperation = "source-over";
        canvas.defaultCursor = "default";
        canvas.freeDrawingCursor = "crosshair";
        canvas.defaultCursor = "default";
        canvas.freeDrawingCursor = "crosshair";
      }

      canvas.renderAll();
    }
  }, [
    canvas,
    isEraser,
    isSelectionMode,
    fillMode,
    color,
    strokeWidth,
    eraserWidth,
  ]);

  useEffect(() => {
    const updateLastObjectRef = (event) => {
      if (event && event.target) {
        lastObjectRef.current = event.target;
      }
    };

    if (canvas) {
      canvas.on("object:selected", updateLastObjectRef);
      canvas.on("selection:updated", updateLastObjectRef);
      canvas.on("selection:cleared", () => {
        lastObjectRef.current = null;
      });
    }

    return () => {
      if (canvas) {
        canvas.off("object:selected", updateLastObjectRef);
        canvas.off("selection:updated", updateLastObjectRef);
        canvas.off("selection:cleared");
      }
    };
  }, [canvas]);

  const handleFillColor = (options) => {
    if (!fillMode || !canvas) return;
    const pointer = canvas.getPointer(options.e);

    const clickedObject = canvas
      .getObjects()
      .find((obj) => obj.containsPoint(pointer));

    if (clickedObject) {
      if (clickedObject.type === "group") {
        clickedObject.getObjects().forEach((childObj) => {
          childObj.set("fill", color);
          childObj.set("fill", color);
        });
      } else {
        clickedObject.set("fill", color);
        clickedObject.set("fill", color);
      }

      clickedObject.setCoords();

      clickedObject.setCoords();
      canvas.renderAll();
    }
  };

  const handleMouseUp = async () => {
    if (!drawingMode) {
      console.error("Not in drawing mode");
      return;
    }

    if (!canvasRef.current) {
      console.error("Canvas not initialized");
      return;
    }

    const fabricCanvas = canvasRef.current;
    const canvasEl = fabricCanvas.lowerCanvasEl || fabricCanvas;

    if (!canvasEl.toBlob) {
      console.error("Unable to convert canvas to Blob");
      return;
    }

    // Ensure that the canvas rendering is completed before capturing it.
    setTimeout(() => {
      canvasEl.toBlob(async (blob) => {
        if (!blob) {
          console.error("Failed to create Blob from canvas");
          return;
        }

        try {
          setPredictingImage(true);
          const formData = new FormData();
          formData.append("file", blob, "canvas.png");

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}predict-image`,
            {
              method: "POST",
              body: formData,
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const result = await response.json();

          if (
            result &&
            result.output &&
            result.output.image &&
            result.output.image.url
          ) {
            const imageURL = result.output.image.url;
            localStorage.setItem("CanvasImage", imageURL);
            setPredictedImageBox(true);
            setPredictedImageData((prevState) => [
              imageURL,
              ...prevState.filter(item => item !== imageURL)
          ]);
            setCurrentImageIndex((prevState) => prevState + 1);
            setPredictingImage(false);
          } else {
            throw new Error("Invalid response format");
            setPredictingImage(false);
          }
        } catch (error) {
          console.error("Error in fetch operation:", error);
          setPredictingImage(false);
        }
      }, "image/png");
    }, 100); // Adjust the timeout as necessary to ensure the canvas is fully rendered
  };

  useEffect(() => {
    if (canvas) {
      // Handle drawing mode and selection
      canvas.isDrawingMode = drawingMode;
      canvas.selection = isSelectionMode;
      canvas.forEachObject(
        (o) => (o.selectable = !fillMode || isSelectionMode)
      );

      // console.log("fill mode is", fillMode);

      // Handle fill mode
      if (fillMode) {
        canvas.on("mouse:down", handleFillColor);
      } else {
        canvas.off("mouse:down", handleFillColor);
      }

      // Handle selection mode events
      if (isSelectionMode) {
        canvas.on("selection:created", (event) => {
          lastObjectRef.current = event.target;
        });

        canvas.on("selection:updated", (event) => {
          lastObjectRef.current = event.target;
        });
      } else {
        canvas.off("selection:created");
        canvas.off("selection:updated");
      }

      canvas.on("mouse:up", handleMouseUp);
      canvas.on("touch:dragend", handleMouseUp);
      canvas.on("touch:mouse:up", handleMouseUp);

      canvas.renderAll();
    }

    return () => {
      if (canvas) {
        canvas.off("mouse:down", handleFillColor);
        canvas.off("mouse:up", handleMouseUp);
        canvas.off("touch:dragend", handleMouseUp);
        canvas.off("touch:mouse:up", handleMouseUp);
        canvas.off("selection:created");
        canvas.off("selection:updated");
      }
    };
  }, [canvas, fillMode, isSelectionMode, color]);

  const toggleFillMode = () => {
    setFillMode(true); // Ensure fillMode remains true
    setIsEraser(false);
    setDrawingMode(false);
    setIsSelectionMode(false);
  };

  const toggleDrawingMode = () => {
    setDrawingMode(true);
    setFillMode(false);
    setIsEraser(false);
    setIsSelectionMode(false);
  };

  const toggleEraserMode = () => {
    setIsEraser(true);
    setDrawingMode(true);
    setFillMode(false);
    setIsSelectionMode(false);
  };

  const toggleSelectionMode = () => {
    // console.log("handle", predictedImageData.length);
    setIsSelectionMode(true);
    setIsSelectionMode(true);
    setIsEraser(false);
    setFillMode(false);
    setDrawingMode(false);
    setDrawingMode(false);

    if (canvas) {
      canvas.isDrawingMode = false;
      canvas.selection = true;
      canvas.forEachObject((o) => (o.selectable = true));
      canvas.selection = true;
      canvas.forEachObject((o) => (o.selectable = true));
    }
  };

  const handleColorChange = (e) => {
    setColor(e.target.value);
  };

  const handleStrokeWidthChange = (e) => {
    setStrokeWidth(parseInt(e.target.value, 10));
  };

  const handleEraserWidthChange = (e) => {
    setEraserWidth(parseInt(e.target.value, 10));
  };

  // const drawFromImage = () => {
  //   const fabricCanvas = canvasRef.current;
  //   const svgUrl = URL.createObjectURL(predictedImageblob);

  //   if (!fabricCanvas || !lastObjectRef.current) {
  //     console.error("Canvas not initialized or no last object available");
  //     return;
  //   }

  //   console.log("Attempting to load SVG from blob URL:", svgUrl);

  //   const lastObject = lastObjectRef.current;
  //   fabricCanvas.remove(lastObject);

  //   fabric.loadSVGFromURL(
  //     svgUrl,
  //     (objects, options) => {
  //       if (!objects || objects.length === 0) {
  //         console.error(
  //           "No objects loaded from SVG. Check if the SVG data is correct and the blob URL is valid."
  //         );
  //         return;
  //       }

  //       console.log(`Loaded ${objects.length} SVG objects from blob URL`);

  //       // Create a group from the SVG elements
  //       const svgGroup = new fabric.Group(objects, {
  //         left: lastObject.left,
  //         top: lastObject.top,
  //         scaleX: 0.5,
  //         scaleY: 0.5,
  //       });

  //       fabricCanvas.add(svgGroup);
  //       fabricCanvas.renderAll();
  //       console.log("SVG added to canvas from blob URL");
  //     },
  //     (error) => {
  //       console.error("Error loading SVG from blob URL:", error);
  //     }
  //   );
  // };

  // PNG scenario

  const drawFromImage = (imageUrl) => {
    const fabricCanvas = canvasRef.current;

    if (!fabricCanvas || !lastObjectRef.current) {
      console.error("Canvas not initialized or no last object available");
      return;
    }

    if (typeof imageUrl !== "string") {
      console.error("Invalid imageUrl. It should be a string.");
      return;
    }

    const lastObject = lastObjectRef.current;
    const { left, top } = lastObject;

    fabricCanvas.remove(lastObject);

    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = imageUrl;

    img.onload = () => {
      fabric.Image.fromURL(
        imageUrl,
        (fabricImg) => {
          if (!fabricImg) {
            console.error("Image failed to load");
            return;
          }

          fabricImg.set({
            left: left,
            top: top,
            scaleX: 0.5,
            scaleY: 0.5,
          });

          fabricCanvas.add(fabricImg);
          fabricCanvas.renderAll();
        },
        { crossOrigin: "anonymous" }
      );
    };

    img.onerror = () => {
      console.error("Image failed to load");
    };
  };

  const downloadImage = (image) => {
    // Create a new anchor element
    const anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = "download.png";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container>
        <CustomSnackbar
          snackbarOpen={snackbarOpen}
          setSnackbarOpen={setSnackbarOpen}
          snackbarSeverity={snackbarSeverity}
          snackbarContent={snackbarContent}
        />

        {/* Sidebar for controls */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            position: ["relative", "fixed"],
            height: ["auto", "100vh"],
            width: {
              xs: "100%", // For small screens, including iPad mini
              sm: "100%", // For small screens, including iPad mini
              md: isSmallScreen ? "100%" : isLandscape ? "11vw" : "7vw", // For medium and larger screens
            },
            background: "transparent",
            display: "flex",
            flexDirection: ["row", "column"],
            alignItems: "center",
            justifyContent: ["space-around", "flex-start"],
            gap: 2,
            paddingTop: [10, 2],
            paddingLeft: [2, 2, 0, 0],
            zIndex: [1, "auto"],
            overflowX: ["auto", "visible"],
            overflowY: ["auto", "auto"],
          }}
        >
          <Tooltip title="Draw" arrow>
            <IconButton
              onClick={toggleDrawingMode}
              sx={{
                color: fillMode ? "red" : "#FFFFFF",
                background: "rgba(255, 255, 255, 0.1)", // semi-transparent background
                backdropFilter: "blur(10px)", // blur effect
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", // subtle shadow
                borderRadius: "25px", // rounded corners
                border: "1px solid rgba(255, 255, 255, 0.3)", // light border
                padding: "8px", // padding for better visual appearance
                width: isSmallScreen ? "20vw" : isLandscape ? "10vw" : "5vw",
              }}
            >
              <img
                src={BrushIcon}
                alt="Brush"
                style={{
                  width: isSmallScreen ? "10vw" : isLandscape ? "5vw" : "3vw",
                  height: isSmallScreen ? "5vh" : isLandscape ? "5vh" : "4vh",
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Erase" arrow>
            <IconButton
              onClick={toggleEraserMode}
              sx={{
                color: isEraser ? "red" : "#FFFFFF",
                background: "rgba(255, 255, 255, 0.1)",
                backdropFilter: "blur(10px)",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                borderRadius: "25px",
                border: "1px solid rgba(255, 255, 255, 0.3)",
                padding: "8px",
                width: isSmallScreen ? "20vw" : isLandscape ? "10vw" : "5vw",
              }}
            >
              <img
                src={eraserIcon}
                alt="Eraser"
                style={{
                  width: isSmallScreen ? "10vw" : isLandscape ? "5vw" : "3vw",
                  height: isSmallScreen ? "5vh" : isLandscape ? "5vh" : "4vh",
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Fill" arrow>
            <IconButton
              onClick={toggleFillMode}
              sx={{
                color: fillMode ? "#FFFFFF" : "red",
                background: "rgba(255, 255, 255, 0.1)",
                backdropFilter: "blur(10px)",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                borderRadius: "25px",
                border: "1px solid rgba(255, 255, 255, 0.3)",
                padding: "8px",
                width: isSmallScreen ? "20vw" : isLandscape ? "10vw" : "5vw",
              }}
            >
              <img
                src={FormatPaintIcon}
                alt="Fill"
                style={{
                  width: isSmallScreen ? "10vw" : isLandscape ? "5vw" : "3vw",
                  height: isSmallScreen ? "5vh" : isLandscape ? "5vh" : "4vh",
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Select/Move/Resize" arrow>
            <IconButton
              onClick={toggleSelectionMode}
              sx={{
                color: isSelectionMode ? "red" : "#FFFFFF",
                background: "rgba(255, 255, 255, 0.1)",
                backdropFilter: "blur(10px)",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                borderRadius: "25px",
                border: "1px solid rgba(255, 255, 255, 0.3)",
                padding: "8px",
                width: isSmallScreen ? "20vw" : isLandscape ? "10vw" : "5vw",
              }}
            >
              <SelectAllIcon
                fontSize="large"
                style={{
                  width: isSmallScreen ? "10vw" : isLandscape ? "5vw" : "3vw",
                  height: isSmallScreen ? "5vh" : isLandscape ? "5vh" : "4vh",
                }}
              />
            </IconButton>
          </Tooltip>

          {/* Buttons to trigger image upload */}
          <Tooltip title="AI Image" arrow>
            <IconButton
              onClick={() => handleButtonClick("canvas", "image", "")}
              sx={{
                background: "rgba(255, 255, 255, 0.1)",
                backdropFilter: "blur(10px)",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                borderRadius: "25px",
                border: "1px solid rgba(255, 255, 255, 0.3)",
                padding: "8px",
                color: "#FFFFFF",
                width: isSmallScreen ? "20vw" : isLandscape ? "10vw" : "5vw",
              }}
            >
              <img
                src={AIImageIcon}
                alt="AI Image"
                style={{
                  width: isSmallScreen ? "10vw" : isLandscape ? "5vw" : "3vw",
                  height: isSmallScreen ? "5vh" : isLandscape ? "5vh" : "4vh",
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="AI Video" arrow>
            <IconButton
              onClick={() => handleButtonClick("canvas", "video", "")}
              sx={{
                background: "rgba(255, 255, 255, 0.1)",
                backdropFilter: "blur(10px)",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                borderRadius: "25px",
                border: "1px solid rgba(255, 255, 255, 0.3)",
                padding: "8px",
                color: "#FFFFFF",
                width: isSmallScreen ? "20vw" : isLandscape ? "10vw" : "5vw",
              }}
            >
              <img
                src={AIVideoIcon}
                alt="AI Video"
                style={{
                  width: isSmallScreen ? "10vw" : isLandscape ? "5vw" : "3vw",
                  height: isSmallScreen ? "5vh" : isLandscape ? "5vh" : "4vh",
                }}
              />
            </IconButton>
          </Tooltip>

          <>
            <input
              type="color"
              value={color}
              onChange={handleColorChange}
              style={{
                width: isSmallScreen ? "20vw" : isLandscape ? "10vw" : "5vw",
                height: "50px",
                borderRadius: "15px",
                background: "rgba(255, 255, 255, 0.1)",
                backdropFilter: "blur(10px)",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                border: "1px solid rgba(255, 255, 255, 0.3)",
                color: "#FFFFFF",
                padding: "8px",
                WebkitAppearance: "none",
                appearance: "none",
                marginBottom: "8px", // Reduced bottom margin
              }}
            />

            {(!fillMode || isEraser) && (
              <>
                <Slider
                  value={isEraser ? eraserWidth : strokeWidth}
                  onChange={
                    isEraser ? handleEraserWidthChange : handleStrokeWidthChange
                  }
                  min={1}
                  max={isEraser ? 50 : 10}
                  valueLabelDisplay="auto"
                  sx={{
                    alignSelf: "center",
                    width: isSmallScreen
                      ? "20vw"
                      : isLandscape
                      ? "10vw"
                      : "4vw",
                  }}
                  orientation="horizontal"
                />
              </>
            )}
          </>

          <Tooltip title="Undo" arrow>
            <IconButton
              onClick={handleUndo}
              disabled={undoStack.length <= 1}
              sx={{ margin: "0 4px", padding: "8px" }} // Adjusted margin and padding
            >
              <UndoIcon fontSize="large" style={{ color: "white" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Redo" arrow>
            <IconButton
              onClick={handleRedo}
              disabled={redoStack.length === 0}
              sx={{ margin: "0 4px", padding: "8px" }} // Adjusted margin and padding
            >
              <RedoIcon fontSize="large" style={{ color: "white" }} />
            </IconButton>
          </Tooltip>
        </Grid>

        {/* Canvas Area */}
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            marginTop: ["8px", 0],
            display: "flex",
            flexDirection: ["column", "column", "column", "row"],
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              height: isSmallScreen ? "70vh" : "auto",
              width: isSmallScreen ? "90vw" : "67vw",
              marginRight: ["0", "0", "15%", "15%"],
              overflow: "auto",
              borderRadius: "15px",
              overflow: "hidden",
            }}
          >
            <canvas
              ref={canvasRef}
              width={canvasDimensions.width}
              height={canvasDimensions.height}
            />
          </Box>

          {!isSmallScreen && (
            <>
              <Box
                sx={{
                  position: "absolute",
                  left: expanded ? "48vw" : "76vw", // Adjust based on expanded state
                  top: "60vh",
                  transform: "translateY(-50%)",
                  background: expanded ? "#fff" : "transparent",
                  width: expanded ? "2vw" : "2vw", // Adjust width based on expanded state
                  height: "60px",
                  borderRadius: "15px 0 0 15px",
                  border: "2px solid rgba(255, 255, 255, 0.3)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.5)",
                  zIndex: 10,
                }}
                onClick={handleExpandClick}
              >
                <Button
                  sx={{
                    minWidth: "auto",
                    padding: 0,
                  }}
                >
                  <ArrowBackIcon
                    sx={{
                      color: expanded ? "black" : "white",
                      transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                      alignItems: "center",
                    }}
                  />
                </Button>
              </Box>

              <Box
                className="custom-scrollbar"
                sx={{
                  position: "absolute", // Position absolutely to overlap the canvas box
                  bottom: 0,
                  right: 0,
                  height: isLandscape ? "90vh" : "90vh", // Ensure it covers the height of the parent
                  width: expanded ? "50vw" : "22vw", // Adjust width based on expanded state
                  marginLeft: ["0", "0", "0", "10%"],
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: [2, 2, 2, 0],
                  borderRadius: "15px",
                  background: "rgba(255, 255, 255, 0.1)",
                  backdropFilter: "blur(10px)",
                  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                  border: "1px solid rgba(255, 255, 255, 0.3)",
                  overflowY: "auto",
                  zIndex: 3, // Ensure this box overlaps the canvas box
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    alignSelf: "flex-start",
                    marginLeft: 2,
                    marginTop: 2,
                    color: expanded ? "#011636" : "white",
                  }}
                >
                  Result
                </Typography>

                {predictingImage && (
                  <div>
                    <Loader expanded={expanded} />
                  </div>
                )}
                {predictedImageBox && !expanded && (
                  <div>
                    {predictedImageData.map((image, index) => (
                      <div key={index}>
                        <img
                          src={image}
                          alt={`Predicted ${index}`}
                          style={{
                            width: "100%",
                            marginBottom: "10px",
                          }}
                          crossOrigin="anonymous"
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            marginBottom: 3,
                            "@media (max-width: 1366px)": {
                              // Styles for tablet screens including larger tablets like iPad Pro
                              flexDirection: "column", // Stack buttons vertically on tablet screens
                              alignItems: "center", // Center the buttons
                            },
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() =>
                              drawFromImage(predictedImageData[index])
                            }
                            sx={{
                              backgroundColor: expanded
                                ? "white"
                                : "transparent",
                              color: expanded ? "#011636" : "white",
                              // margin: "8px", // Added margin for spacing between buttons
                              border: "1px solid white",
                              borderRadius: "12px",
                              padding: "4px 8px", // Reduced padding for smaller buttons on tablets
                              // minWidth: "100px", // Reduced minimum width for smaller buttons
                              fontSize: "1.5vh", // Smaller font size for tablets
                              "&:hover": {
                                backgroundColor: "white",
                                color: "#011636",
                              },
                              "@media (max-width: 1366px)": {
                                padding: "3px 6px", // Further reduced padding for tablet screens
                                minWidth: "30px", // Further reduced width for tablets
                                fontSize: "0.8vh", // Reduced font size for tablets
                              },
                            }}
                          >
                            Draw
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() =>
                              downloadImage(predictedImageData[index])
                            }
                            sx={{
                              backgroundColor: expanded
                                ? "white"
                                : "transparent",
                              color: expanded ? "#011636" : "white",
                              // margin: "8px", // Added margin for spacing between buttons
                              border: "1px solid white",
                              borderRadius: "12px",
                              padding: "4px 8px", // Reduced padding for smaller buttons on tablets
                              // minWidth: "100px", // Reduced minimum width for smaller buttons
                              fontSize: "1.5vh", // Smaller font size for tablets
                              "&:hover": {
                                backgroundColor: "white",
                                color: "#011636",
                              },
                              "@media (max-width: 1366px)": {
                                padding: "3px 6px", // Further reduced padding for tablet screens
                                minWidth: "30px", // Further reduced width for tablets
                                fontSize: "0.8vh", // Reduced font size for tablets
                              },
                            }}
                          >
                            Download
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleButtonClick(
                                "predictedImage",
                                "image",
                                index
                              )
                            }
                            sx={{
                              backgroundColor: expanded
                                ? "white"
                                : "transparent",
                              color: expanded ? "#011636" : "white",
                              // margin: "8px", // Added margin for spacing between buttons
                              border: "1px solid white",
                              borderRadius: "12px",
                              padding: "4px 8px", // Reduced padding for smaller buttons on tablets
                              // minWidth: "100px", // Reduced minimum width for smaller buttons
                              fontSize: "1.5vh", // Smaller font size for tablets
                              "&:hover": {
                                backgroundColor: "white",
                                color: "#011636",
                              },
                              "@media (max-width: 1366px)": {
                                padding: "3px 6px", // Further reduced padding for tablet screens
                                minWidth: "30px", // Further reduced width for tablets
                                fontSize: "0.8vh", // Reduced font size for tablets
                              },
                            }}
                          >
                            AI Image
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleButtonClick("predictedImage", "video", index)
                            }
                            sx={{
                              backgroundColor: expanded
                                ? "white"
                                : "transparent",
                              color: expanded ? "#011636" : "white",
                              // margin: "8px", // Added margin for spacing between buttons
                              border: "1px solid white",
                              borderRadius: "12px",
                              padding: "4px 8px", // Reduced padding for smaller buttons on tablets
                              // minWidth: "80px", // Reduced minimum width for smaller buttons
                              fontSize: "1.5vh", // Smaller font size for tablets
                              "&:hover": {
                                backgroundColor: "white",
                                color: "#011636",
                              },
                              "@media (max-width: 1366px)": {
                                padding: "3px 6px", // Further reduced padding for tablet screens
                                minWidth: "30px", // Further reduced width for tablets
                                fontSize: "0.8vh", // Reduced font size for tablets
                              },
                            }}
                          >
                            AI Video
                          </Button>
                        </Box>
                      </div>
                    ))}
                  </div>
                )}

                {!predictingImage && predictedImageBox && expanded && (
                  <Paper
                    elevation={3}
                    sx={{
                      backgroundColor: "transparent",
                      color: expanded ? "#011636" : "white",
                      width: "90%",
                      height: "80%",
                      textAlign: "center",
                      marginTop: "10px",
                      position: "relative",
                      overflowY: "auto", // Add vertical scrolling
                      maxHeight: "80vh", // Ensure the height can grow but remains within viewport
                    }}
                  >
                    {predictedImageData.map((image, index) => (
                      <div key={index}>
                        <img
                          src={image}
                          alt={`Predicted ${index}`}
                          style={{
                            width: "80%", // Adjust width if necessary
                            marginBottom: "10px", // Add spacing between images
                          }}
                          crossOrigin="anonymous"
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center", // Center align buttons
                            marginBottom: 3,
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() =>
                              drawFromImage(predictedImageData[index])
                            }
                            sx={{
                              backgroundColor: expanded
                                ? "white"
                                : "transparent",
                              color: expanded ? "#011636" : "white",
                              margin: "0 2px",
                              border: "2px solid white",
                              borderRadius: "12px",
                              padding: "2px 6px",
                              minWidth: "60px",
                              fontSize: "1.5rem",
                              fontWeight: "bold",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "#011636",
                              },
                            }}
                          >
                            Draw
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() =>
                              downloadImage(predictedImageData[index])
                            }
                            sx={{
                              backgroundColor: expanded
                                ? "white"
                                : "transparent",
                              color: expanded ? "#011636" : "white",
                              margin: "0 2px",
                              border: "2px solid white",
                              borderRadius: "12px",
                              padding: "2px 6px",
                              minWidth: "60px",
                              fontSize: "1.5rem",
                              fontWeight: "bold",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "#011636",
                              },
                            }}
                          >
                            Download
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleButtonClick("predictedImage", "image", index)
                            }
                            sx={{
                              backgroundColor: expanded
                                ? "white"
                                : "transparent",
                              color: expanded ? "#011636" : "white",
                              margin: "0 2px",
                              border: "2px solid white",
                              borderRadius: "12px",
                              padding: "2px 6px",
                              minWidth: "60px",
                              fontSize: "1.5rem",
                              fontWeight: "bold",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "#011636",
                              },
                            }}
                          >
                            AI Image
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleButtonClick("predictedImage", "video", index)
                            }
                            sx={{
                              backgroundColor: expanded
                                ? "white"
                                : "transparent",
                              color: expanded ? "#011636" : "white",
                              margin: "0 2px",
                              border: "2px solid white",
                              borderRadius: "12px",
                              padding: "2px 6px",
                              minWidth: "60px",
                              fontSize: "1.5rem",
                              fontWeight: "bold",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "#011636",
                              },
                            }}
                          >
                            AI Video
                          </Button>
                        </Box>
                      </div>
                    ))}
                  </Paper>
                )}

                {!predictingImage && !predictedImageBox && (
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      color: "white",
                      marginTop: 2,
                    }}
                  >
                    No Predicted Image Available
                  </Typography>
                )}
              </Box>
            </>
          )}
          {isSmallScreen && (
            <Chat className="chatbox" openi={openi} setOpen={setOpen}>
              <Typography
                variant="h2"
                sx={{
                  // alignSelf: "flex-start",
                  marginLeft: 0,
                  marginTop: 0,
                  color: "white",
                }}
              >
                Result
              </Typography>

              {predictingImage && !predictedImageBox ? (
                <div>
                  <Loader expanded={expanded} />
                </div>
              ) : predictedImageBox ? (
                <div>
                  {predictingImage && <Loader expanded={expanded} />}

                  {!expanded && (
                    <div>
                      {predictedImageData.map((image, index) => (
                        <div key={index}>
                          <img
                            src={image}
                            alt={`Predicted ${index}`}
                            style={{
                              width: "80%", // Adjust width if necessary
                              marginBottom: "10px", // Add spacing between images
                              height: "20%",
                            }}
                            crossOrigin="anonymous"
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center", // Center align buttons
                              marginBottom: 3,
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={() =>
                                drawFromImage(predictedImageData[index])
                              }
                              sx={{
                                backgroundColor: expanded
                                  ? "white"
                                  : "transparent",
                                color: expanded ? "#011636" : "white",
                                margin: "0 2px",
                                border: "2px solid white",
                                padding: "2px 6px",
                                minWidth: "60px",
                                fontSize: "0.7rem",
                                "&:hover": {
                                  backgroundColor: "white",
                                  color: "#011636",
                                },
                              }}
                            >
                              Draw
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() =>
                                downloadImage(predictedImageData[index])
                              }
                              sx={{
                                backgroundColor: expanded
                                  ? "white"
                                  : "transparent",
                                color: expanded ? "#011636" : "white",
                                margin: "0 2px",
                                border: "2px solid white",
                                padding: "2px 6px",
                                minWidth: "60px",
                                fontSize: "0.7rem",
                                "&:hover": {
                                  backgroundColor: "white",
                                  color: "#011636",
                                },
                              }}
                            >
                              Download
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() =>
                                handleButtonClick("predictedImage", "image", index)
                              }
                              sx={{
                                backgroundColor: expanded
                                  ? "white"
                                  : "transparent",
                                color: expanded ? "#011636" : "white",
                                margin: "0 2px",
                                border: "2px solid white",
                                padding: "2px 6px",
                                minWidth: "60px",
                                fontSize: "0.7rem",
                                "&:hover": {
                                  backgroundColor: "white",
                                  color: "#011636",
                                },
                              }}
                            >
                              AI Image
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() =>
                                handleButtonClick("predictedImage", "video", index)
                              }
                              sx={{
                                backgroundColor: expanded
                                  ? "white"
                                  : "transparent",
                                color: expanded ? "#011636" : "white",
                                margin: "0 2px",
                                border: "2px solid white",
                                padding: "2px 6px",
                                minWidth: "60px",
                                fontSize: "0.7rem",
                                "&:hover": {
                                  backgroundColor: "white",
                                  color: "#011636",
                                },
                              }}
                            >
                              AI Video
                            </Button>
                          </Box>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    color: "white",
                    marginTop: 2,
                  }}
                >
                  No Predicted Image Available
                </Typography>
              )}
            </Chat>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default PaintApp;
