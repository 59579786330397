import { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  MenuItem,
  FormControl,
  Select,
  Typography,
  TextField,
  Stack,
  Button,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  genres,
  tones,
  personalities,
  writing_styles,
  persona,
  persona_mapping,
} from "utils/constants";

const PersonaHandler = ({
  onPersonaChange,
  onGenreChange,
  onWritingsChange,
  onPersonalityChange,
  onToneChange,
  currentPersona,
  setNewMessage,
  onSubmit,
}) => {
  const [selectedTone, setSelectedTone] = useState("");
  const [selectedGenre, setSelectedGenre] = useState("");
  const [selectedPersonality, setSelectedPersonality] = useState("");
  const [selectedWritingStyles, setSelectedWritingStyles] = useState("");
  const [selectedPersona, setSelectedPersona] = useState(
    currentPersona ? currentPersona : ""
  );
  const [prompt, setPrompt] = useState("");

  useEffect(() => {
    try {
      const persona_now = currentPersona;

      const mapping_object = persona_mapping[persona_now];

      setSelectedWritingStyles(mapping_object["writing-style"]);
      setSelectedPersonality(mapping_object.personality);
      setSelectedGenre(mapping_object.genre);
      setSelectedTone(mapping_object.tone);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handlePersonaChange = (event, newValue) => {
    try {
      setSelectedPersona(newValue);

      const persona_now = newValue;

      const mapping_object = persona_mapping[persona_now];

      setSelectedWritingStyles(mapping_object["writing-style"]);
      setSelectedPersonality(mapping_object.personality);
      setSelectedGenre(mapping_object.genre);
      setSelectedTone(mapping_object.tone);

      onPersonaChange(newValue);
    } catch (error) {
      console.error(error);
    }
  };

  const handleWritingStylesChange = (event, newValue) => {
    setSelectedWritingStyles(newValue);
    onWritingsChange(newValue);
  };

  const handlePersonalityChange = (event, newValue) => {
    setSelectedPersonality(newValue);
    onPersonalityChange(newValue);
  };

  const handleGenreChange = (event, newValue) => {
    setSelectedGenre(newValue);
    onGenreChange(newValue);
  };

  const handleToneChange = (event, newValue) => {
    setSelectedTone(newValue);
    onToneChange(newValue);
  };

  const handlePrompt = (event) => {
    setPrompt(event.target.value);
    setNewMessage(event.target.value);
    // console.log(event.target.value);
  };

  return (
    <div>
      <List>
        <ListItem
          sx={{
            display: "flex",
            alignItems: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              lg: "center",
              xl: "center",
            },
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "200px",
                xl: "200px",
              },
              marginRight: {
                xs: "0",
                sm: "0",
                md: "0",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            Prompt
          </Typography>
          <FormControl fullWidth sx={{ flex: 1, marginRight: "16px" }}>
            <TextField
              placeholder="5000 characters only..."
              style={{ color: "white" }}
              InputLabelProps={{
                style: { color: "white", fontSize: "2rem" },
              }}
              InputProps={{ style: { color: "white", fontSize: "2rem" } }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "& fieldset": {
                    borderColor: "white",
                    borderRadius: "5px",
                  },
                  color: "white",
                  fontSize: "18px",
                },
              }}
              value={prompt}
              onChange={handlePrompt}
              multiline
              maxRows={10}
              inputProps={{ maxLength: 5000 }}
            />
          </FormControl>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            alignItems: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              lg: "center",
              xl: "center",
            },
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "200px",
                xl: "200px",
              },
              marginRight: {
                xs: "0",
                sm: "0",
                md: "0",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            Persona
          </Typography>
          <FormControl fullWidth sx={{ flex: 1, marginRight: "16px" }}>
            {/* <Select value={selectedPersona} onChange={handlePersonaChange}>
              {persona.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Select> */}
            <Autocomplete
  value={selectedPersona}
  onChange={handlePersonaChange}
  options={persona}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Search..."
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
          </InputAdornment>
        ),
        style: {
          color: "white",
          fontSize: "2rem", // Increase the font size of the input text
        },
      }}
      InputLabelProps={{
        style: {
          color: "white",
          fontSize: "2rem", // Increase the font size of the placeholder text
        },
      }}
      sx={{
        "& .MuiInputBase-input": {
          color: "white",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
          "& fieldset": {
            borderColor: "white",
          },
          "&:hover fieldset": {
            borderColor: "white",
          },
          "&.Mui-focused fieldset": {
            borderColor: "white",
          },
        },
        "& .MuiInputLabel-root": {
          color: "white",
          fontSize: "1.25rem", // Increase the font size of the placeholder
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "white",
          fontSize: "1.25rem", // Increase the font size of the placeholder when focused
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "white",
        },
        "& .MuiInputAdornment-root .MuiSvgIcon-root": {
          color: "white",
        },
        "& .MuiInputBase-root": {
          color: "white",
        },
      }}
    />
  )}
  ListboxProps={{
    sx: {
      "& .MuiAutocomplete-option": {
        fontSize: "1.5rem", // Increase the font size of the options in the dropdown
        color: "black", // Set text color for the options
        "&[aria-selected='true']": {
          backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
        },
        "&:hover": {
          backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
        },
      },
    },
  }}
/>

          </FormControl>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            alignItems: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              lg: "center",
              xl: "center",
            },
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "200px",
                xl: "200px",
              },
              marginRight: {
                xs: "0",
                sm: "0",
                md: "0",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            Genre
          </Typography>
          <FormControl fullWidth sx={{ flex: 1, marginRight: "16px" }}>
            {/* <Select value={selectedGenre} onChange={handleGenreChange}>
              {genres.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Select> */}
            <Autocomplete
              value={selectedGenre}
              onChange={handleGenreChange}
              options={genres}
              renderInput={(params) => (
                <TextField
              {...params}
              placeholder="Search..."
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                  </InputAdornment>
                ),
                style: {
                  color: "white",
                  fontSize: "2rem", // Increase the font size of the input text
                },
              }}
              InputLabelProps={{
                style: {
                  color: "white",
                  fontSize: "2rem", // Increase the font size of the placeholder text
                },
              }}
              sx={{
                "& .MuiInputBase-input": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "white",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiInputBase-root": {
                  color: "white",
                },
              }}

              
            />
              )}
              ListboxProps={{
                sx: {
                  "& .MuiAutocomplete-option": {
                    fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                    color: "black", // Set text color for the options
                    "&[aria-selected='true']": {
                      backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                    },
                    "&:hover": {
                      backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                    },
                  },
                },
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            alignItems: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              lg: "center",
              xl: "center",
            },
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "200px",
                xl: "200px",
              },
              marginRight: {
                xs: "0",
                sm: "0",
                md: "0",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            Tone
          </Typography>
          <FormControl fullWidth sx={{ flex: 1, marginRight: "16px" }}>
            {/* <Select value={selectedTone} onChange={handleToneChange}>
              {tones.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Select> */}
            <Autocomplete
              value={selectedTone}
              onChange={handleToneChange}
              options={tones}
              renderInput={(params) => (
                <TextField
              {...params}
              placeholder="Search..."
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                  </InputAdornment>
                ),
                style: {
                  color: "white",
                  fontSize: "2rem", // Increase the font size of the input text
                },
              }}
              InputLabelProps={{
                style: {
                  color: "white",
                  fontSize: "2rem", // Increase the font size of the placeholder text
                },
              }}
              sx={{
                "& .MuiInputBase-input": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "white",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiInputBase-root": {
                  color: "white",
                },
              }}
            />
              )}
              ListboxProps={{
                sx: {
                  "& .MuiAutocomplete-option": {
                    fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                    color: "black", // Set text color for the options
                    "&[aria-selected='true']": {
                      backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                    },
                    "&:hover": {
                      backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                    },
                  },
                },
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            alignItems: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              lg: "center",
              xl: "center",
            },
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "200px",
                xl: "200px",
              },
              marginRight: {
                xs: "0",
                sm: "0",
                md: "0",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            Personality
          </Typography>
          <FormControl fullWidth sx={{ flex: 1, marginRight: "16px" }}>
            {/* <Select
              value={selectedPersonality}
              onChange={handlePersonalityChange}
            >
              {personalities.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Select> */}
            <Autocomplete
              value={selectedPersonality}
              onChange={handlePersonalityChange}
              options={personalities}
              renderInput={(params) => (
                <TextField
              {...params}
              placeholder="Search..."
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                  </InputAdornment>
                ),
                style: {
                  color: "white",
                  fontSize: "2rem", // Increase the font size of the input text
                },
              }}
              InputLabelProps={{
                style: {
                  color: "white",
                  fontSize: "2rem", // Increase the font size of the placeholder text
                },
              }}
              sx={{
                "& .MuiInputBase-input": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "white",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiInputBase-root": {
                  color: "white",
                },
              }}
            />
              )}
              ListboxProps={{
                sx: {
                  "& .MuiAutocomplete-option": {
                    fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                    color: "black", // Set text color for the options
                    "&[aria-selected='true']": {
                      backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                    },
                    "&:hover": {
                      backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                    },
                  },
                },
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            alignItems: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              lg: "center",
              xl: "center",
            },
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "200px",
                xl: "200px",
              },
              marginRight: {
                xs: "0",
                sm: "0",
                md: "0",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            Writing Style
          </Typography>
          <FormControl fullWidth sx={{ flex: 1, marginRight: "16px" }}>
            {/* <Select
              value={selectedWritingStyles}
              onChange={handleWritingStylesChange}
            >
              {writing_styles.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Select> */}
            <Autocomplete
              value={selectedWritingStyles}
              onChange={handleWritingStylesChange}
              options={writing_styles}
              renderInput={(params) => (
                <TextField
              {...params}
              placeholder="Search..."
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                  </InputAdornment>
                ),
                style: {
                  color: "white",
                  fontSize: "2rem", // Increase the font size of the input text
                },
              }}
              InputLabelProps={{
                style: {
                  color: "white",
                  fontSize: "2rem", // Increase the font size of the placeholder text
                },
              }}
              sx={{
                "& .MuiInputBase-input": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "white",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiInputBase-root": {
                  color: "white",
                },
              }}
            />
              )}
              ListboxProps={{
                sx: {
                  "& .MuiAutocomplete-option": {
                    fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                    color: "black", // Set text color for the options
                    "&[aria-selected='true']": {
                      backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                    },
                    "&:hover": {
                      backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                    },
                  },
                },
              }}
            />
          </FormControl>
        </ListItem>
      </List>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        flexWrap="wrap"
        spacing={{ xs: 1, sm: 1, md: 2 }}
        useFlexGap
      >
        <Button
          id="basic-button"
          variant="outlined"
          aria-haspopup="true"
          size="large"
          style={{
            border: "1px solid white",
            backgroundColor: "transparent",
            color: "white",
            fontSize: "16px",
            borderRadius: "20px",
            "&:hover": {  
              backgroundColor: "white",
              color: "#011636",
            },
          }}
          onClick={onSubmit}
        >
          Generate
        </Button>
      </Stack>
    </div>
  );
};

export default PersonaHandler;