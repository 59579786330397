import Loader from "assets/loader.gif";
import "./Loader.css";

const SpinnerLoader = () => {
  return (
    <div>
      <img
        src={Loader}
        alt="Loading..."
        style={{ width: "60px", height: "60px", transform: "translateX(-20%)" }}
      />
    </div>
  );
};

export default SpinnerLoader;
