import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="buffer" color="primary" value={props.value} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="h5" color="white">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel({ isAnimation = false }) { 
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Calculate intervalTime based on isAnimation
    const totalDuration = isAnimation ? 120000 : 80000; 
    const intervalTime = totalDuration / 99; 

    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 99) {
          clearInterval(timer);
          return prevProgress;
        }
        return prevProgress + 1;
      });
    }, intervalTime);

    return () => {
      clearInterval(timer);
    };
  }, [isAnimation]); 

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
