import { useState, useEffect } from "react";
import {
  List,
  ListItem,
  MenuItem,
  FormControl,
  Autocomplete,
  Select,
  Typography,
  TextField,
  Button,
  Stack,
  Slider,
  Checkbox,
  Divider,
  InputAdornment,
  FormControlLabel,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  imageAspectRatios,
  imageArtStyles,
  colorSchemes,
  tones,
  textStyles
} from "utils/constants.js";

const ImageSettingsDrawer = ({
  images,
  onAspectRatioChange,
  onImageArtStyleChange,
  onColoSchemeChange,
  onImageToneChange,
  onImageSeedChange,
  onPresetStyleChange,
  onCharacterRetention,
  onChangePromptText,
  onModelSelection,
  onSubmit,
  promptText,
  characterRetention: characterRetentionProp,
  upscale: upscaleProp,
  outpaint: outpaintProp,
  outpaintValues: outpaintValuesProp,
  removeBackground: removeBackgroundProp,
  onUpscaleChange,
  onOutpaintChange,
  onRemoveBackgroundChange,
  onOutpaintValuesChange,
  onTextStyleChange
}) => {
  const [seedValue, setSeedValue] = useState("");
  const [aspectRatio, setAspectRatio] = useState("");
  const [imageArtStyle, setImageArtStyle] = useState("");
  const [colorScheme, setColorScheme] = useState("");
  const [imageTone, setImageTone] = useState("");
  const [textStyle, setTextStyle] = useState("");
  const [prompt, setPrompt] = useState(promptText);
  const [checked, setChecked] = useState(false);
  const [characterRetention, setCharacterRetention] = useState(
    characterRetentionProp
  );
  const [upscale, setUpscale] = useState(upscaleProp);
  const [removeBackground, setRemoveBackground] = useState(false);
  const [outpaint, setOutpaint] = useState(false);
  const [creativeUpscaleFlag, setCreativeUpscaleFlag] = useState(false);
  const [conservativeUpscaleFlag, setConservativeUpscaleFlag] = useState(false);

  const [outpaintValues, setOutpaintValues] = useState(outpaintValuesProp);

  const [presetStyle, setPresetStyle] = useState("");
  const [selectedModel, setSelectedModel] = useState(null);

  const stylesForChecked = [
    "ANIME",
    "CREATIVE",
    "DYNAMIC",
    "ENVIRONMENT",
    "GENERAL",
    "ILLUSTRATION",
    "PHOTOGRAPHY",
    "RAYTRACED",
    "RENDER_3D",
    "SKETCH_BW",
    "SKETCH_COLOR",
    "NONE",
  ];
  const stylesForUnchecked = ["NONE"];

  const handleImageAspectRatio = (event, newValue) => {
    setAspectRatio(newValue);
    onAspectRatioChange(newValue);
  };

  const handleSeedValue = (event) => {
    setSeedValue(event.target.value);
    onImageSeedChange(event.target.value);
  };

  const handleImageArtStyle = (event, newValue) => {
    setImageArtStyle(newValue);
    onImageArtStyleChange(newValue);
  };

  const handleColorScheme = (event, newValue) => {
    setColorScheme(newValue);
    onColoSchemeChange(newValue);
  };

  const handleTextStyle = (event, newValue) => {
    setTextStyle(newValue);
    onTextStyleChange(newValue);
  };

  const handleImageTone = (event, newValue) => {
    setImageTone(newValue);
    onImageToneChange(newValue);
  };

  const handlePrompt = (event) => {
    setPrompt(event.target.value);
    onChangePromptText(event);
  };

  const handleChangeCheckbox = (event) => {
    setChecked(event.target.checked);
    if (!event.target.checked) {
      setPresetStyle("NONE");
    }
  };

  const handleCharacterRetention = (event) => {
    setCharacterRetention(event.target.checked);
    onCharacterRetention(event.target.checked);
  };

  const handleChangeSelect = (event, newValue) => {
    setPresetStyle(newValue);
    onPresetStyleChange(newValue);
  };

  const handleOutpaintSliderChange = (direction, value) => {
    setOutpaintValues((prevValues) => ({
      ...prevValues,
      [direction]: value,
    }));
    onOutpaintValuesChange(direction, value);
  };

  const handleOutpaint = (checked) => {
    if (checked) {
      setOutpaint(true);
      setRemoveBackground(false);
      setCreativeUpscaleFlag(false);
      setConservativeUpscaleFlag(false);
      setSelectedModel("model_4");
      onModelSelection("model_4");
      onOutpaintChange(true);
    } else {
      setOutpaint(false);
      setSelectedModel(null);
      onModelSelection(null);
    }
  };

  const handleRemoveBackground = (checked) => {
    if (checked) {
      setRemoveBackground(true);
      setOutpaint(false);
      setCreativeUpscaleFlag(false);
      setConservativeUpscaleFlag(false);
      setSelectedModel("model_4");
      onModelSelection("model_4");
      onRemoveBackgroundChange(true);
    } else {
      setRemoveBackground(false);
      setSelectedModel(null);
      onModelSelection(null);
    }
  };

  const handleCreativeUpscaleMode = (checked) => {
    if (checked) {
      setCreativeUpscaleFlag(true);
      setConservativeUpscaleFlag(false);
      setOutpaint(false);
      setRemoveBackground(false);
      setSelectedModel("model_4");
      onModelSelection("model_4");
      setUpscale("Creative Upscale");
      onUpscaleChange("Creative Upscale");
    } else {
      setCreativeUpscaleFlag(false);
      setSelectedModel(null);
      onModelSelection(null);
    }
  };

  const handleConservativeUpscaleMode = (checked) => {
    if (checked) {
      setConservativeUpscaleFlag(true);
      setCreativeUpscaleFlag(false);
      setOutpaint(false);
      setRemoveBackground(false);
      setSelectedModel("model_4");
      onModelSelection("model_4");
      setUpscale("Conservative Upscale");
      onUpscaleChange("Conservative Upscale");
    } else {
      setConservativeUpscaleFlag(false);
      setSelectedModel(null);
      onModelSelection(null);
    }
  };

  const handleModelSelect = (event) => {
    console.log(event.target.id);
    let selectedModel;
    switch (event.target.id) {
      case "basic-button-1":
        selectedModel = "model_1";
        break;
      case "basic-button-2":
        selectedModel = "model_2";
        break;
      case "basic-button-3":
        selectedModel = "model_3";
        break;
      case "basic-button-4":
        selectedModel = "model_4";
        break;
      default:
        selectedModel = null;
    }
    if (selectedModel) {
      setSelectedModel(selectedModel);
      onModelSelection(selectedModel);
    }
  };

  return (
    <div>
      {/* Model selection */}
      <div style={{marginTop: "25px"}}>
        <Typography
          variant="h3"
          sx={{
            color: "#FFFFFF", 
            textAlign: "center",
          }}
        >
          Select a Model
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-around"
          flexWrap="wrap"
          spacing={{ xs: 1, sm: 1, md: 2 }}
          sx={{mt: 3, ml: { xs: 0, sm: 0, md: 4, lg: 4, xl: 4 }}}
        >
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              id="basic-button-1"
              variant="outlined"
              aria-haspopup="true"
              size="large"
              sx={{
                border: "1px solid #FFFFFF",
                backgroundColor:
                  selectedModel === "model_1" ? "#FFFFFF" : "transparent",
                color: selectedModel === "model_1" ? "#011636" : "#FFFFFF",
                fontSize: "16px",
                alignItems: "center",
                textAlign: "center",
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  borderColor: "#FFFFFF",
                  color: "#011636",
                  opacity: 0.9,
                },
                "&.Mui-disabled": {
                  backgroundColor:
                    selectedModel === "model_2" ? "#FFFFFF" : "transparent",
                  color: selectedModel === "model_2" ? "#011636" : "#FFFFFF",
                  borderColor: "#FFFFFF",
                  opacity: 1,
                  cursor: "not-allowed",
                },
              }}
              onClick={handleModelSelect}
              disabled={selectedModel === "model_4"}
            >
              Image Model 1
            </Button>
            <Typography
              style={{
                color: "#FFFFFF",
                textAlign: "center",
                fontSize: "12px",
                marginTop: "8px",
              }}
            >
              Super fast but Less Detailed
            </Typography>
          </div>

          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              id="basic-button-2"
              variant="outlined"
              aria-haspopup="true"
              size="large"
              sx={{
                border: "1px solid #FFFFFF",
                backgroundColor:
                  selectedModel === "model_2" ? "#FFFFFF" : "transparent",
                color: selectedModel === "model_2" ? "#011636" : "#FFFFFF",
                fontSize: "16px",
                alignItems: "center",
                textAlign: "center",
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  borderColor: "#FFFFFF",
                  color: "#011636",
                  opacity: 0.9,
                },
                "&.Mui-disabled": {
                  backgroundColor:
                    selectedModel === "model_2" ? "#FFFFFF" : "transparent",
                  color: selectedModel === "model_2" ? "#011636" : "#FFFFFF",
                  borderColor: "#FFFFFF",
                  opacity: 1,
                  cursor: "not-allowed",
                },
              }}
              onClick={handleModelSelect}
              disabled={selectedModel === "model_4"}
            >
              Image model 2
            </Button>
            <Typography
              style={{
                color: "#FFFFFF",
                textAlign: "center",
                fontSize: "12px",
                marginTop: "8px",
              }}
            >
              Slower but More Detailed
            </Typography>
          </div>

          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              id="basic-button-3"
              variant="outlined"
              aria-haspopup="true"
              size="large"
              sx={{
                border: "1px solid #FFFFFF",
                backgroundColor:
                  selectedModel === "model_3" ? "#FFFFFF" : "transparent",
                color: selectedModel === "model_3" ? "#011636" : "#FFFFFF",
                fontSize: "16px",
                alignItems: "center",
                textAlign: "center",
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  borderColor: "#FFFFFF",
                  color: "#011636",
                  opacity: 0.9,
                },
                "&:disabled": {
                  cursor: "not-allowed",
                },
                "&.Mui-disabled": {
                  backgroundColor:
                    selectedModel === "model_2" ? "#FFFFFF" : "transparent",
                  color: selectedModel === "model_2" ? "#011636" : "#FFFFFF",
                  borderColor: "#FFFFFF",
                  opacity: 1,
                  cursor: "not-allowed",
                },
              }}
              disabled={images.length > 0}
              onClick={handleModelSelect}
            >
              Image model 3
            </Button>
            <Typography
              style={{
                color: "#FFFFFF",
                textAlign: "center",
                fontSize: "12px",
                marginTop: "8px",
              }}
            >
              Image with embedded text & dialogues
            </Typography>
          </div>
        </Stack>
      </div>

      {/* Image edit */}
      <>
        <Divider
          variant="middle"
          sx={{ background: "#FFFFFF", my: 4, color: "#011636" }}
        >
          AI Image Editing
        </Divider>
        <Box
          sx={{
            display: "flex",
            gap: { xs: "5px", sm: "10px", md: "5%" },
            marginLeft: { xs: "0", sm: "5px", md: "15px", lg: "15px", xl: "15px" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={removeBackground}
                onChange={(e) => handleRemoveBackground(e.target.checked)}
                sx={{
                  color: "#FFFFFF",
                  "&.Mui-checked": {
                    color: "#FFFFFF",
                  },
                }}
              />
            }
            label={
              <Typography
                variant="h4"
                sx={{
                  color: "#FFFFFF",
                  xs: "0",
                  sm: "0",
                  md: "0",
                  lg: "16px",
                  xl: "16px",
                }}
              >
                Remove Background
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={outpaint}
                onChange={(e) => handleOutpaint(e.target.checked)}
                sx={{
                  color: "#FFFFFF",
                  "&.Mui-checked": {
                    color: "#FFFFFF",
                  },
                }}
              />
            }
            label={
              <Typography
                variant="h4"
                sx={{
                  color: "#FFFFFF",
                  xs: "0",
                  sm: "0",
                  md: "0",
                  lg: "16px",
                  xl: "16px",
                }}
              >
                Outpaint Image
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={creativeUpscaleFlag}
                onChange={(e) => handleCreativeUpscaleMode(e.target.checked)}
                sx={{
                  color: "#FFFFFF",
                  "&.Mui-checked": {
                    color: "#FFFFFF",
                  },
                }}
              />
            }
            label={
              <Typography
                variant="h4"
                sx={{
                  color: "#FFFFFF",
                  xs: "0",
                  sm: "0",
                  md: "0",
                  lg: "16px",
                  xl: "16px",
                }}
              >
                Creative Upscale
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={conservativeUpscaleFlag}
                onChange={(e) =>
                  handleConservativeUpscaleMode(e.target.checked)
                }
                sx={{
                  color: "#FFFFFF",
                  "&.Mui-checked": {
                    color: "#FFFFFF",
                  },
                }}
              />
            }
            label={
              <Typography
                variant="h4"
                sx={{
                  color: "#FFFFFF",
                  xs: "0",
                  sm: "0",
                  md: "0",
                  lg: "16px",
                  xl: "16px",
                }}
              >
                Conservative Upscale
              </Typography>
            }
          />
        </Box>
        {outpaint && (
          <>
            <div
              style={{ display: "flex", gap: "10px", margin: "15px 15px 0" }}
            >
              <FormControl fullWidth>
                <Typography variant="h4">Top</Typography>
                <Slider
                  defaultValue={100}
                  step={10}
                  min={0}
                  max={2000}
                  valueLabelDisplay="auto"
                  onChange={(e, value) =>
                    handleOutpaintSliderChange("top", value)
                  }
                  sx={{
                    color: "#DBC55B",
                    "& .MuiSlider-thumb": {
                      color: "#FFFFFF",
                    },
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography variant="h4">Bottom</Typography>
                <Slider
                  defaultValue={100}
                  step={10}
                  min={0}
                  max={2000}
                  valueLabelDisplay="auto"
                  onChange={(e, value) =>
                    handleOutpaintSliderChange("bottom", value)
                  }
                  sx={{
                    color: "#DBC55B",
                    "& .MuiSlider-thumb": {
                      color: "#FFFFFF",
                    },
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography variant="h4">Left</Typography>
                <Slider
                  defaultValue={100}
                  step={10}
                  min={0}
                  max={2000}
                  valueLabelDisplay="auto"
                  onChange={(e, value) =>
                    handleOutpaintSliderChange("left", value)
                  }
                  sx={{
                    color: "#DBC55B",
                    "& .MuiSlider-thumb": {
                      color: "#FFFFFF",
                    },
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography variant="h4">Right</Typography>
                <Slider
                  defaultValue={100}
                  step={10}
                  min={0}
                  max={2000}
                  valueLabelDisplay="auto"
                  onChange={(e, value) =>
                    handleOutpaintSliderChange("right", value)
                  }
                  sx={{
                    color: "#DBC55B",
                    "& .MuiSlider-thumb": {
                      color: "#FFFFFF",
                    },
                  }}
                />
              </FormControl>
            </div>
          </>
        )}
      </>

      {/* Image settings */}
      {selectedModel && selectedModel !== "model_4" && (
        <div>
          {/* Prompt */}
          <ListItem
            sx={{
              display: "flex",
              alignItems: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "center",
                xl: "center",
              },
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "200px",
                  xl: "200px",
                },
                marginRight: {
                  xs: "0",
                  sm: "0",
                  md: "0",
                  lg: "16px",
                  xl: "16px",
                },
              }}
            >
              Prompt *
            </Typography>
            <FormControl fullWidth sx={{ flex: 1 }}>
              <TextField
                placeholder="5000 characters only..."
                InputLabelProps={{
                  style: { color: "#FFFFFF", fontSize: "18px" },
                }}
                InputProps={{ style: { color: "#FFFFFF", fontSize: "18px" } }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                      borderRadius: "5px",
                    },
                    color: "#FFFFFF",
                    fontSize: "18px",
                  },
                }}
                value={prompt}
                onChange={handlePrompt}
                multiline
                maxRows={10}
                inputProps={{ maxLength: 5000 }}
              />
            </FormControl>
          </ListItem>

          {/* Aspect Ratio */}
          <ListItem
            sx={{
              display: "flex",
              alignItems: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "center",
                xl: "center",
              },
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "200px",
                  xl: "200px",
                },
                marginRight: {
                  xs: "0",
                  sm: "0",
                  md: "0",
                  lg: "16px",
                  xl: "16px",
                },
              }}
            >
              Aspect Ratio
            </Typography>
            <FormControl fullWidth sx={{ flex: 1 }}>
              {/* <Select value={aspectRatio} onChange={handleImageAspectRatio}>
                    {imageAspectRatios.map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select> */}
              <Autocomplete
                value={aspectRatio}
                onChange={handleImageAspectRatio}
                options={imageAspectRatios}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search..."
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                        </InputAdornment>
                      ),
                      style: {
                        color: "white",
                        fontSize: "2rem", // Increase the font size of the input text
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white",
                        fontSize: "2rem", // Increase the font size of the placeholder text
                      },
                    }}
                  />
                )}
                ListboxProps={{
                  sx: {
                    "& .MuiAutocomplete-option": {
                      fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                      color: "black", // Set text color for the options
                      "&[aria-selected='true']": {
                        backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                      },
                      "&:hover": {
                        backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                      },
                    },
                  },
                }}
              />
            </FormControl>
          </ListItem>

          {/* Character Retention */}
          <ListItem
            sx={{
              display: "flex",
              alignItems: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "center",
                xl: "center",
              },
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              },
            }}
          >
            {selectedModel === "model_2" && (
              <Typography
                variant="h4"
                sx={{
                  width: "100%",
                  marginRight: 0,
                }}
              >
                <Checkbox
                  checked={characterRetention}
                  onChange={handleCharacterRetention}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                Retain Character
              </Typography>
            )}
          </ListItem>

          {/* Compositional Styles */}
          <Divider
            variant="middle"
            sx={{ background: "#FFFFFF", my: 4, color: "#011636" }}
          >
            Compositional Styles
          </Divider>

          {/* Seed value */}
          <ListItem
            sx={{
              display: "flex",
              alignItems: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "center",
                xl: "center",
              },
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "200px",
                  xl: "200px",
                },
                marginRight: {
                  xs: "0",
                  sm: "0",
                  md: "0",
                  lg: "16px",
                  xl: "16px",
                },
              }}
            >
              Seed value
            </Typography>
            <Slider
              value={seedValue}
              onChange={handleSeedValue}
              aria-labelledby="volume-slider"
              valueLabelDisplay="auto"
              min={0}
              max={500}
              sx={{ flex: 1, color: "#FFFFFF", marginRight: "16px" }}
            />
          </ListItem>

          {/* Compositional Styles Checkbox */}
          <ListItem
            sx={{
              display: "flex",
              alignItems: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "center",
                xl: "center",
              },
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "200px",
                  xl: "200px",
                },
                marginRight: {
                  xs: "0",
                  sm: "0",
                  md: "0",
                  lg: "16px",
                  xl: "16px",
                },
              }}
            >
              <Checkbox
                checked={checked}
                onChange={handleChangeCheckbox}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              Compositional Styles
            </Typography>

            <FormControl fullWidth sx={{ flex: 1 }}>
              {/* <Select value={presetStyle} onChange={handleChangeSelect}>
                    {(checked ? stylesForChecked : stylesForUnchecked).map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select> */}
              <Autocomplete
                value={presetStyle}
                onChange={handleChangeSelect}
                options={checked ? stylesForChecked : stylesForUnchecked}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search..."
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                        </InputAdornment>
                      ),
                      style: {
                        color: "white",
                        fontSize: "2rem", // Increase the font size of the input text
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white",
                        fontSize: "2rem", // Increase the font size of the placeholder text
                      },
                    }}
                  />
                )}
                ListboxProps={{
                  sx: {
                    "& .MuiAutocomplete-option": {
                      fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                      color: "black", // Set text color for the options
                      "&[aria-selected='true']": {
                        backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                      },
                      "&:hover": {
                        backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                      },
                    },
                  },
                }}
              />
            </FormControl>
          </ListItem>

          {/* Image Settings */}
          <Divider
            variant="middle"
            sx={{ background: "#FFFFFF", my: 4, color: "#011636" }}
          >
            Image Settings
          </Divider>

          {/* Image Art Style */}
          <ListItem
            sx={{
              display: "flex",
              alignItems: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "center",
                xl: "center",
              },
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "200px",
                  xl: "200px",
                },
                marginRight: {
                  xs: "0",
                  sm: "0",
                  md: "0",
                  lg: "16px",
                  xl: "16px",
                },
              }}
            >
              Image Art Style
            </Typography>
            <FormControl fullWidth sx={{ flex: 1 }}>
              {/* <Select value={imageArtStyle} onChange={handleImageArtStyle}>
                    {imageArtStyles.map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select> */}
              <Autocomplete
                value={imageArtStyle}
                onChange={handleImageArtStyle}
                options={imageArtStyles}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search..."
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                        </InputAdornment>
                      ),
                      style: {
                        color: "white",
                        fontSize: "2rem", // Increase the font size of the input text
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white",
                        fontSize: "2rem", // Increase the font size of the placeholder text
                      },
                    }}
                  />
                )}
                ListboxProps={{
                  sx: {
                    "& .MuiAutocomplete-option": {
                      fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                      color: "black", // Set text color for the options
                      "&[aria-selected='true']": {
                        backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                      },
                      "&:hover": {
                        backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                      },
                    },
                  },
                }}
              />
            </FormControl>
          </ListItem>

          {/* Image Tone */}
          <ListItem
            sx={{
              display: "flex",
              alignItems: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "center",
                xl: "center",
              },
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "200px",
                  xl: "200px",
                },
                marginRight: {
                  xs: "0",
                  sm: "0",
                  md: "0",
                  lg: "16px",
                  xl: "16px",
                },
              }}
            >
              Image Tone
            </Typography>
            <FormControl fullWidth sx={{ flex: 1 }}>
              {/* <Select value={imageTone} onChange={handleImageTone}>
                    {tones.map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select> */}
              <Autocomplete
                value={imageTone}
                onChange={handleImageTone}
                options={tones}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search..."
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                        </InputAdornment>
                      ),
                      style: {
                        color: "white",
                        fontSize: "2rem", // Increase the font size of the input text
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white",
                        fontSize: "2rem", // Increase the font size of the placeholder text
                      },
                    }}
                  />
                )}
                ListboxProps={{
                  sx: {
                    "& .MuiAutocomplete-option": {
                      fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                      color: "black", // Set text color for the options
                      "&[aria-selected='true']": {
                        backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                      },
                      "&:hover": {
                        backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                      },
                    },
                  },
                }}
              />
            </FormControl>
          </ListItem>

          {/* Color Scheme */}
          <ListItem
            sx={{
              display: "flex",
              alignItems: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "center",
                xl: "center",
              },
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "200px",
                  xl: "200px",
                },
                marginRight: {
                  xs: "0",
                  sm: "0",
                  md: "0",
                  lg: "16px",
                  xl: "16px",
                },
              }}
            >
              Color Scheme
            </Typography>
            <FormControl fullWidth sx={{ flex: 1 }}>
              {/* <Select value={colorScheme} onChange={handleColorScheme}>
                    {colorSchemes.map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select> */}
              <Autocomplete
                value={colorScheme}
                onChange={handleColorScheme}
                options={colorSchemes}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search..."
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                        </InputAdornment>
                      ),
                      style: {
                        color: "white",
                        fontSize: "2rem", // Increase the font size of the input text
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white",
                        fontSize: "2rem", // Increase the font size of the placeholder text
                      },
                    }}
                  />
                )}
                ListboxProps={{
                  sx: {
                    "& .MuiAutocomplete-option": {
                      fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                      color: "black", // Set text color for the options
                      "&[aria-selected='true']": {
                        backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                      },
                      "&:hover": {
                        backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                      },
                    },
                  },
                }}
              />
            </FormControl>
          </ListItem>
        </div>
      )}

      {selectedModel && selectedModel === "model_3" && (
        <ListItem
        sx={{
          display: "flex",
          alignItems: {
            xs: "flex-start",
            sm: "flex-start",
            md: "flex-start",
            lg: "center",
            xl: "center",
          },
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "200px",
              xl: "200px",
            },
            marginRight: {
              xs: "0",
              sm: "0",
              md: "0",
              lg: "16px",
              xl: "16px",
            },
          }}
        >
          Text Style
        </Typography>
        <FormControl fullWidth sx={{ flex: 1 }}>
          <Autocomplete
            value={textStyle}
            onChange={handleTextStyle}
            options={textStyles}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search..."
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                    </InputAdornment>
                  ),
                  style: {
                    color: "white",
                    fontSize: "2rem", // Increase the font size of the input text
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "white",
                    fontSize: "2rem", // Increase the font size of the placeholder text
                  },
                }}
              />
            )}
            ListboxProps={{
              sx: {
                "& .MuiAutocomplete-option": {
                  fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                  color: "black", // Set text color for the options
                  "&[aria-selected='true']": {
                    backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                  },
                  "&:hover": {
                    backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                  },
                },
              },
            }}
          />
        </FormControl>
      </ListItem>
      )}

      {/* Illustrate Button */}
      {selectedModel && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-around"
          flexWrap="wrap"
          spacing={{ xs: 1, sm: 1, md: 2 }}
          useFlexGap
        >
          <Button
            id="basic-button"
            variant="outlined"
            aria-haspopup="true"
            size="large"
            style={{
              border: "1px solid #FFFFFF",
              backgroundColor: "transparent",
              color: "#FFFFFF",
              fontSize: "16px",
              marginTop: "16px",
              borderRadius: "20px",
            "&:hover": {  
              backgroundColor: "white",
              color: "#011636",
            },
            }}
            onClick={onSubmit}
          >
            Illustrate
          </Button>
        </Stack>
      )}
    </div>
  );
};

export default ImageSettingsDrawer;
