import React from "react";
import { Box, Autocomplete, TextField } from "@mui/material";
import { useAuth } from "hooks/User";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

// Extracted names from images
const personas = [
  "Art Writer",
  "Fashion Designer",
  "Industrial Designer",
  "Marine Biologist",
  "Cinematographer",
  "Aeronautical Engineer",
  "Journalist",
  "Medical Writer",
  "Documentary Filmmaker",
  "Nature Writer",
  "Fashion Writer",
  "Anthropologist",
  "Political Writer",
  "Oceanographer",
  "Travel Writer",
  "Broadcast Journalist",
  "War Correspondent",
  "Columnist",
  "Meteorologist",
  "Cartographer",
  "Film Critic",
  "Art Critic",
  "Demographer",
  "Mathematician",
  "Laboratory Technician",
  "Comic Book Writer",
  "Physical Therapist",
  "Fantasy Author",
  "Dermatologist",
  "Neurologist",
  "Biographer",
  "Writer",
  "Public Health Specialist",
  "Librarian",
  "Nuclear Physicist",
  "Psychologist",
  "Photographer",
  "Science Educator",
  "Literary Agent",
  "Romance Writer",
  "Political Scientist",
  "Climatologist",
  "Copywriter",
  "Young Adult Author",
  "Pathologist",
  "Novelist",
  "Ghostwriter",
  "Zoologist",
  "Thriller Writer",
  "Adventure Writer",
  "Art Historian",
  "Dentist",
  "Human Resources Specialist",
  "Pharmacist",
  "Editor",
  "Speechwriter",
  "Singer",
  "Physicist",
  "Surgeon",
  "Health Educator",
  "Paleontologist",
  "Biologist",
  "Oncologist",
  "Psychiatrist",
  "Astronomer",
  "Travelogue Writer",
  "Photojournalist",
  "Business Writer",
  "Comedy Writer",
  "Music Therapist",
  "Game Designer",
  "Political Analyst",
  "Public Relations Specialist",
  "Graphic Novelist",
  "Cultural Studies Scholar",
  "Architect",
  "Veterinarian",
  "Theatre Director",
  "Chemist",
  "Sociolinguist",
  "Forensic Scientist",
  "Religious Writer",
  "Publisher",
  "Mystery Writer",
  "Sculptor",
  "Economist",
  "Video Game Writer",
  "Self Help Writer",
  "Sports Writer",
  "Nutritionist",
  "Essayist",
  "Botanist",
  "Astrophysicist",
  "Immunologist",
  "Philosopher",
  "Legal Writer",
  "Research Scientist",
  "Technical Writer",
  "Illustrator",
  "Toxicologist",
  "Speech Therapist",
  "Wildlife Biologist",
  "Music Writer",
  "Poet",
  "Education Consultant",
  "Archaeologist",
  "Medical Illustrator",
  "Geneticist",
  "Performance Artist",
  "Environmental Scientist",
  "Screenwriter",
  "Philosophical Writer",
  "Business Analyst",
  "Radio Producer",
  "Sociologist",
  "Food Critic",
  "Urban Planner",
  "Food Writer",
  "Music Critic",
  "Literary Critic",
  "Dramaturg",
  "Legal Analyst",
  "Neuroscientist",
  "Statistician",
  "Academic Writer",
  "Ethnographer",
  "Physician",
  "Playwright",
  "Software Developer",
  "Geographer",
  "Microbiologist",
  "Horticulturist",
  "Data Scientist",
  "Musicologist",
  "Science Fiction Author",
  "Historian",
  "Marketing Specialist",
  "Geologist",
  "Horror Writer",
  "Epidemiologist",
];

const PersonaSelector = () => {
  const user = useAuth();
  const handleImageClick = (event, newValue) => {
    if (newValue) {
      user.setPersona(newValue); // Store the selected word in user.setPersona
    }
  };
  return (
    <Box>
      <Autocomplete
        options={personas}
        value={user.selectedPersonaFromTrain}
        onChange={handleImageClick}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select Persona"
            sx={{
              bgcolor: "rgba(255, 255, 255, 0.1)",
              borderRadius: "5px",
              "& .MuiInputBase-input": { color: "white", fontSize: "1.2rem" }, // Set the input text color and size
              "& .MuiInputLabel-root": { color: "white", fontSize: "1.2rem" }, // Set the label (placeholder) color and size
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(255, 255, 255, 0.3)",
              }, // Outline color
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "rgba(255, 255, 255, 0.5)" }} />
                </InputAdornment>
              ),
            }}
          />
        )}
        sx={{
          width: "100%",
          "& .MuiAutocomplete-option": { color: "white", fontSize: "1.2rem" }, // Set the options text color and size
          "& .MuiAutocomplete-listbox": {
            bgcolor: "rgba(255, 255, 255, 0.1)", // Optional: background for the dropdown list
          },
        }}
        ListboxProps={{
          sx: {
            "& .MuiAutocomplete-option": {
              fontSize: "1.5rem", // Increase the font size of the options in the dropdown
              color: "black", // Set text color for the options
              "&[aria-selected='true']": {
                backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
              },
              "&:hover": {
                backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
              },
            },
          },
        }}
      />
    </Box>
  );
};

export default PersonaSelector;
