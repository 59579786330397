import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import PersonIcon from "@mui/icons-material/Person";

import Typography from "@mui/material/Typography";
import Logo from "assets/images/Logo.png";

import "./AppBar.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, IconButton } from "@mui/material";

const TopAppBar = ({ toggleSideDrawer }) => {
  return (
    <>
      <AppBar
        position="static"
        className="appBar"
        sx={{
          background: "rgba(255, 255, 255, 0.1)", // More transparent background for glass effect
          backdropFilter: "blur(10px)", // Apply blur effect
          WebkitBackdropFilter: "blur(10px)", // Apply blur effect for Safari
          // border: "1px solid rgba(255, 255, 255, 0.2)", // Subtle border for glass effect
          boxShadow: "none", // Remove default box shadow
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
        <Box sx={{  zIndex: 1200 }}>
        <Link to="/dashboard">
          <IconButton aria-label="back">
            <ArrowBackIcon style={{ width: "2em", height: "2em", color: "white" }} />
          </IconButton>
        </Link>
      </Box>
          <img src={Logo} alt="image" className="logoImage" />
          <Typography variant="h4" component="div" sx={{ color: "white", flexGrow: 1 }}>
            Compositional AI
          </Typography>
          <IconButton
            aria-label="draw"
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 28 },
              color: "white",
              cursor: "pointer",
            }}
            onClick={toggleSideDrawer}
          >
            <PersonIcon />
          </IconButton>
        </Stack>
      </AppBar>
    </>
  );
};

export default TopAppBar;
