import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const getQueryParam = (param) => {
    const value = queryParams.get(param);
    return value;
  };

  return getQueryParam;
};
