import { useEffect, useState } from "react";
import Narrative from "assets/images/Narrative.gif";
import Generative from "assets/images/Generative.gif";
import Compositional from "assets/images/Compositional.gif";
import "views/Main/Main.css";

const Loader = () => {
  const gifs = [Narrative, Generative, Compositional];
  const [currentGif, setCurrentGif] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentGif((prevGif) => (prevGif + 1) % gifs.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <img src={gifs[currentGif]} alt="Loading..." style={{width: "100%", height: "300px"}} />
    </div>
  );
};

export default Loader;
