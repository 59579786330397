import { forwardRef } from 'react';
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const CustomSnackbar = ({
  snackbarOpen,
  setSnackbarOpen,
  snackbarSeverity,
  snackbarContent,
}) => {
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        sx={{ width: "100%" }}
      >
        <span style={{ fontSize: "18px" }}>{snackbarContent}</span>
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
