import React, { createContext, useState, useContext } from "react";

// Create the context
export const UploadSourceContext = createContext();

// Create a custom hook to access the context
export const useUploadSource = () => useContext(UploadSourceContext);

// Create a context provider component
export const UploadSourceProvider = ({ children }) => {
  const [uploadSource, setUploadSource] = useState("default"); // Initial value can be anything you want

  

  return (
    <UploadSourceContext.Provider value={{ uploadSource, setUploadSource }}>
      {children}
    </UploadSourceContext.Provider>
  );
};