import React, { useEffect, useState } from 'react';
import SpinnerLoader from 'assets/loader.gif';
import './Loader.css';

const Loader = ({ expanded }) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage((prev) => {
        if (prev < 98) {
          return prev + 1;
        } else {
          clearInterval(interval);
          return prev;
        }
      });
    }, 500); 
    
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={expanded ? `loader-container-expand` : `loader-container`}>
      <img src={SpinnerLoader} alt="Loading..." className={expanded ? `loader-expand` : `loader-normal`} />
      <div className={expanded ? `percentage-expand` : `percentage-normal`}>{percentage}%</div>
    </div>
  );
};

export default Loader;