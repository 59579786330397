import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: 'white', 
            '&.Mui-checked': {
              color: '#011636', 
            },
            '& .MuiSvgIcon-root': {
              fontSize: '2rem', 
            }
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            color: "white",
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "white",
            color: "white",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            border: "1px solid white",
            color: "#011636",
            backgroundColor: "linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(129.36, 129.36, 129.36, 0.12) 49%, rgba(255, 255, 255, 0.12) 100%)",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          paper: {
            border: "1px solid white",
            color: "#011636",
            backgroundColor: "linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(129.36, 129.36, 129.36, 0.12) 49%, rgba(255, 255, 255, 0.12) 100%)",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            margin: "5px 0",
          },
        },
      },
    },
  });

export default theme;
