import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import "./testing.css";
import BackgroundAnimation from "components/Background/BackgroundAnimations";
import { useMediaQuery, Button, Box, } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AuthContext } from "context/User";
import { generateUniqueNumber } from "utils/helper";
import { useAuth } from "hooks/User";

const StyledButton = styled(Button)(({ theme }) => ({
  left: "0vw",
            top: "85vh",
  background: 'rgba(255, 255, 255, 0)',
  color: 'white',
  border: '2px solid rgba(255, 255, 255, 0.3)',
  borderRadius: '3vh',
  padding: '5px 10px',
  width: '150px',
  fontSize: '3vh', // Adjust the font size as needed
  transition: 'background 0.3s ease, border-color 0.3s ease',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
    borderColor: 'rgba(255, 255, 255, 0.5)',
  },
}));

const IntroScreen = () => {
  let auth = useAuth();
  const { user } = useContext(AuthContext);
  const chatId = user.userId + "t" + generateUniqueNumber();  

  useEffect(() => {
    const debounceNavigate = debounce(() => {
      console.log("Redirecting to dashboard");
    }, 1000);

    window.receiveToken = function (data, headers) {
      // console.log(data);
      // console.log(headers);
      const version = headers["Version"] || "";
      if (version === process.env.REACT_APP_MAPP_VERSION) {
        // console.log("domain setting");
        localStorage.setItem("user", JSON.stringify(data));
        debounceNavigate();
      } else {
        console.error("Version mismatch:", version);
      }
    };

    function debounce(func, timeout = 300) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    }
  }, [auth, window.receiveToken]);

  const handleHover = (isHovering) => {
    const event = new CustomEvent("animateGradient", { detail: isHovering });
    window.dispatchEvent(event);
  };
  

  const isSmallScreen = useMediaQuery(
    "(max-width: 1200px) and (orientation: portrait)"
  );
  const isShortPhone = useMediaQuery(
    "(max-width: 768px) and (max-height: 730px)"
  );
  const isLandscape = useMediaQuery(
    "(max-width: 1000px) and (orientation: landscape)"
  );


  return (
    <BackgroundAnimation>
     {!isSmallScreen && (
      <>
       <Box sx={{ textAlign: "center", mb: 4 }}>
        <img src="Logo.png" alt="Logo" className="logo" />
      </Box>
      <div className="container">
        <div 
          className="glass-box" 
          style={{
            width: "40vw",
            height: "45vh", // Using vh for height for better scaling
            left: "5vw",
            top: "30vh",
            position: "absolute",
            background: "linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(129, 129, 129, 0.12) 49%, rgba(255, 255, 255, 0.12) 100%)",
            boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.10) inset",
            borderRadius: "4vw", // Use vw for border-radius
            backdropFilter: "blur(10vh)" // Adjust blur with vh
          }}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
        >
          <div 
            style={{
              left: "17vw",
              top: "8vh",
              // position: "absolute",
              textAlign: "center",
              color: "white",
              fontSize: "3vw", // Use vw for font size
              fontFamily: "Inter",
              fontWeight: "600",
              pointerEvents: "none",
              wordWrap: "break-word"
            }}
          >
            Train
          </div>
          <div 
            style={{
              left: "8vw",
              top: "17vh",
              // position: "absolute",
              textAlign: "center",
              color: "white",
              fontSize: "1.5vw", // Use vw for font size
              fontFamily: "Inter",
              fontWeight: "300",
              pointerEvents: "none",
              wordWrap: "break-word",
              marginTop: "4vh",
              marginBottom: "2vh"
            }}
          >
            Custom-train your Compositional Copilot by dragging and dropping documents.
          </div>
          <div 
            style={{
              width: "38vw",
              height: "16vh",
              // position: "absolute",
              left: "7vw",
              top: "38vh",
              backgroundColor: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontSize: "1.5vw", // Use vw for font size
              fontFamily: "Inter",
              fontWeight: "500",
              wordWrap: "break-word"
            }}
          >
            <Link to={"/train"}>
              <button className="next-button">Next</button>
            </Link>
          </div>
        </div>

        <div 
          className="glass-box-2" 
          style={{
            width: "40vw",
            height: "45vh", // Using vh for height for better scaling
            left: "55vw",
            top: "30vh",
            position: "absolute",
            background: "linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(129, 129, 129, 0.12) 49%, rgba(255, 255, 255, 0.12) 100%)",
            boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.10) inset",
            borderRadius: "4vw", // Use vw for border-radius
            backdropFilter: "blur(10vh)" // Adjust blur with vh
          }}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
        >
          <div 
            style={{
              left: "17vw",
              top: "8vh",
              // position: "absolute",
              textAlign: "center",
              color: "white",
              fontSize: "3vw", // Use vw for font size
              fontFamily: "Inter",
              fontWeight: "600",
              pointerEvents: "none",
              wordWrap: "break-word"
            }}
          >
            Compose
          </div>
          <div 
            style={{
              left: "8vw",
              top: "17vh",
              // position: "absolute",
              textAlign: "center",
              color: "white",
              fontSize: "1.5vw", // Use vw for font size
              fontFamily: "Inter",
              fontWeight: "300",
              pointerEvents: "none",
              wordWrap: "break-word",
              marginTop: "4vh",
              marginBottom: "2vh"
            }}
          >
            Brainstorm, develop, compose, and publish with Compositional Copilot.
          </div>
          <div 
            style={{
              width: "38vw",
              height: "16vh",
              // position: "absolute",
              left: "7vw",
              top: "38vh",
              backgroundColor: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontSize: "1.5vw", // Use vw for font size
              fontFamily: "Inter",
              fontWeight: "500",
              wordWrap: "break-word"
            }}
          >
            <Link to={"/compose"}>
              <button className="next-button">Next</button>
            </Link>
          </div>
        </div>
      </div>
      <Box sx={{ mt: isLandscape ? "-10vh" : 1, textAlign: "center" }}>
        <Link to={`/c/${chatId}`}>
          <StyledButton variant="contained">Skip</StyledButton>
        </Link>
      </Box>
      </>
     )}
     {isSmallScreen && (
      <>
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <img src="Logo.png" alt="Logo" className="logo" />
      </Box>
      <div className="container">
      <div 
  className="glass-box" 
  style={{
    width: "90vw",
    height: "25vh",
    left: "5vw",
    top: "25vh",
    position: "absolute",
    background: "linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(129, 129, 129, 0.12) 49%, rgba(255, 255, 255, 0.12) 100%)",
    boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.10) inset",
    borderRadius: "4vw",
    backdropFilter: "blur(10vh)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2vh 0"
  }}
  onMouseEnter={() => handleHover(true)}
  onMouseLeave={() => handleHover(false)}
>
  <div 
    style={{
      textAlign: "center",
      color: "white",
      fontSize: "6vw",
      fontFamily: "Inter",
      fontWeight: "600",
      pointerEvents: "none",
      wordWrap: "break-word"
    }}
  >
    Train
  </div>
  <div 
    style={{
      textAlign: "center",
      color: "white",
      fontSize: "4vw",
      fontFamily: "Inter",
      fontWeight: "300",
      pointerEvents: "none",
      wordWrap: "break-word"
    }}
  >
    Custom-train your Compositional Copilot by dragging and dropping documents.
  </div>
  <div 
    style={{
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      fontSize: "3vh"
      
    }}
  >
    <Link to={"/train"}>
      <button className="next-button">Next</button>
    </Link>
  </div>
</div>


        <div 
  className="glass-box-2" 
  style={{
    width: "90vw",
    height: "25vh", 
    position: "absolute",
    left: "5vw",
    top: "55vh",
    background: "linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(129, 129, 129, 0.12) 49%, rgba(255, 255, 255, 0.12) 100%)",
    boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.10) inset",
    borderRadius: "4vw",
    backdropFilter: "blur(10vh)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2vh 0"
  }}
  onMouseEnter={() => handleHover(true)}
  onMouseLeave={() => handleHover(false)}
>
  <div 
    style={{
      textAlign: "center",
      color: "white",
      fontSize: "6vw",
      fontFamily: "Inter",
      fontWeight: "600",
      pointerEvents: "none",
      wordWrap: "break-word"
    }}
  >
    Compose
  </div>
  <div 
    style={{
      textAlign: "center",
      color: "white",
      fontSize: "4vw",
      fontFamily: "Inter",
      fontWeight: "300",
      pointerEvents: "none",
      wordWrap: "break-word"
    }}
  >
    Brainstorm, develop, compose, and publish <br/> with Compositional Copilot.
  </div>
  <div 
    style={{
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      fontSize: "3vh"
    }}
  >
    <Link to={"/compose"}>
      <button className="next-button">Next</button>
    </Link>
  </div>
</div>

      </div>
      <Box sx={{ mt: 1, textAlign: "center" }}>
        <Link to={`/c/${chatId}`}>
          <StyledButton variant="contained">Skip</StyledButton>
        </Link>
      </Box>
      </>
     )}
    </BackgroundAnimation>
  );
};

export default IntroScreen;
