export const allVoices = [
  "Rachel",
  "Domi",
  "Bella",
  "Antoni",
  "Thomas",
  "Emily",
  "Elli",
  "Liam",
  "Josh",
  "Arnold",
  "Matilda",
  "Matthew",
  "Jeremy",
  "Michael",
  "Adam",
  "Nicole",
  "Ryan",
  "Sam",
  "Giovanni",
];

export const genres = [
  "Adventure",
  "Comedy",
  "Thriller",
  "Fantasy",
  "Mystery",
  "Science Fiction",
  "Literary Fiction",
  "Historical Fiction",
  "Mystery/Thriller/Crime",
  "Horror",
  "Romance",
  "Dystopian",
  "Young Adult (YA)",
  "Children's Fiction",
  "Urban Fantasy",
  "Paranormal",
  "Magical Realism",
  "Cyberpunk",
  "Steampunk",
  "Space Opera",
  "Superhero Fiction",
  "Gothic Fiction",
  "Western",
  "Alternate History",
  "Dark Fantasy",
  "Epic Fantasy",
  "Sword and Sorcery",
  "Psychological Thriller",
  "Detective Fiction",
  "Fairy Tale",
  "Fan Fiction",
  "Biographical Fiction",
  "War Fiction",
  "Political Fiction",
  "Religious Fiction",
  "Satire",
  "Tragedy",
  "Coming-of-Age",
  "Bildungsroman",
  "Chick Lit",
  "LGBTQ+ Fiction",
  "New Adult",
  "Post-Apocalyptic",
  "Time Travel",
  "Vampire Fiction",
  "Werewolf Fiction",
  "Zombie Fiction",
  "Alien Fiction",
  "Medical Fiction",
  "Legal Thriller",
  "Techno-Thriller",
  "Slipstream Fiction",
  "Flash Fiction",
  "Noir Fiction",
  "Hardboiled Fiction",
  "Pulp Fiction",
  "Spy Fiction",
  "High Fantasy",
  "Low Fantasy",
  "Contemporary Fantasy",
  "Historical Mystery",
  "Cozy Mystery",
  "Police Procedural",
  "Capers & Heists",
  "Regency Romance",
  "Erotic Fiction",
  "Inspirational Fiction",
  "Classic Literature",
  "Experimental Fiction",
  "Feminist Fiction",
  "Environmental Fiction",
  "Existential Fiction",
  "Absurdist Fiction",
  "Surrealist Fiction",
  "Beat Generation Fiction",
  "Postmodern Fiction",
  "Transgressive Fiction",
  "Bizarro Fiction",
  "Metafiction",
  "Interactive Fiction",
  "GameLit",
  "LitRPG",
  "Military Science Fiction",
  "Hard Science Fiction",
  "Soft Science Fiction",
  "Space Western",
  "Planetary Romance",
  "Utopian Fiction",
  "Dying Earth",
  "Mannerpunk",
  "Mythpunk",
  "Hopepunk",
  "Solarpunk",
  "Dieselpunk",
  "Clockpunk",
  "New Weird",
  "Weird West",
  "Grimdark",
  "Noblebright",
  "Body Horror",
  "Psychological Horror",
  "Ghost Story",
  "Fantasy Fiction",
];

export const tones = [
  "Exciting",
  "Chilling",
  "Romantic",
  "Enthralling",
  "Enchanting",
  "Fun-filled",
  "Mystical",
  "Suspenseful",
  "Tragic",
  "Comedic",
  "Whimsical",
  "Nostalgic",
  "Melancholic",
  "Uplifting",
  "Inspirational",
  "Thought-provoking",
  "Reflective",
  "Poignant",
  "Energetic",
  "Serene",
  "Dreamlike",
  "Nightmarish",
  "Adventurous",
  "Heroic",
  "Villainous",
  "Mysterious",
  "Intriguing",
  "Playful",
  "Youthful",
  "Mature",
  "Sophisticated",
  "Simple",
  "Minimalistic",
  "Grandiose",
  "Epic",
  "Intimate",
  "Personal",
  "Objective",
  "Subjective",
  "Critical",
  "Supportive",
  "Sympathetic",
  "Empathetic",
  "Detached",
  "Involved",
  "Angry",
  "Joyful",
  "Sad",
  "Happy",
  "Frightening",
  "Soothing",
  "Rebellious",
  "Conventional",
  "Modern",
  "Futuristic",
  "Historical",
  "Realistic",
  "Fantastical",
  "Surreal",
  "Absurd",
  "Logical",
  "Illogical",
  "Clear",
  "Ambiguous",
  "Direct",
  "Indirect",
  "Formal",
  "Informal",
  "Scholarly",
  "Optimistic",
  "Pessimistic",
  "Hopeful",
  "Despairing",
  "Loving",
  "Accepting",
  "Judgmental",
  "Religious",
  "Secular",
  "Spiritual",
  "Materialistic",
  "Naturalistic",
  "Gentle",
  "Harsh",
  "Loud",
  "Quiet",
  "Fast-paced",
  "Slow-paced",
  "Chaotic",
  "Orderly",
  "Masculine",
  "Feminine",
  "Androgynous",
  "Traditional",
  "Innovative",
  "Timeless",
  "Courageous",
  "Timid",
  "Respectful",
  "Disrespectful",
  "Compassionate",
];

export const writing_styles = [
  "Descriptive Writing",
  "Expository Writing",
  "Narrative Writing",
  "Persuasive Writing",
  "Stream of Consciousness",
  "Epistolary Writing",
  "Argumentative Writing",
  "Analytical Writing",
  "Journalistic Writing",
  "Poetic Writing",
  "Conversational Writing",
  "Technical Writing",
  "Script Writing",
  "Satirical Writing",
  "Gothic Writing",
  "Allegorical Writing",
  "Didactic Writing",
  "Flash Fiction Writing",
  "Surrealist Writing",
  "Postmodern Writing",
  "Realistic Writing",
  "Romantic Writing",
  "Tragicomic Writing",
  "Minimalist Writing",
  "Maximalist Writing",
  "Parody Writing",
  "Ironical Writing",
  "Metafictional Writing",
  "Autobiographical Writing",
  "Biographical Writing",
  "Travel Writing",
  "Science Writing",
  "Fantasy Writing",
  "Science Fiction Writing",
  "Historical Writing",
  "Mystery Writing",
  "Thriller Writing",
  "Horror Writing",
  "Adventure Writing",
  "Western Writing",
  "Cyberpunk Writing",
  "Steampunk Writing",
  "Dystopian Writing",
  "Utopian Writing",
  "Noir Writing",
  "Hardboiled Writing",
  "Academic Writing",
  "Business Writing",
  "Legal Writing",
  "Medical Writing",
  "Environmental Writing",
  "Philosophical Writing",
  "Theological Writing",
  "Inspirational Writing",
  "Humorous Writing",
  "Dark Comedy Writing",
  "Absurdist Writing",
  "Experimental Writing",
  "Speculative Writing",
  "Mythopoeic Writing",
  "Fairy Tale Writing",
  "Fable Writing",
  "Parable Writing",
  "Anecdotal Writing",
  "Confessional Writing",
  "Diary Writing",
  "Memoir Writing",
  "Reportage Writing",
  "Review Writing",
  "Criticism Writing",
  "Rhetorical Writing",
  "Speech Writing",
  "Dialogue Writing",
  "Monologue Writing",
  "Soliloquy Writing",
  "Letter Writing",
  "Email Writing",
  "Text Message Writing",
  "Social Media Writing",
  "Blog Writing",
  "Vignette Writing",
  "Listicle Writing",
  "How-to Writing",
  "Guide Writing",
  "FAQ Writing",
  "Interview Writing",
  "Profile Writing",
  "Case Study Writing",
  "Research Writing",
  "Survey Writing",
  "Opinion Writing",
  "Editorial Writing",
  "Column Writing",
  "Feature Writing",
  "News Writing",
  "Investigative Writing",
  "Interpretive Writing",
  "Review Writing",
  "Comparative Writing",
  "Synthesis Writing",
];

export const personalities = [
  "Oliver Wendell Holmes Jr.",
  "Joseph Kinnicutt Angell",
  "Erle Stanley Gardner",
  "Scott Turow",
  "John Emory Powers",
  "William-Shakespeare",
  "George-Orwell",
  "Ernest-Hemingway",
  "J.K.-Rowling",
  "Stephen-King",
  "Jane-Austen",
  "Mark-Twain",
  "Virginia-Woolf",
  "F.-Scott-Fitzgerald",
  "Agatha-Christie",
  "J.R.R.-Tolkien",
  "Charles-Dickens",
  "Emily-Dickinson",
  "Edgar-Allan-Poe",
  "Leo-Tolstoy",
  "Gabriel-García-Márquez",
  "Haruki-Murakami",
  "Maya-Angelou",
  "Margaret-Atwood",
  "J.D.-Salinger",
  "James-Joyce",
  "Oscar-Wilde",
  "H.P.-Lovecraft",
  "George-R.R.-Martin",
  "Neil-Gaiman",
  "Roald-Dahl",
  "Arthur-Conan-Doyle",
  "Isaac-Asimov",
  "Ray-Bradbury",
  "Toni-Morrison",
  "Alice-Walker",
  "John-Steinbeck",
  "Kurt-Vonnegut",
  "Ernest-Hemingway",
  "J.D.-Salinger",
  "Herman-Melville",
  "Fyodor-Dostoevsky",
  "Franz-Kafka",
  "Thomas-Hardy",
  "Henry-David-Thoreau",
  "Walt-Whitman",
  "Langston-Hughes",
  "Sylvia-Plath",
  "Gloria-Steinem",
  "Hunter-S.-Thompson",
  "Tom-Wolfe",
  "Joan-Didion",
  "Malcolm-Gladwell",
  "Ta-Nehisi-Coates",
  "Anne-Lamott",
  "Chimamanda-Ngozi-Adichie",
  "Zadie-Smith",
  "Salman-Rushdie",
  "Don-DeLillo",
  "Philip-Roth",
  "Cormac-McCarthy",
  "Jhumpa-Lahiri",
  "Harper-Lee",
  "Amy-Tan",
  "Nicholas-Sparks",
  "Michael-Lewis",
  "Mary-Shelley",
  "Nathaniel-Hawthorne",
  "Louisa-May-Alcott",
  "Charlotte-Brontë",
  "Emily-Brontë",
  "James-Baldwin",
  "Ralph-Waldo-Emerson",
  "Henry-James",
  "Jack-London",
  "Robert-Frost",
  "T.S.-Eliot",
  "E.E.-Cummings",
  "John-Updike",
  "Doris-Lessing",
  "Ursula-K.-Le-Guin",
  "Kazuo-Ishiguro",
  "Ian-McEwan",
  "Julian-Barnes",
  "Graham-Greene",
  "Salman-Rushdie",
  "V.S.-Naipaul",
  "Chinua-Achebe",
  "Gabriel-Garcia-Marquez",
  "Mario-Vargas-Llosa",
  "Orhan-Pamuk",
  "Harold-Pinter",
  "Samuel-Beckett",
  "Tennessee-Williams",
  "Arthur-Miller",
  "Eugene-O'Neill",
  "Tom-Stoppard",
  "David-Mamet",
  "August-Wilson",
  "Truman-Capote",
  "Norman-Mailer",
  "Joan-Didion",
  "Susan-Sontag",
  "Gore-Vidal",
  "John-Grisham",
];

export const musical_genres = [
  "Ambient-atmospheres",
  "Lo-Fi-beats",
  "Ambient-Cinematic",
  "Ambient-Classical",
  "Chillwave",
  "Space-Ambient",
  "Sleepy-Atmospheres",
  "Ambient-Nature-Sounds",
  "Dreamy-Lo-Fi",
  "Acid-House",
  "Acid-Jazz",
  "Afrobeat",
  "Alternative-Country",
  "Alternative-Rock",
  "Ambient",
  "Ambient-Techno",
  "Americana",
  "Baroque",
  "Bhangra",
  "Big-Band",
  "Bluegrass",
  "Blues",
  "Bossa-Nova",
  "Breakbeat",
  "Celtic",
  "Chamber-Music",
  "Chanson",
  "Chiptune",
  "Classical",
  "Country",
  "Crunk",
  "Dancehall",
  "Death-Metal",
  "Disco",
  "Doom-Metal",
  "Downtempo",
  "Drum-and-Bass",
  "Dub",
  "Dubstep",
  "EDM_(Electronic-Dance-Music)",
  "Electro",
  "Emo",
  "Experimental",
  "Flamenco",
  "Folk",
  "Funk",
  "Garage-Rock",
  "Glam-Rock",
  "Gospel",
  "Grime",
  "Grunge",
  "Hard-Rock",
  "Hardcore",
  "Heavy-Metal",
  "Hip-Hop",
  "House",
  "Indie-Pop",
  "Indie-Rock",
  "Industrial",
  "Jazz",
  "K-Pop",
  "Klezmer",
  "Latin",
  "Lo-fi",
  "Mariachi",
  "Math-Rock",
  "Metalcore",
  "Minimalism",
  "Motown",
  "New-Age",
  "New-Wave",
  "Noise",
  "Opera",
  "Orchestral",
  "Pop",
  "Post-Rock",
  "Progressive-Rock",
  "Psychedelic-Rock",
  "Punk",
  "R&B_(Rhythm-and-Blues)",
  "Ragtime",
  "Rap",
  "Reggae",
  "Reggaeton",
  "Rock",
  "Rockabilly",
  "Salsa",
  "Samba",
  "Shoegaze",
  "Ska",
  "Smooth-Jazz",
  "Soul",
  "Soundtrack",
  "Swing",
  "Symphonic-Metal",
  "Synthpop",
  "Techno",
  "Traditional-Folk",
  "Trance",
  "Trap",
  "Trip-Hop",
  "Tropical-House",
  "UK-Garage",
  "Vaporwave",
  "Vocalese",
  "Waltz",
  "West-Coast-Jazz",
  "World-Music",
  "Zydeco",
];

export const instruments = [
  "Violin",
  "Viola",
  "Cello",
  "Double-Bass",
  "Harp",
  "Guitar",
  "Electric-Guitar",
  "Bass-Guitar",
  "Ukulele",
  "Banjo",
  "Mandolin",
  "Sitar",
  "Koto",
  "Flute",
  "Clarinet",
  "Oboe",
  "Bassoon",
  "Piccolo",
  "Saxophone",
  "Recorder",
  "Trumpet",
  "Trombone",
  "Tuba",
  "French-Horn",
  "Euphonium",
  "Cornet",
  "Flugelhorn",
  "Drum-Kit",
  "Timpani",
  "Xylophone",
  "Marimba",
  "Vibraphone",
  "Bongos",
  "Congas",
  "Tabla",
  "Tambourine",
  "Triangle",
  "Djembe",
  "Maracas",
  "Castanets",
  "Piano",
  "Electric-Piano",
  "Organ",
  "Accordion",
  "Harmonium",
  "Clavichord",
  "Harpsichord",
  "Synthesizer",
  "Theremin",
  "Drum-Machine",
  "Sampler",
  "Harmonica",
  "Bagpipes",
  "Didgeridoo",
  "Pan-Flute",
  "Shakuhachi",
  "Zither",
  "Dulcimer",
  "Autoharp",
  "Erhu",
  "Sarangi",
  "Steel-Drums",
  "Hang-Drum",
  "Mbira",
  "Kalimba",
  "Ocarina",
  "Lute",
  "Lyre",
  "Gongs",
  "Singing-Bowls",
  "Cuíca",
  "Berimbau",
  "Balalaika",
  "Bouzouki",
  "Pipa",
  "Guzheng",
  "Hurdy-Gurdy",
  "Hammered-Dulcimer",
  "Jaw-Harp",
  "Glass-Armonica",
  "Washboard",
  "Melodica",
  "Cajón",
  "Rainstick",
  "Jew's-Harp",
  "Saz",
  "Shekere",
  "Bodhrán",
  "Shruti-Box",
  "Concertina",
  "Bandoneon",
  "Chapman-Stick",
  "Ney",
  "Kora",
  "Veena",
  "Dizi",
  "Hardanger-Fiddle",
  "Rebab",
  "Charango",
  "Oud",
];

export const moods = [
  "Happy",
  "Sad",
  "Energetic",
  "Relaxing",
  "Romantic",
  "Angry",
  "Uplifting",
  "Melancholic",
  "Inspirational",
  "Mysterious",
  "Party",
  "Chill",
  "Dark",
  "Exciting",
  "Sentimental",
  "Epic",
  "Dreamy",
  "Playful",
  "Nostalgic",
  "Dramatic",
  "Intense",
  "Reflective",
  "Calm",
  "Groovy",
  "Funky",
  "Smooth",
  "Aggressive",
  "Soothing",
  "Spiritual",
  "Soulful",
  "Joyful",
  "Jazzy",
  "Hypnotic",
  "Triumphant",
  "Ethereal",
  "Healing",
  "Meditative",
  "Cinematic",
  "Ambient",
  "Anxious",
  "Ecstatic",
  "Heartbreaking",
  "Motivational",
  "Powerful",
  "Tense",
  "Cheerful",
  "Fearful",
  "Hopeful",
  "Lively",
  "Suspenseful",
  "Vibrant",
  "Warm",
  "Whimsical",
  "Wistful",
  "Youthful",
  "Zany",
  "Adventurous",
  "Bittersweet",
  "Confident",
  "Cool",
  "Delicate",
  "Edgy",
  "Elated",
  "Emotional",
  "Exhilarating",
  "Festive",
  "Gentle",
  "Gritty",
  "Heroic",
  "Innocent",
  "Intimate",
  "Majestic",
  "Optimistic",
  "Pensive",
  "Positive",
  "Rebellious",
  "Regal",
  "Rhythmic",
  "Sassy",
  "Serene",
  "Sophisticated",
  "Stirring",
  "Sweet",
  "Thoughtful",
  "Tragic",
  "Tranquil",
  "Yearning",
  "Sorrowful",
  "Determined",
  "Laid-back",
  "Wild",
  "Quirky",
  "Expressive",
  "Majestic",
  "Solemn",
  "Unsettling",
  "Rejuvenating",
  "Compassionate",
  "Vibrant",
  "Celebratory",
];

export const composers = [
  "Hans-Zimmer",
  "John-Williams",
  "Ludwig-van-Beethoven",
  "Wolfgang-Amadeus-Mozart",
  "Quincy-Jones",
  "Dr.-Dre",
  "Pharrell-Williams",
  "Max-Martin",
  "Beyoncé",
  "Taylor-Swift",
  "Elton-John",
  "Kanye-West",
  "Timbaland",
  "Adele",
  "Lady-Gaga",
  "Michael-Jackson",
  "Madonna",
  "Rihanna",
  "Jay-Z",
  "Paul-McCartney",
  "Ennio-Morricone",
  "Danny-Elfman",
  "Alan-Menken",
  "Howard-Shore",
  "John-Legend",
  "Alicia-Keys",
  "Bruno-Mars",
  "Katy-Perry",
  "Ed-Sheeran",
  "Calvin-Harris",
  "David-Guetta",
  "Skrillex",
  "Diplo",
  "Prince",
  "Brian-Wilson",
  "Burt-Bacharach",
  "Henry-Mancini",
  "Jerry-Goldsmith",
  "James-Horner",
  "Kendrick-Lamar",
  "Frank-Ocean",
  "Sia",
  "Stevie-Wonder",
  "Bob-Dylan",
  "Leonard-Bernstein",
  "Andrew-Lloyd-Webber",
  "Philip-Glass",
  "Steve-Reich",
  "Thomas-Newman",
  "John-Powell",
  "Ramin-Djawadi",
  "James-Newton-Howard",
  "Trent-Reznor",
  "Atticus-Ross",
  "Jóhann-Jóhannsson",
  "Alexandre-Desplat",
  "Mark-Ronson",
  "Justin-Timberlake",
  "Nile-Rodgers",
  "Rick-Rubin",
  "Giorgio-Moroder",
  "Berry-Gordy",
  "George-Martin",
  "Dolly-Parton",
  "Willie-Nelson",
  "Johnny-Cash",
  "Aretha-Franklin",
  "Marvin-Gaye",
  "Lata-Mangeshkar",
  "A.R.-Rahman",
  "Gustavo-Santaolalla",
  "Randy-Newman",
  "John-Barry",
  "Elmer-Bernstein",
  "Craig-Armstrong",
  "Brian-Eno",
  "Björk",
  "Radiohead",
  "Daft-Punk",
  "Coldplay",
  "U2",
  "The-Rolling-Stones",
  "The-Beatles",
  "Pink-Floyd",
  "Led-Zeppelin",
  "Nirvana",
  "Alicia-Keys",
  "Britney-Spears",
  "Mariah-Carey",
  "Whitney-Houston",
  "Celine-Dion",
  "Andrea-Bocelli",
  "Luciano-Pavarotti",
  "Plácido-Domingo",
  "José-Carreras",
  "Kishore-Kumar",
  "Ilaiyaraaja",
  "Yo-Yo-Ma",
  "Itzhak-Perlman",
  "Lang-Lang",
  "Miles-Davis",
  "Jimi-Hendrix",
  "B.B.-King",
  "Bob-Marley",
  "John-Coltrane",
];

export const persona_mapping = {
  "Academic Writer": {
    genre: "Non-Fiction",
    tone: "Informative",
    "writing-style": "Academic",
    personality: "Analytical",
  },
  "Adventure Writer": {
    genre: "Adventure",
    tone: "Exciting",
    "writing-style": "Descriptive",
    personality: "Adventurous",
  },
  "Aeronautical Engineer": {
    genre: "Technical",
    tone: "Analytical",
    "writing-style": "Technical Documentation",
    personality: "Innovative",
  },
  Anthropologist: {
    genre: "Cultural Studies",
    tone: "Observational",
    "writing-style": "Ethnographic",
    personality: "Curious",
  },
  Archaeologist: {
    genre: "Historical",
    tone: "Investigative",
    "writing-style": "Scholarly",
    personality: "Explorative",
  },
  Architect: {
    genre: "Architectural Design",
    tone: "Visionary",
    "writing-style": "Technical with Creative Insights",
    personality: "Imaginative",
  },
  "Art Critic": {
    genre: "Art Criticism",
    tone: "Critical",
    "writing-style": "Analytical and Descriptive",
    personality: "Opinionated",
  },
  "Art Historian": {
    genre: "Art History",
    tone: "Educational",
    "writing-style": "Narrative",
    personality: "Knowledgeable",
  },
  "Art Writer": {
    genre: "Art",
    tone: "Informative",
    "writing-style": "Descriptive",
    personality: "Artistic",
  },
  Astronomer: {
    genre: "Science",
    tone: "Analytical",
    "writing-style": "Scientific",
    personality: "Inquisitive",
  },
  Astrophysicist: {
    genre: "Astrophysics",
    tone: "Technical",
    "writing-style": "Scientific",
    personality: "Analytical",
  },
  Biographer: {
    genre: "Biography",
    tone: "Narrative",
    "writing-style": "Narrative Non-Fiction",
    personality: "Insightful",
  },
  Biologist: {
    genre: "Biology",
    tone: "Explanatory",
    "writing-style": "Scientific",
    personality: "Analytical",
  },
  Botanist: {
    genre: "Botany",
    tone: "Informative",
    "writing-style": "Scientific",
    personality: "Detail-Oriented",
  },
  "Broadcast Journalist": {
    genre: "Journalism",
    tone: "Objective",
    "writing-style": "News Reporting",
    personality: "Inquisitive",
  },
  "Business Analyst": {
    genre: "Business",
    tone: "Analytical",
    "writing-style": "Technical Analysis",
    personality: "Strategic",
  },
  "Business Writer": {
    genre: "Business",
    tone: "Professional",
    "writing-style": "Formal",
    personality: "Concise",
  },
  Cartographer: {
    genre: "Geography",
    tone: "Informative",
    "writing-style": "Technical",
    personality: "Precise",
  },
  Chemist: {
    genre: "Chemistry",
    tone: "Technical",
    "writing-style": "Scientific",
    personality: "Analytical",
  },
  Cinematographer: {
    genre: "Film",
    tone: "Visual",
    "writing-style": "Descriptive",
    personality: "Creative",
  },
  Climatologist: {
    genre: "Environmental Science",
    tone: "Analytical",
    "writing-style": "Scientific",
    personality: "Investigative",
  },
  Columnist: {
    genre: "Opinion",
    tone: "Persuasive",
    "writing-style": "Editorial",
    personality: "Opinionated",
  },
  "Comedy Writer": {
    genre: "Comedy",
    tone: "Humorous",
    "writing-style": "Creative",
    personality: "Witty",
  },
  "Comic Book Writer": {
    genre: "Graphic Novels",
    tone: "Dramatic",
    "writing-style": "Narrative",
    personality: "Inventive",
  },
  Copywriter: {
    genre: "Advertising",
    tone: "Persuasive",
    "writing-style": "Promotional",
    personality: "Creative",
  },
  "Cultural Studies Scholar": {
    genre: "Cultural Studies",
    tone: "Analytical",
    "writing-style": "Academic",
    personality: "Insightful",
  },
  "Data Scientist": {
    genre: "Technology",
    tone: "Technical",
    "writing-style": "Analytical",
    personality: "Analytical",
  },
  Demographer: {
    genre: "Sociology",
    tone: "Analytical",
    "writing-style": "Statistical",
    personality: "Investigative",
  },
  Dentist: {
    genre: "Health",
    tone: "Informative",
    "writing-style": "Technical",
    personality: "Detail-Oriented",
  },
  Dermatologist: {
    genre: "Medical",
    tone: "Professional",
    "writing-style": "Scientific",
    personality: "Expert",
  },
  "Documentary Filmmaker": {
    genre: "Documentary",
    tone: "Narrative",
    "writing-style": "Visual Storytelling",
    personality: "Creative",
  },
  Dramaturg: {
    genre: "Theatre",
    tone: "Analytical",
    "writing-style": "Critical",
    personality: "Inquisitive",
  },
  Economist: {
    genre: "Economics",
    tone: "Analytical",
    "writing-style": "Academic",
    personality: "Logical",
  },
  Editor: {
    genre: "Literature",
    tone: "Critical",
    "writing-style": "Editorial",
    personality: "Detail-Oriented",
  },
  "Education Consultant": {
    genre: "Education",
    tone: "Advisory",
    "writing-style": "Informative",
    personality: "Helpful",
  },
  "Environmental Scientist": {
    genre: "Environmental Studies",
    tone: "Explanatory",
    "writing-style": "Scientific",
    personality: "Eco-Conscious",
  },
  Epidemiologist: {
    genre: "Public Health",
    tone: "Analytical",
    "writing-style": "Scientific",
    personality: "Investigative",
  },
  Essayist: {
    genre: "Cultural Commentary",
    tone: "Reflective",
    "writing-style": "Narrative",
    personality: "Thoughtful",
  },
  Ethnographer: {
    genre: "Anthropology",
    tone: "Observational",
    "writing-style": "Descriptive",
    personality: "Curious",
  },
  "Fantasy Author": {
    genre: "Fantasy",
    tone: "Imaginative",
    "writing-style": "Creative",
    personality: "Inventive",
  },
  "Fashion Designer": {
    genre: "Fashion",
    tone: "Innovative",
    "writing-style": "Visual Description",
    personality: "Artistic",
  },
  "Fashion Writer": {
    genre: "Fashion Journalism",
    tone: "Trendy",
    "writing-style": "Descriptive",
    personality: "Stylish",
  },
  "Film Critic": {
    genre: "Film Criticism",
    tone: "Analytical",
    "writing-style": "Critical",
    personality: "Opinionated",
  },
  "Food Critic": {
    genre: "Gastronomy",
    tone: "Evaluative",
    "writing-style": "Descriptive",
    personality: "Discerning",
  },
  "Food Writer": {
    genre: "Culinary Arts",
    tone: "Descriptive",
    "writing-style": "Narrative",
    personality: "Passionate",
  },
  "Forensic Scientist": {
    genre: "Crime/Mystery",
    tone: "Analytical",
    "writing-style": "Technical Report Writing",
    personality: "Sherlock Holmes",
  },
  "Game Designer": {
    genre: "Fantasy/Sci-Fi",
    tone: "Creative",
    "writing-style": "Descriptive",
    personality: "Shigeru Miyamoto",
  },
  Geneticist: {
    genre: "Science Fiction",
    tone: "Inquisitive",
    "writing-style": "Academic Writing",
    personality: "Francis Crick",
  },
  Geographer: {
    genre: "Travel/Adventure",
    tone: "Informative",
    "writing-style": "Expository",
    personality: "Alexander von Humboldt",
  },
  Geologist: {
    genre: "Natural History",
    tone: "Detailed",
    "writing-style": "Scientific Writing",
    personality: "Charles Lyell",
  },
  Ghostwriter: {
    genre: "Varies",
    tone: "Adaptive",
    "writing-style": "Versatile",
    personality: "H.P. Lovecraft",
  },
  "Graphic Novelist": {
    genre: "Graphic Novels",
    tone: "Expressive",
    "writing-style": "Visual Storytelling",
    personality: "Stan Lee",
  },
  "Health Educator": {
    genre: "Self-help/Health",
    tone: "Empathetic",
    "writing-style": "Educational",
    personality: "Deepak Chopra",
  },
  Historian: {
    genre: "Historical",
    tone: "Reflective",
    "writing-style": "Narrative",
    personality: "Doris Kearns Goodwin",
  },
  "Horror Writer": {
    genre: "Horror",
    tone: "Eerie",
    "writing-style": "Narrative",
    personality: "Stephen King",
  },
  Horticulturist: {
    genre: "Gardening/Nature",
    tone: "Practical",
    "writing-style": "Guide/Instructional",
    personality: "Gertrude Jekyll",
  },
  "Human Resources Specialist": {
    genre: "Professional Development",
    tone: "Objective",
    "writing-style": "Persuasive",
    personality: "Adam Grant",
  },
  Illustrator: {
    genre: "Art/Design",
    tone: "Visual",
    "writing-style": "Illustrative",
    personality: "Maurice Sendak",
  },
  Immunologist: {
    genre: "Medical Science",
    tone: "Technical",
    "writing-style": "Research Paper Writing",
    personality: "Anthony Fauci",
  },
  "Industrial Designer": {
    genre: "Design/Technology",
    tone: "Innovative",
    "writing-style": "Project Presentation",
    personality: "Jony Ive",
  },
  Journalist: {
    genre: "Non-fiction",
    tone: "Investigative",
    "writing-style": "News Reporting",
    personality: "Bob Woodward",
  },
  "Laboratory Technician": {
    genre: "Science/Research",
    tone: "Methodical",
    "writing-style": "Lab Report Writing",
    personality: "Rosalind Franklin",
  },
  "Legal Analyst": {
    genre: "Legal/Political",
    tone: "Critical",
    "writing-style": "Analytical Writing",
    personality: "Ruth Bader Ginsburg",
  },
  "Legal Writer": {
    genre: "Legal",
    tone: "Formal",
    "writing-style": "Legal Writing",
    personality: "John Grisham",
  },
  Librarian: {
    genre: "Informational",
    tone: "Helpful",
    "writing-style": "Cataloging",
    personality: "Nancy Pearl",
  },
  "Literary Agent": {
    genre: "Literary",
    tone: "Persuasive",
    "writing-style": "Negotiation",
    personality: "Jacqueline Kennedy Onassis",
  },
  "Literary Critic": {
    genre: "Literary Analysis",
    tone: "Critical",
    "writing-style": "Critical Essay",
    personality: "Harold Bloom",
  },
  "Marine Biologist": {
    genre: "Environmental Science",
    tone: "Passionate",
    "writing-style": "Research Paper Writing",
    personality: "Sylvia Earle",
  },
  "Marketing Specialist": {
    genre: "Marketing/Advertising",
    tone: "Engaging",
  },
  "Medical Illustrator": {
    genre: "Medical",
    tone: "Technical",
    "writing-style": "Illustrative",
    personality: "Artistic",
  },
  "Medical Writer": {
    genre: "Medical",
    tone: "Informative",
    "writing-style": "Technical",
    personality: "Analytical",
  },
  Meteorologist: {
    genre: "Science",
    tone: "Informative",
    "writing-style": "Technical",
    personality: "Analytical",
  },
  Microbiologist: {
    genre: "Biology",
    tone: "Scientific",
    "writing-style": "Research",
    personality: "Detail-Oriented",
  },
  "Music Critic": {
    genre: "Music",
    tone: "Critical",
    "writing-style": "Analytical",
    personality: "Insightful",
  },
  "Music Therapist": {
    genre: "Health",
    tone: "Empathetic",
    "writing-style": "Clinical",
    personality: "Compassionate",
  },
  "Music Writer": {
    genre: "Music",
    tone: "Informative",
    "writing-style": "Descriptive",
    personality: "Artistic",
  },
  Musicologist: {
    genre: "Music",
    tone: "Academic",
    "writing-style": "Scholarly",
    personality: "Analytical",
  },
  "Mystery Writer": {
    genre: "Mystery",
    tone: "Suspenseful",
    "writing-style": "Narrative",
    personality: "Intriguing",
  },
  "Nature Writer": {
    genre: "Nature",
    tone: "Reflective",
    "writing-style": "Descriptive",
    personality: "Observant",
  },
  Neurologist: {
    genre: "Medical",
    tone: "Clinical",
    "writing-style": "Technical",
    personality: "Analytical",
  },
  Neuroscientist: {
    genre: "Neuroscience",
    tone: "Academic",
    "writing-style": "Scientific",
    personality: "Inquisitive",
  },
  Novelist: {
    genre: "Fiction",
    tone: "Creative",
    "writing-style": "Narrative",
    personality: "Imaginative",
  },
  "Nuclear Physicist": {
    genre: "Physics",
    tone: "Technical",
    "writing-style": "Scientific",
    personality: "Analytical",
  },
  Nutritionist: {
    genre: "Health",
    tone: "Educational",
    "writing-style": "Informative",
    personality: "Helpful",
  },
  Oceanographer: {
    genre: "Marine Science",
    tone: "Exploratory",
    "writing-style": "Scientific",
    personality: "Curious",
  },
  Oncologist: {
    genre: "Medical",
    tone: "Clinical",
    "writing-style": "Technical",
    personality: "Compassionate",
  },
  Paleontologist: {
    genre: "Science",
    tone: "Investigative",
    "writing-style": "Research",
    personality: "Inquisitive",
  },
  Pathologist: {
    genre: "Medical",
    tone: "Analytical",
    "writing-style": "Scientific",
    personality: "Detail-Oriented",
  },
  "Performance Artist": {
    genre: "Art",
    tone: "Expressive",
    "writing-style": "Narrative",
    personality: "Creative",
  },
  Pharmacist: {
    genre: "Health",
    tone: "Informative",
    "writing-style": "Technical",
    personality: "Analytical",
  },
  Philosopher: {
    genre: "Philosophy",
    tone: "Reflective",
    "writing-style": "Analytical",
    personality: "Thoughtful",
  },
  "Philosophical Writer": {
    genre: "Philosophy",
    tone: "Analytical",
    "writing-style": "Critical",
    personality: "Insightful",
  },
  Photographer: {
    genre: "Art",
    tone: "Visual",
    "writing-style": "Descriptive",
    personality: "Creative",
  },
  Photojournalist: {
    genre: "Journalism",
    tone: "Documentary",
    "writing-style": "Narrative",
    personality: "Adventurous",
  },
  "Physical Therapist": {
    genre: "Health",
    tone: "Empathetic",
    "writing-style": "Clinical",
    personality: "Supportive",
  },
  Physician: {
    genre: "Medical",
    tone: "Professional",
    "writing-style": "Clinical",
    personality: "Hippocrates",
  },
  Physicist: {
    genre: "Science",
    tone: "Analytical",
    "writing-style": "Technical",
    personality: "Albert Einstein",
  },
  Playwright: {
    genre: "Drama",
    tone: "Expressive",
    "writing-style": "Dialogic",
    personality: "Tennessee Williams",
  },
  Poet: {
    genre: "Poetry",
    tone: "Lyrical",
    "writing-style": "Poetic",
    personality: "Maya Angelou",
  },
  "Political Analyst": {
    genre: "Political Commentary",
    tone: "Insightful",
    "writing-style": "Analytical",
    personality: "George Orwell",
  },
  "Political Scientist": {
    genre: "Politics",
    tone: "Critical",
    "writing-style": "Academic",
    personality: "Aristotle",
  },
  "Political Writer": {
    genre: "Political Essays",
    tone: "Persuasive",
    "writing-style": "Expository",
    personality: "Thomas Paine",
  },
  Psychiatrist: {
    genre: "Psychology/Mental Health",
    tone: "Empathetic",
    "writing-style": "Clinical",
    personality: "Sigmund Freud",
  },
  Psychologist: {
    genre: "Psychology",
    tone: "Insightful",
    "writing-style": "Expository",
    personality: "Carl Rogers",
  },
  "Public Health Specialist": {
    genre: "Public Health",
    tone: "Informative",
    "writing-style": "Educational",
    personality: "Margaret Chan",
  },
  "Public Relations Specialist": {
    genre: "Public Relations",
    tone: "Persuasive",
    "writing-style": "Promotional",
    personality: "Edward Bernays",
  },
  Publisher: {
    genre: "Literary Business",
    tone: "Professional",
    "writing-style": "Business Communication",
    personality: "Katharine Graham",
  },
  "Radio Producer": {
    genre: "Broadcasting",
    tone: "Creative",
    "writing-style": "Scriptwriting",
    personality: "Ira Glass",
  },
  "Religious Writer": {
    genre: "Religious/Spiritual",
    tone: "Inspirational",
    "writing-style": "Narrative",
    personality: "C.S. Lewis",
  },
  "Research Scientist": {
    genre: "Scientific Research",
    tone: "Objective",
    "writing-style": "Research Paper",
    personality: "Marie Curie",
  },
  "Romance Writer": {
    genre: "Romance",
    tone: "Passionate",
    "writing-style": "Narrative",
    personality: "Jane Austen",
  },
  "Science Educator": {
    genre: "Science Education",
    tone: "Engaging",
    "writing-style": "Didactic",
    personality: "Neil deGrasse Tyson",
  },
  "Science Fiction Author": {
    genre: "Science Fiction",
    tone: "Imaginative",
    "writing-style": "Creative",
    personality: "Isaac Asimov",
  },
  Screenwriter: {
    genre: "Film",
    tone: "Visual",
    "writing-style": "Screenwriting",
    personality: "Charlie Kaufman",
  },
  Sculptor: {
    genre: "Art/Design",
    tone: "Aesthetic",
    "writing-style": "Descriptive",
    personality: "Michelangelo",
  },
  "Self-help Writer": {
    genre: "Self-help",
    tone: "Motivational",
    "writing-style": "Persuasive",
    personality: "Tony Robbins",
  },
  Singer: {
    genre: "Music",
    tone: "Expressive",
    "writing-style": "Lyrical",
    personality: "Bob Dylan",
  },
  Sociolinguist: {
    genre: "Linguistics",
    tone: "Analytical",
    "writing-style": "Academic",
    personality: "William Labov",
  },
  Sociologist: {
    genre: "Sociology",
    tone: "Critical",
    "writing-style": "Analytical",
  },
  "Software Developer": {
    genre: "Technology/Innovation",
    tone: "Logical",
    "writing-style": "Technical Documentation",
    personality: "Linus Torvalds",
  },
  "Speech Therapist": {
    genre: "Educational/Health",
    tone: "Supportive",
    "writing-style": "Therapeutic Guidance",
    personality: "Temple Grandin",
  },
  Speechwriter: {
    genre: "Political/Inspirational",
    tone: "Persuasive",
    "writing-style": "Oratory",
    personality: "Jon Favreau",
  },
  "Sports Writer": {
    genre: "Sports/Journalism",
    tone: "Energetic",
    "writing-style": "Narrative Journalism",
    personality: "Grantland Rice",
  },
  Statistician: {
    genre: "Academic/Analytical",
    tone: "Objective",
    "writing-style": "Statistical Analysis",
    personality: "Florence Nightingale",
  },
  Surgeon: {
    genre: "Medical",
    tone: "Precise",
    "writing-style": "Clinical",
    personality: "Atul Gawande",
  },
  "Technical Writer": {
    genre: "Technology/Instructional",
    tone: "Clear",
    "writing-style": "Expository",
    personality: "Donald Knuth",
  },
  "Theatre Director": {
    genre: "Drama/Theatre",
    tone: "Visionary",
    "writing-style": "Script Direction",
    personality: "Julie Taymor",
  },
  "Thriller Writer": {
    genre: "Thriller/Suspense",
    tone: "Tense",
    "writing-style": "Fast-Paced Narrative",
    personality: "Alfred Hitchcock",
  },
  Toxicologist: {
    genre: "Science/Health",
    tone: "Analytical",
    "writing-style": "Research Findings",
    personality: "Rachel Carson",
  },
  "Travel Writer": {
    genre: "Travel/Culture",
    tone: "Adventurous",
    "writing-style": "Descriptive",
    personality: "Anthony Bourdain",
  },
  "Travelogue Writer": {
    genre: "Travel/Exploration",
    tone: "Engaging",
    "writing-style": "Narrative",
    personality: "Marco Polo",
  },
  "Urban Planner": {
    genre: "Urban Studies/Design",
    tone: "Strategic",
    "writing-style": "Analytical Planning",
    personality: "Jane Jacobs",
  },
  Veterinarian: {
    genre: "Animal Health/Care",
    tone: "Compassionate",
    "writing-style": "Informative",
    personality: "James Herriot",
  },
  "Video Game Writer": {
    genre: "Video Games/Narrative",
    tone: "Imaginative",
    "writing-style": "Story Development",
    personality: "Amy Hennig",
  },
  "War Correspondent": {
    genre: "Journalism/War Reporting",
    tone: "Intrepid",
    "writing-style": "Investigative",
    personality: "Marie Colvin",
  },
  "Wildlife Biologist": {
    genre: "Wildlife Conservation",
    tone: "Passionate",
    "writing-style": "Scientific Research",
    personality: "David Attenborough",
  },
  Writer: {
    genre: "Varies",
    tone: "Adaptive",
    "writing-style": "Creative",
    personality: "Margaret Atwood",
  },
  "Young Adult Author": {
    genre: "Young Adult Fiction",
    tone: "Relatable",
    "writing-style": "Narrative",
    personality: "J.K. Rowling",
  },
  Zoologist: {
    genre: "Nature/Animal Science",
    tone: "Informative",
    "writing-style": "Scientific Documentation",
    personality: "Steve Irwin",
  },
};

export const persona = [
  "Academic Writer",
  "Adventure Writer",
  "Aeronautical Engineer",
  "Anthropologist",
  "Archaeologist",
  "Architect",
  "Art Critic",
  "Art Historian",
  "Art Writer",
  "Astronomer",
  "Astrophysicist",
  "Biographer",
  "Biologist",
  "Botanist",
  "Broadcast Journalist",
  "Business Analyst",
  "Business Writer",
  "Cartographer",
  "Chemist",
  "Cinematographer",
  "Climatologist",
  "Columnist",
  "Comedy Writer",
  "Comic Book Writer",
  "Copywriter",
  "Cultural Studies Scholar",
  "Data Scientist",
  "Demographer",
  "Dentist",
  "Dermatologist",
  "Documentary Filmmaker",
  "Dramaturg",
  "Economist",
  "Editor",
  "Education Consultant",
  "Environmental Scientist",
  "Epidemiologist",
  "Essayist",
  "Ethnographer",
  "Fantasy Author",
  "Fashion Designer",
  "Fashion Writer",
  "Film Critic",
  "Food Critic",
  "Food Writer",
  "Forensic Scientist",
  "Game Designer",
  "Geneticist",
  "Geographer",
  "Geologist",
  "Ghostwriter",
  "Graphic Novelist",
  "Health Educator",
  "Historian",
  "Horror Writer",
  "Horticulturist",
  "Human Resources Specialist",
  "Illustrator",
  "Immunologist",
  "Industrial Designer",
  "Journalist",
  "Laboratory Technician",
  "Legal Analyst",
  "Legal Writer",
  "Librarian",
  "Literary Agent",
  "Literary Critic",
  "Marine Biologist",
  "Marketing Specialist",
  "Mathematician",
  "Medical Illustrator",
  "Medical Writer",
  "Meteorologist",
  "Microbiologist",
  "Music Critic",
  "Music Therapist",
  "Music Writer",
  "Musicologist",
  "Mystery Writer",
  "Nature Writer",
  "Neurologist",
  "Neuroscientist",
  "Novelist",
  "Nuclear Physicist",
  "Nutritionist",
  "Oceanographer",
  "Oncologist",
  "Paleontologist",
  "Pathologist",
  "Performance Artist",
  "Pharmacist",
  "Philosopher",
  "Philosophical Writer",
  "Photographer",
  "Photojournalist",
  "Physical Therapist",
  "Physician",
  "Physicist",
  "Playwright",
  "Poet",
  "Political Analyst",
  "Political Scientist",
  "Political Writer",
  "Psychiatrist",
  "Psychologist",
  "Public Health Specialist",
  "Public Relations Specialist",
  "Publisher",
  "Radio Producer",
  "Religious Writer",
  "Research Scientist",
  "Romance Writer",
  "Science Educator",
  "Science Fiction Author",
  "Screenwriter",
  "Sculptor",
  "Self-help Writer",
  "Singer",
  "Sociolinguist",
  "Sociologist",
  "Software Developer",
  "Speech Therapist",
  "Speechwriter",
  "Sports Writer",
  "Statistician",
  "Surgeon",
  "Technical Writer",
  "Theatre Director",
  "Thriller Writer",
  "Toxicologist",
  "Travel Writer",
  "Travelogue Writer",
  "Urban Planner",
  "Veterinarian",
  "Video Game Writer",
  "War Correspondent",
  "Wildlife Biologist",
  "Writer",
  "Young Adult Author",
  "Zoologist",
];

export const mappedVoices = [
  { voice: "Adam", display: "Bruce Wayne : Batman" },
  { voice: "Antoni", display: "Peter Parker : Spiderman" },
  { voice: "Arnold", display: "Morpheus : Matrix" },
  { voice: "Bella", display: "Belle : Beauty & the Beast" },
  { voice: "Domi", display: "Katniss : Hunger Games" },
  { voice: "Emily", display: "Luna : Harry Potter" },
  { voice: "Elli", display: "Elena : Vampire Diaries" },
  { voice: "Giovanni", display: "Ezio : Assassin's Creed" },
  { voice: "Jeremy", display: "Barry Allen : The Flash" },
  { voice: "Josh", display: "Thor : Avengers" },
  { voice: "Liam", display: "Leonard : Big-Bang Theory" },
  { voice: "Matilda", display: "Ariel : Little Mermaid" },
  { voice: "Matthew", display: "Watson : Sherlock Holmes" },
  { voice: "Michael", display: "Dumbledore : Harry Potter" },
  { voice: "Nicole", display: "Alice : Alice in Wonderland" },
  { voice: "Rachel", display: "Rory : Gilmore Girls" },
  { voice: "Ryan", display: "Steve : Captain America" },
  { voice: "Sam", display: "Logan : Wolverine" },
  { voice: "Thomas", display: "Samwise : LOTR" },
];

export const toolbarListItems = [
  "New",
  "Open",
  "Undo",
  "Redo",
  "Image",
  "Table",
  "Hyperlink",
  "Header",
  "Footer",
  "Break",
  "Find",
];

export const imageArtStyles = [
  "Pixar 3D",
  "Shonen Anime",
  "Shojo Anime",
  "Comic Superhero",
  "Superhero 3D",
  "LEGO 3D",
  "Watercolor Illustration",
  "Cyberpunk Aesthetic",
  "Steampunk Design",
  "Retro 80s",
  "Gothic Art",
  "Fantasy Art",
  "Digital Realism",
  "Manga Style",
  "Art Nouveau",
  "Graffiti Style",
  "Pixel Art",
  "Surrealist Art",
  "Pop Art",
  "Minimalist Design",
];

export const colorSchemes = [
  "Monochrome",
  "Complementary",
  "Analogous",
  "Triadic",
  "Warm Tones",
  "Cool Tones",
  "Square",
  "Neutral",
  "Pastel",
  "Vibrant",
  "Muted",
  "Earthy",
  "Metallic",
  "Rainbow",
  "Complementary Contrast Colors",
  "Four-Color Complementary (Tetradic)",
  "Darker Shades of a Color",
  "Lighter Tints of a Color",
  "High Contrast",
  "Low Contrast",
];

export const animationStyles = [
  "Pixar 3D Animation Style",
  "3D Lego Animation Style",
  "Disney 3D Animation Style",
  "Disney 2D Animation Style",
  "Shonen Anime Style",
  "Shojo Anime Style",
  "Seinen Anime Style",
  "Josei Anime Style",
  "Manga Black and White Style",
  "Comic Book Style",
  "Stop Motion Style",
  "Spider-Verse Comic 3D Style",
  "Ghibli 2D Style",
  "Paper Cut-Out Style",
  "Rotoscope Realism Style",
  "Abstract Animation Style",
  "Pixel Art Style",
  "Flash Animation Style",
  "Motion Graphics Style",
  "Claymation Style",
];

export const animationMoods = [
  "Heartwarming",
  "Adventurous",
  "Nostalgic",
  "Humorous",
  "Mysterious",
  "Romantic",
  "Inspirational",
  "Melancholic",
  "Uplifting",
  "Tense",
  "Playful",
  "Serene",
  "Dramatic",
  "Fantastical",
  "Suspenseful",
  "Whimsical",
  "Heroic",
  "Reflective",
  "Ethereal",
  "Comedic",
];

export const colorAndLightings = [
  "Vibrant Palette",
  "Soft Lighting",
  "Monochromatic Palette",
  "Dramatic Lighting",
  "Pastel Palette",
  "High Contrast Lighting",
  "Complementary Palette",
  "Natural Lighting",
  "Analogous Palette",
  "Low Key Lighting",
  "Warm Palette",
  "Backlit Lighting",
  "Triadic Palette",
  "Ambient Lighting",
  "Cool Palette",
  "Neon Palette",
  "Muted Palette",
  "Retro Palette",
  "Earth Tone Palette",
  "Metallic Palette",
];

export const imageAspectRatios = [
  "16:9",
  "21:9",
  "9:16",
  "4:5",
  "1:1",
  "3:2",
  "4:3",
  "2:3",
  "4:7",
  "7:4",
  "5:4",
];

export const videoAspectRatios = {
  "16:9": "16/9",
  "21:9": "21/9",
  "9:16": "9/16",
  "4:3": "4/3",
  "3:4": "3/4",
  "1:1": "1/1",
  "2:1": "2/1",
};

export const domains = [
  { label: "Academic Writing" },
  { label: "Advertising Copywriting" },
  { label: "Affiliate Marketing Content Creation" },
  { label: "Animation Scriptwriting" },
  { label: "Annual Report Writing" },
  { label: "Augmented Reality Content Creation" },
  { label: "Blogging" },
  { label: "Brand Storytelling" },
  { label: "Business Proposal Writing" },
  { label: "Case Study Creation" },
  { label: "Chatbot Scripting" },
  { label: "Comedy Writing" },
  { label: "Comic Book Writing" },
  { label: "Company Newsletter Writing" },
  { label: "Content Marketing Strategy" },
  { label: "Content Optimization for SEO" },
  { label: "Course and Curriculum Development" },
  { label: "Creative Writing" },
  { label: "Crowdfunding Campaign Content" },
  { label: "Data Analysis Reports" },
  { label: "Direct Response Copywriting" },
  { label: "E-Book Writing" },
  { label: "Editing and Proofreading" },
  { label: "Educational Material Development" },
  { label: "Email Marketing" },
  { label: "Environmental Reporting" },
  { label: "Event Script Writing" },
  { label: "Explainer Video Scripts" },
  { label: "Faceless Animated Video" },
  { label: "Fantasy Writing" },
  { label: "Fiction Writing" },
  { label: "Financial Reporting" },
  { label: "Game Design Documentation" },
  { label: "Ghostwriting" },
  { label: "Grant Writing" },
  { label: "Graphic Novel Writing" },
  { label: "Health and Wellness Writing" },
  { label: "Historical Writing" },
  { label: "How-To Guides" },
  { label: "Influencer Content Creation" },
  { label: "Instructional Design" },
  { label: "Interactive Media Writing" },
  { label: "Interviewing and Journalism" },
  { label: "Legal Writing" },
  { label: "LinkedIn Profile Optimization" },
  { label: "Literary Critique and Analysis" },
  { label: "Live Broadcasting Scripts" },
  { label: "Magazine Article Writing" },
  { label: "Marketing Collateral Creation" },
  { label: "Medical Writing" },
  { label: "Memoir Writing" },
  { label: "Mystery Writing" },
  { label: "Newsletter Content Creation" },
  { label: "Online Course Content" },
  { label: "Patent Writing" },
  { label: "Personal Branding Content" },
  { label: "Podcast Scripting" },
  { label: "Poetry" },
  { label: "Press Release Writing" },
  { label: "Product Descriptions" },
  { label: "Product Review Writing" },
  { label: "Professional Biography Writing" },
  { label: "Programmatic Advertising Scripts" },
  { label: "Psychological Reports" },
  { label: "Public Relations Writing" },
  { label: "Publishing Advisory" },
  { label: "Radio Ad Scripts" },
  { label: "Regulatory Compliance Documentation" },
  { label: "Research Papers" },
  { label: "Resume Writing" },
  { label: "Romance Writing" },
  { label: "Sales Funnel Content" },
  { label: "Science Fiction Writing" },
  { label: "Screenwriting" },
  { label: "SEO Article Writing" },
  { label: "Software Documentation" },
  { label: "Speech Writing" },
  { label: "Sponsored Content Creation" },
  { label: "Sports Writing" },
  { label: "Stand-up Comedy Writing" },
  { label: "Survey and Questionnaire Design" },
  { label: "Technical Writing" },
  { label: "Television Show Writing" },
  { label: "Theatre Playwriting" },
  { label: "Thesis and Dissertation Writing" },
  { label: "Travel Writing" },
  { label: "Tutorial Creation" },
  { label: "UI/UX Writing" },
  { label: "User Manual Writing" },
  { label: "Video Game Scripting" },
  { label: "Video Production Scripts" },
  { label: "Virtual Reality Content Creation" },
  { label: "Vlogging" },
  { label: "Web Comic Creation" },
  { label: "Web Content Strategy" },
  { label: "Web Development Writing" },
  { label: "White Paper Writing" },
  { label: "Wikipedia Page Creation" },
  { label: "YouTube Video Creation" },
  { label: "Youth Literature Writing" },
  { label: "YouTube Automation Video" },
];

export const educational_persona_mapping = {
  "Academic Writer": {
    genre: "Non-Fiction",
    tone: "Informative",
    "writing-style": "Academic",
    personality: "Analytical",
  },
  "Aeronautical Engineer": {
    genre: "Technical",
    tone: "Analytical",
    "writing-style": "Technical Documentation",
    personality: "Innovative",
  },
  Anthropologist: {
    genre: "Cultural Studies",
    tone: "Observational",
    "writing-style": "Ethnographic",
    personality: "Curious",
  },
  Archaeologist: {
    genre: "Historical",
    tone: "Investigative",
    "writing-style": "Scholarly",
    personality: "Explorative",
  },
  Architect: {
    genre: "Architectural Design",
    tone: "Visionary",
    "writing-style": "Technical with Creative Insights",
    personality: "Imaginative",
  },
  "Art Critic": {
    genre: "Art Criticism",
    tone: "Critical",
    "writing-style": "Analytical and Descriptive",
    personality: "Opinionated",
  },
  "Art Historian": {
    genre: "Art History",
    tone: "Educational",
    "writing-style": "Narrative",
    personality: "Knowledgeable",
  },
  "Art Writer": {
    genre: "Art",
    tone: "Informative",
    "writing-style": "Descriptive",
    personality: "Artistic",
  },
  Astronomer: {
    genre: "Science",
    tone: "Analytical",
    "writing-style": "Scientific",
    personality: "Inquisitive",
  },
  Astrophysicist: {
    genre: "Astrophysics",
    tone: "Technical",
    "writing-style": "Scientific",
    personality: "Analytical",
  },
  Biographer: {
    genre: "Biography",
    tone: "Narrative",
    "writing-style": "Narrative Non-Fiction",
    personality: "Insightful",
  },
  Biologist: {
    genre: "Biology",
    tone: "Explanatory",
    "writing-style": "Scientific",
    personality: "Analytical",
  },
  Botanist: {
    genre: "Botany",
    tone: "Informative",
    "writing-style": "Scientific",
    personality: "Detail-Oriented",
  },
  "Broadcast Journalist": {
    genre: "Journalism",
    tone: "Objective",
    "writing-style": "News Reporting",
    personality: "Inquisitive",
  },
  "Business Analyst": {
    genre: "Business",
    tone: "Analytical",
    "writing-style": "Technical Analysis",
    personality: "Strategic",
  },
  "Business Writer": {
    genre: "Business",
    tone: "Professional",
    "writing-style": "Formal",
    personality: "Concise",
  },
  Cartographer: {
    genre: "Geography",
    tone: "Informative",
    "writing-style": "Technical",
    personality: "Precise",
  },
  Chemist: {
    genre: "Chemistry",
    tone: "Technical",
    "writing-style": "Scientific",
    personality: "Analytical",
  },
  Climatologist: {
    genre: "Environmental Science",
    tone: "Analytical",
    "writing-style": "Scientific",
    personality: "Investigative",
  },
  "Cultural Studies Scholar": {
    genre: "Cultural Studies",
    tone: "Analytical",
    "writing-style": "Academic",
    personality: "Insightful",
  },
  "Data Scientist": {
    genre: "Technology",
    tone: "Technical",
    "writing-style": "Analytical",
    personality: "Analytical",
  },
  Demographer: {
    genre: "Sociology",
    tone: "Analytical",
    "writing-style": "Statistical",
    personality: "Investigative",
  },
  Dentist: {
    genre: "Health",
    tone: "Informative",
    "writing-style": "Technical",
    personality: "Detail-Oriented",
  },
  Dermatologist: {
    genre: "Medical",
    tone: "Professional",
    "writing-style": "Scientific",
    personality: "Expert",
  },
  "Documentary Filmmaker": {
    genre: "Documentary",
    tone: "Narrative",
    "writing-style": "Visual Storytelling",
    personality: "Creative",
  },
  Dramaturg: {
    genre: "Theatre",
    tone: "Analytical",
    "writing-style": "Critical",
    personality: "Inquisitive",
  },
  Economist: {
    genre: "Economics",
    tone: "Analytical",
    "writing-style": "Academic",
    personality: "Logical",
  },
  Editor: {
    genre: "Literature",
    tone: "Critical",
    "writing-style": "Editorial",
    personality: "Detail-Oriented",
  },
  "Education Consultant": {
    genre: "Education",
    tone: "Advisory",
    "writing-style": "Informative",
    personality: "Helpful",
  },
  "Environmental Scientist": {
    genre: "Environmental Studies",
    tone: "Explanatory",
    "writing-style": "Scientific",
    personality: "Eco-Conscious",
  },
  Epidemiologist: {
    genre: "Public Health",
    tone: "Analytical",
    "writing-style": "Scientific",
    personality: "Investigative",
  },
  Essayist: {
    genre: "Cultural Commentary",
    tone: "Reflective",
    "writing-style": "Narrative",
    personality: "Thoughtful",
  },
  Ethnographer: {
    genre: "Anthropology",
    tone: "Observational",
    "writing-style": "Descriptive",
    personality: "Curious",
  },
  "Forensic Scientist": {
    genre: "Crime/Mystery",
    tone: "Analytical",
    "writing-style": "Technical Report Writing",
    personality: "Sherlock Holmes",
  },
  Geneticist: {
    genre: "Science Fiction",
    tone: "Inquisitive",
    "writing-style": "Academic Writing",
    personality: "Francis Crick",
  },
  Geographer: {
    genre: "Travel/Adventure",
    tone: "Informative",
    "writing-style": "Expository",
    personality: "Alexander von Humboldt",
  },
  Geologist: {
    genre: "Natural History",
    tone: "Detailed",
    "writing-style": "Scientific Writing",
    personality: "Charles Lyell",
  },
  Ghostwriter: {
    genre: "Varies",
    tone: "Adaptive",
    "writing-style": "Versatile",
    personality: "H.P. Lovecraft",
  },
  "Health Educator": {
    genre: "Self-help/Health",
    tone: "Empathetic",
    "writing-style": "Educational",
    personality: "Deepak Chopra",
  },
  Historian: {
    genre: "Historical",
    tone: "Reflective",
    "writing-style": "Narrative",
    personality: "Doris Kearns Goodwin",
  },
  Horticulturist: {
    genre: "Gardening/Nature",
    tone: "Practical",
    "writing-style": "Guide/Instructional",
    personality: "Gertrude Jekyll",
  },
  "Human Resources Specialist": {
    genre: "Professional Development",
    tone: "Objective",
    "writing-style": "Persuasive",
    personality: "Adam Grant",
  },
  Illustrator: {
    genre: "Art/Design",
    tone: "Visual",
    "writing-style": "Illustrative",
    personality: "Maurice Sendak",
  },
  Immunologist: {
    genre: "Medical Science",
    tone: "Technical",
    "writing-style": "Research Paper Writing",
    personality: "Anthony Fauci",
  },
  "Industrial Designer": {
    genre: "Design/Technology",
    tone: "Innovative",
    "writing-style": "Project Presentation",
    personality: "Jony Ive",
  },
  Journalist: {
    genre: "Non-fiction",
    tone: "Investigative",
    "writing-style": "News Reporting",
    personality: "Bob Woodward",
  },
  "Laboratory Technician": {
    genre: "Science/Research",
    tone: "Methodical",
    "writing-style": "Lab Report Writing",
    personality: "Rosalind Franklin",
  },
  "Legal Analyst": {
    genre: "Legal/Political",
    tone: "Critical",
    "writing-style": "Analytical Writing",
    personality: "Ruth Bader Ginsburg",
  },
  "Legal Writer": {
    genre: "Legal",
    tone: "Formal",
    "writing-style": "Legal Writing",
    personality: "John Grisham",
  },
  Librarian: {
    genre: "Informational",
    tone: "Helpful",
    "writing-style": "Cataloging",
    personality: "Nancy Pearl",
  },
  "Literary Agent": {
    genre: "Literary",
    tone: "Persuasive",
    "writing-style": "Negotiation",
    personality: "Jacqueline Kennedy Onassis",
  },
  "Literary Critic": {
    genre: "Literary Analysis",
    tone: "Critical",
    "writing-style": "Critical Essay",
    personality: "Harold Bloom",
  },
  "Marine Biologist": {
    genre: "Environmental Science",
    tone: "Passionate",
    "writing-style": "Research Paper Writing",
    personality: "Sylvia Earle",
  },
  "Marketing Specialist": {
    genre: "Marketing/Advertising",
    tone: "Engaging",
    "writing-style": "Persuasive",
    personality: "Creative",
  },
  Mathematician: {
    genre: "Mathematics",
    tone: "Logical",
    "writing-style": "Technical",
    personality: "Precise",
  },
  "Medical Illustrator": {
    genre: "Medical",
    tone: "Technical",
    "writing-style": "Illustrative",
    personality: "Artistic",
  },
  "Medical Writer": {
    genre: "Medical",
    tone: "Informative",
    "writing-style": "Technical",
    personality: "Analytical",
  },
  Meteorologist: {
    genre: "Science",
    tone: "Informative",
    "writing-style": "Technical",
    personality: "Analytical",
  },
  Microbiologist: {
    genre: "Biology",
    tone: "Scientific",
    "writing-style": "Research",
    personality: "Detail-Oriented",
  },
  Musicologist: {
    genre: "Music",
    tone: "Academic",
    "writing-style": "Scholarly",
    personality: "Analytical",
  },
  "Nature Writer": {
    genre: "Nature",
    tone: "Reflective",
    "writing-style": "Descriptive",
    personality: "Observant",
  },
  Neurologist: {
    genre: "Medical",
    tone: "Clinical",
    "writing-style": "Technical",
    personality: "Analytical",
  },
  Neuroscientist: {
    genre: "Neuroscience",
    tone: "Academic",
    "writing-style": "Scientific",
    personality: "Inquisitive",
  },
  "Nuclear Physicist": {
    genre: "Physics",
    tone: "Technical",
    "writing-style": "Scientific",
    personality: "Analytical",
  },
  Nutritionist: {
    genre: "Health",
    tone: "Educational",
    "writing-style": "Informative",
    personality: "Helpful",
  },
  Oceanographer: {
    genre: "Marine Science",
    tone: "Exploratory",
    "writing-style": "Scientific",
    personality: "Curious",
  },
  Oncologist: {
    genre: "Medical",
    tone: "Clinical",
    "writing-style": "Technical",
    personality: "Compassionate",
  },
  Paleontologist: {
    genre: "Science",
    tone: "Investigative",
    "writing-style": "Research",
    personality: "Inquisitive",
  },
  Pathologist: {
    genre: "Medical",
    tone: "Analytical",
    "writing-style": "Scientific",
    personality: "Detail-Oriented",
  },
  Pharmacist: {
    genre: "Health",
    tone: "Informative",
    "writing-style": "Technical",
    personality: "Analytical",
  },
  Philosopher: {
    genre: "Philosophy",
    tone: "Reflective",
    "writing-style": "Analytical",
    personality: "Thoughtful",
  },
  "Philosophical Writer": {
    genre: "Philosophy",
    tone: "Analytical",
    "writing-style": "Critical",
    personality: "Insightful",
  },
  Photojournalist: {
    genre: "Journalism",
    tone: "Documentary",
    "writing-style": "Narrative",
    personality: "Adventurous",
  },
  "Physical Therapist": {
    genre: "Health",
    tone: "Empathetic",
    "writing-style": "Clinical",
    personality: "Supportive",
  },
  Physician: {
    genre: "Medical",
    tone: "Professional",
    "writing-style": "Clinical",
    personality: "Hippocrates",
  },
  Physicist: {
    genre: "Science",
    tone: "Analytical",
    "writing-style": "Technical",
    personality: "Albert Einstein",
  },
  "Political Analyst": {
    genre: "Political Commentary",
    tone: "Insightful",
    "writing-style": "Analytical",
    personality: "George Orwell",
  },
  "Political Scientist": {
    genre: "Politics",
    tone: "Critical",
    "writing-style": "Academic",
    personality: "Aristotle",
  },
  "Political Writer": {
    genre: "Political Essays",
    tone: "Persuasive",
    "writing-style": "Expository",
    personality: "Thomas Paine",
  },
  Psychiatrist: {
    genre: "Psychology/Mental Health",
    tone: "Empathetic",
    "writing-style": "Clinical",
    personality: "Sigmund Freud",
  },
  Psychologist: {
    genre: "Psychology",
    tone: "Insightful",
    "writing-style": "Expository",
    personality: "Carl Rogers",
  },
  "Public Health Specialist": {
    genre: "Public Health",
    tone: "Informative",
    "writing-style": "Educational",
    personality: "Margaret Chan",
  },
  "Research Scientist": {
    genre: "Scientific Research",
    tone: "Objective",
    "writing-style": "Research Paper",
    personality: "Marie Curie",
  },
  "Science Educator": {
    genre: "Science Education",
    tone: "Engaging",
    "writing-style": "Didactic",
    personality: "Neil deGrasse Tyson",
  },
  Sociolinguist: {
    genre: "Linguistics",
    tone: "Analytical",
    "writing-style": "Academic",
    personality: "William Labov",
  },
  Sociologist: {
    genre: "Sociology",
    tone: "Critical",
    "writing-style": "Analytical",
    personality: "Investigative",
  },
  "Software Developer": {
    genre: "Technology/Innovation",
    tone: "Logical",
    "writing-style": "Technical Documentation",
    personality: "Linus Torvalds",
  },
  "Speech Therapist": {
    genre: "Educational/Health",
    tone: "Supportive",
    "writing-style": "Therapeutic Guidance",
    personality: "Temple Grandin",
  },
  Statistician: {
    genre: "Academic/Analytical",
    tone: "Objective",
    "writing-style": "Statistical Analysis",
    personality: "Florence Nightingale",
  },
  Surgeon: {
    genre: "Medical",
    tone: "Precise",
    "writing-style": "Clinical",
    personality: "Atul Gawande",
  },
  "Technical Writer": {
    genre: "Technology/Instructional",
    tone: "Clear",
    "writing-style": "Expository",
    personality: "Donald Knuth",
  },
  Toxicologist: {
    genre: "Science/Health",
    tone: "Analytical",
    "writing-style": "Research Findings",
    personality: "Rachel Carson",
  },
  "Urban Planner": {
    genre: "Urban Studies/Design",
    tone: "Strategic",
    "writing-style": "Analytical Planning",
    personality: "Jane Jacobs",
  },
  Veterinarian: {
    genre: "Animal Health/Care",
    tone: "Compassionate",
    "writing-style": "Informative",
    personality: "James Herriot",
  },
  "War Correspondent": {
    genre: "Journalism/War Reporting",
    tone: "Intrepid",
    "writing-style": "Investigative",
    personality: "Marie Colvin",
  },
  "Wildlife Biologist": {
    genre: "Wildlife Conservation",
    tone: "Passionate",
    "writing-style": "Scientific Research",
    personality: "David Attenborough",
  },
  Zoologist: {
    genre: "Nature/Animal Science",
    tone: "Informative",
    "writing-style": "Scientific Documentation",
    personality: "Steve Irwin",
  },
};

export const entertainment_persona_mapping = {
  "Adventure Writer": {
    genre: "Adventure",
    tone: "Exciting",
    "writing-style": "Descriptive",
    personality: "Adventurous",
  },
  "Comedy Writer": {
    genre: "Comedy",
    tone: "Humorous",
    "writing-style": "Creative",
    personality: "Witty",
  },
  "Comic Book Writer": {
    genre: "Graphic Novels",
    tone: "Dramatic",
    "writing-style": "Narrative",
    personality: "Inventive",
  },
  "Fantasy Author": {
    genre: "Fantasy",
    tone: "Imaginative",
    "writing-style": "Creative",
    personality: "Inventive",
  },
  "Film Critic": {
    genre: "Film Criticism",
    tone: "Analytical",
    "writing-style": "Critical",
    personality: "Opinionated",
  },
  "Food Critic": {
    genre: "Gastronomy",
    tone: "Evaluative",
    "writing-style": "Descriptive",
    personality: "Discerning",
  },
  "Food Writer": {
    genre: "Culinary Arts",
    tone: "Descriptive",
    "writing-style": "Narrative",
    personality: "Passionate",
  },
  "Game Designer": {
    genre: "Fantasy/Sci-Fi",
    tone: "Creative",
    "writing-style": "Descriptive",
    personality: "Shigeru Miyamoto",
  },
  "Graphic Novelist": {
    genre: "Graphic Novels",
    tone: "Expressive",
    "writing-style": "Visual Storytelling",
    personality: "Stan Lee",
  },
  "Horror Writer": {
    genre: "Horror",
    tone: "Eerie",
    "writing-style": "Narrative",
    personality: "Stephen King",
  },
  "Mystery Writer": {
    genre: "Mystery",
    tone: "Suspenseful",
    "writing-style": "Narrative",
    personality: "Intriguing",
  },
  Novelist: {
    genre: "Fiction",
    tone: "Creative",
    "writing-style": "Narrative",
    personality: "Imaginative",
  },
  "Performance Artist": {
    genre: "Art",
    tone: "Expressive",
    "writing-style": "Narrative",
    personality: "Creative",
  },
  Photographer: {
    genre: "Art",
    tone: "Visual",
    "writing-style": "Descriptive",
    personality: "Creative",
  },
  Playwright: {
    genre: "Drama",
    tone: "Expressive",
    "writing-style": "Dialogic",
    personality: "Tennessee Williams",
  },
  Poet: {
    genre: "Poetry",
    tone: "Lyrical",
    "writing-style": "Poetic",
    personality: "Maya Angelou",
  },
  "Romance Writer": {
    genre: "Romance",
    tone: "Passionate",
    "writing-style": "Narrative",
    personality: "Jane Austen",
  },
  "Science Fiction Author": {
    genre: "Science Fiction",
    tone: "Imaginative",
    "writing-style": "Creative",
    personality: "Isaac Asimov",
  },
  Screenwriter: {
    genre: "Film",
    tone: "Visual",
    "writing-style": "Screenwriting",
    personality: "Charlie Kaufman",
  },
  "Self-help Writer": {
    genre: "Self-help",
    tone: "Motivational",
    "writing-style": "Persuasive",
    personality: "Tony Robbins",
  },
  Singer: {
    genre: "Music",
    tone: "Expressive",
    "writing-style": "Lyrical",
    personality: "Bob Dylan",
  },
  "Sports Writer": {
    genre: "Sports/Journalism",
    tone: "Energetic",
    "writing-style": "Narrative Journalism",
    personality: "Grantland Rice",
  },
  "Theatre Director": {
    genre: "Drama/Theatre",
    tone: "Visionary",
    "writing-style": "Script Direction",
    personality: "Julie Taymor",
  },
  "Thriller Writer": {
    genre: "Thriller/Suspense",
    tone: "Tense",
    "writing-style": "Fast-Paced Narrative",
    personality: "Alfred Hitchcock",
  },
  "Travel Writer": {
    genre: "Travel/Culture",
    tone: "Adventurous",
    "writing-style": "Descriptive",
    personality: "Anthony Bourdain",
  },
  "Travelogue Writer": {
    genre: "Travel/Exploration",
    tone: "Engaging",
    "writing-style": "Narrative",
    personality: "Marco Polo",
  },
  "Video Game Writer": {
    genre: "Video Games/Narrative",
    tone: "Imaginative",
    "writing-style": "Story Development",
    personality: "Amy Hennig",
  },
  Writer: {
    genre: "Varies",
    tone: "Adaptive",
    "writing-style": "Creative",
    personality: "Margaret Atwood",
  },
  "Young Adult Author": {
    genre: "Young Adult Fiction",
    tone: "Relatable",
    "writing-style": "Narrative",
    personality: "J.K. Rowling",
  },
};

export const educational_persona = [
  "Academic Writer",
  "Aeronautical Engineer",
  "Anthropologist",
  "Archaeologist",
  "Architect",
  "Art Critic",
  "Art Historian",
  "Art Writer",
  "Astronomer",
  "Astrophysicist",
  "Biographer",
  "Biologist",
  "Botanist",
  "Broadcast Journalist",
  "Business Analyst",
  "Business Writer",
  "Cartographer",
  "Chemist",
  "Climatologist",
  "Cultural Studies Scholar",
  "Data Scientist",
  "Demographer",
  "Dentist",
  "Dermatologist",
  "Documentary Filmmaker",
  "Dramaturg",
  "Economist",
  "Editor",
  "Education Consultant",
  "Environmental Scientist",
  "Epidemiologist",
  "Essayist",
  "Ethnographer",
  "Forensic Scientist",
  "Geneticist",
  "Geographer",
  "Geologist",
  "Ghostwriter",
  "Health Educator",
  "Historian",
  "Horticulturist",
  "Human Resources Specialist",
  "Illustrator",
  "Immunologist",
  "Industrial Designer",
  "Journalist",
  "Laboratory Technician",
  "Legal Analyst",
  "Legal Writer",
  "Librarian",
  "Literary Agent",
  "Literary Critic",
  "Marine Biologist",
  "Marketing Specialist",
  "Mathematician",
  "Medical Illustrator",
  "Medical Writer",
  "Meteorologist",
  "Microbiologist",
  "Musicologist",
  "Nature Writer",
  "Neurologist",
  "Neuroscientist",
  "Nuclear Physicist",
  "Nutritionist",
  "Oceanographer",
  "Oncologist",
  "Paleontologist",
  "Pathologist",
  "Pharmacist",
  "Philosopher",
  "Philosophical Writer",
  "Photojournalist",
  "Physical Therapist",
  "Physician",
  "Physicist",
  "Political Analyst",
  "Political Scientist",
  "Political Writer",
  "Psychiatrist",
  "Psychologist",
  "Public Health Specialist",
  "Research Scientist",
  "Science Educator",
  "Sociolinguist",
  "Sociologist",
  "Software Developer",
  "Speech Therapist",
  "Statistician",
  "Surgeon",
  "Technical Writer",
  "Toxicologist",
  "Urban Planner",
  "Veterinarian",
  "War Correspondent",
  "Wildlife Biologist",
  "Zoologist",
];

export const entertainment_persona = [
  "Adventure Writer",
  "Comedy Writer",
  "Comic Book Writer",
  "Fantasy Author",
  "Film Critic",
  "Food Critic",
  "Food Writer",
  "Game Designer",
  "Graphic Novelist",
  "Horror Writer",
  "Mystery Writer",
  "Novelist",
  "Performance Artist",
  "Photographer",
  "Playwright",
  "Poet",
  "Romance Writer",
  "Science Fiction Author",
  "Screenwriter",
  "Self-help Writer",
  "Singer",
  "Sports Writer",
  "Theatre Director",
  "Thriller Writer",
  "Travel Writer",
  "Travelogue Writer",
  "Video Game Writer",
  "Writer",
  "Young Adult Author",
];

export const textStyles = [
  "Serif",
  "Sans-Serif",
  "Slab Serif",
  "Script",
  "Monospaced",
  "Display",
  "Handwritten",
  "Blackletter",
  "Decorative",
  "Gothic",
];
