import React from 'react';
import Avatar from '@mui/material/Avatar';

function CustomAvatar({ color, name, imgUrl }) {
  return (
    imgUrl ? (
      <Avatar src={imgUrl} alt={name} sx={{ width: 32, height: 32 }} />
    ) : (
      <Avatar sx={{ bgcolor: color, color: 'blue', width: 24, height: 24 }}>
        {name ? name[0] : ''}
      </Avatar>
    )
  );
}

export default CustomAvatar;
