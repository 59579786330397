import { useState, useEffect } from "react";
import {
  List,
  ListItem,
  MenuItem,
  FormControl,
  Select,
  Button,
  Stack,
  Typography,
  TextField,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  videoAspectRatios,
  animationStyles,
  animationMoods,
  colorAndLightings,
} from "utils/constants";

const originalAspectRatios = Object.keys(videoAspectRatios);

const VideoSettingsDrawer = ({
  onAspectRatioChange,
  onAnimationStyleChange,
  onColorLightingChange,
  onAnimationMoodChange,
  onDirectVideoPromptChange,
  promptText,
  onSubmit,
}) => {
  const [videoAspectRatio, setVideoAspectRatio] = useState("");
  const [animationStyle, setAnimationStyle] = useState("");
  const [colorAndLighting, setColorAndLighting] = useState("");
  const [animationMood, setAnimationMood] = useState("");
  const [prompt, setPrompt] = useState(promptText);

  const handleVideoAspectRatio = (event, newvalue) => {
    const mappedValue = videoAspectRatios[newvalue] || newvalue;
    setVideoAspectRatio(mappedValue);
    onAspectRatioChange(mappedValue);
  };

  const handleAnimationStyle = (event, newvalue) => {
    setAnimationStyle(newvalue);
    onAnimationStyleChange(newvalue);
  };

  const handleVideoColorAndLighting = (event, newvalue) => {
    setColorAndLighting(newvalue);
    onColorLightingChange(newvalue);
  };

  const handleAnimationMoods = (event, newvalue) => {
    setAnimationMood(newvalue);
    onAnimationMoodChange(newvalue);
  };

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
    onDirectVideoPromptChange(event);
  };

  return (
    <List
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <ListItem
        sx={{
          display: "flex",
          alignItems: {
            xs: "flex-start",
            sm: "flex-start",
            md: "flex-start",
            lg: "center",
            xl: "center",
          },
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "200px",
              xl: "200px",
            },
            marginRight: { xs: "0", sm: "0", md: "0", lg: "16px", xl: "16px" },
          }}
        >
          Prompt *
        </Typography>
        <FormControl fullWidth sx={{ flex: 1 }}>
          <TextField
            label="Prompt"
            variant="outlined"
            value={prompt}
            onChange={handlePromptChange}
            fullWidth
            InputLabelProps={{
              style: { color: "white", fontSize: "18px" },
            }}
            InputProps={{
              style: {
                color: "white",
                fontSize: "18px",
              },
            }}
            sx={{
              "& label.Mui-focused": {
                color: "white",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              "& .MuiOutlinedInput-input": {
                paddingTop: "14px",
                paddingBottom: "14px",
              },
              "& .MuiInputLabel-outlined": {
                transform: "translate(14px, 14px) scale(1)",
              },
              "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
              },
            }}
            multiline
            maxRows={15}
          />
        </FormControl>
      </ListItem>
      <ListItem
        sx={{
          display: "flex",
          alignItems: {
            xs: "flex-start",
            sm: "flex-start",
            md: "flex-start",
            lg: "center",
            xl: "center",
          },
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "200px",
              xl: "200px",
            },
            marginRight: { xs: "0", sm: "0", md: "0", lg: "16px", xl: "16px" },
          }}
        >
          Aspect Ratio
        </Typography>
        <FormControl fullWidth sx={{ flex: 1 }}>
          {/* <Select value={videoAspectRatio} onChange={handleVideoAspectRatio}>
            {videoAspectRatios.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select> */}

          <Autocomplete
            // value={Object.keys(videoAspectRatios).find(key => videoAspectRatios[key])}
            onChange={handleVideoAspectRatio}
            options={originalAspectRatios}
            renderInput={(params) => (
             <TextField
            {...params}
            placeholder="Search..."
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                </InputAdornment>
              ),
              style: {
                color: "white",
                fontSize: "2rem", // Increase the font size of the input text
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
                fontSize: "2rem", // Increase the font size of the placeholder text
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                color: "white",
              },
            }}
          />
            )}
            ListboxProps={{
              sx: {
                "& .MuiAutocomplete-option": {
                  fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                  color: "black", // Set text color for the options
                  "&[aria-selected='true']": {
                    backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                  },
                  "&:hover": {
                    backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                  },
                },
              },
            }}
          />
        </FormControl>
      </ListItem>
      <ListItem
        sx={{
          display: "flex",
          alignItems: {
            xs: "flex-start",
            sm: "flex-start",
            md: "flex-start",
            lg: "center",
            xl: "center",
          },
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "200px",
              xl: "200px",
            },
            marginRight: { xs: "0", sm: "0", md: "0", lg: "16px", xl: "16px" },
          }}
        >
          Animation Style
        </Typography>
        <FormControl fullWidth sx={{ flex: 1 }}>
          {/* <Select value={animationStyle} onChange={handleAnimationStyle}>
            {animationStyles.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select> */}
          <Autocomplete
            value={animationStyle}
            onChange={handleAnimationStyle}
            options={animationStyles}
            renderInput={(params) => (
              <TextField
            {...params}
            placeholder="Search..."
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                </InputAdornment>
              ),
              style: {
                color: "white",
                fontSize: "2rem", // Increase the font size of the input text
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
                fontSize: "2rem", // Increase the font size of the placeholder text
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                color: "white",
              },
            }}
          />
            )}
            ListboxProps={{
              sx: {
                "& .MuiAutocomplete-option": {
                  fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                  color: "black", // Set text color for the options
                  "&[aria-selected='true']": {
                    backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                  },
                  "&:hover": {
                    backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                  },
                },
              },
            }}
          />
        </FormControl>
      </ListItem>
      <ListItem
        sx={{
          display: "flex",
          alignItems: {
            xs: "flex-start",
            sm: "flex-start",
            md: "flex-start",
            lg: "center",
            xl: "center",
          },
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "200px",
              xl: "200px",
            },
            marginRight: { xs: "0", sm: "0", md: "0", lg: "16px", xl: "16px" },
          }}
        >
          Animation Mood
        </Typography>
        <FormControl fullWidth sx={{ flex: 1 }}>
          {/* <Select value={animationMood} onChange={handleAnimationMoods}>
            {animationMoods.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select> */}
          <Autocomplete
            value={animationMood}
            onChange={handleAnimationMoods}
            options={animationMoods}
            renderInput={(params) => (
              <TextField
            {...params}
            placeholder="Search..."
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                </InputAdornment>
              ),
              style: {
                color: "white",
                fontSize: "2rem", // Increase the font size of the input text
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
                fontSize: "2rem", // Increase the font size of the placeholder text
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                color: "white",
              },
            }}
          />
            )}
            ListboxProps={{
              sx: {
                "& .MuiAutocomplete-option": {
                  fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                  color: "black", // Set text color for the options
                  "&[aria-selected='true']": {
                    backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                  },
                  "&:hover": {
                    backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                  },
                },
              },
            }}
          />
        </FormControl>
      </ListItem>
      <ListItem
        sx={{
          display: "flex",
          alignItems: {
            xs: "flex-start",
            sm: "flex-start",
            md: "flex-start",
            lg: "center",
            xl: "center",
          },
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "200px",
              xl: "200px",
            },
            marginRight: { xs: "0", sm: "0", md: "0", lg: "16px", xl: "16px" },
          }}
        >
          Color Lighting
        </Typography>
        <FormControl fullWidth sx={{ flex: 1 }}>
          {/* <Select
            value={colorAndLighting}
            onChange={handleVideoColorAndLighting}
          >
            {colorAndLightings.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select> */}
          <Autocomplete
            value={colorAndLighting}
            onChange={handleVideoColorAndLighting}
            options={colorAndLightings}
            renderInput={(params) => (
              <TextField
            {...params}
            placeholder="Search..."
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "white", fontSize: "2.5rem" }} />
                </InputAdornment>
              ),
              style: {
                color: "white",
                fontSize: "2rem", // Increase the font size of the input text
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
                fontSize: "2rem", // Increase the font size of the placeholder text
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
              "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                color: "white",
              },
            }}
          />
            )}
            ListboxProps={{
              sx: {
                "& .MuiAutocomplete-option": {
                  fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                  color: "black", // Set text color for the options
                  "&[aria-selected='true']": {
                    backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                  },
                  "&:hover": {
                    backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                  },
                },
              },
            }}
          />
        </FormControl>
      </ListItem>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={{ xs: 1, sm: 1, md: 2 }}
        useFlexGap
        sx={{ marginTop: "16px" }}
      >
        <Button
          id="basic-button"
          variant="outlined"
          aria-haspopup="true"
          size="large"
          style={{
            border: "1px solid white",
            backgroundColor: "transparent",
            color: "white",
            fontSize: "16px",
            borderRadius: "20px",
            "&:hover": {  
              backgroundColor: "white",
              color: "#011636",
            },
          }}
          onClick={onSubmit}
        >
          Animate
        </Button>
      </Stack>
    </List>
  );
};

export default VideoSettingsDrawer;