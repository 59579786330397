import { useState, Fragment } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import SettingsIcon from "@mui/icons-material/Settings";

const SettingsMenu = ({
  handleMultilingual,
  isMultilingual,
  handleInternetQuery,
  internetQuery,
  onUploadToGpt,
  documentButtons,
  handlePromptModal,
  promptModalOpen
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [options, setOptions] = useState({
    option1: documentButtons,
    option2: isMultilingual,
    option3: internetQuery,
    option4: promptModalOpen,
    // option5: false,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionChange = (option) => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
    if (option === "option1") {
      onUploadToGpt();
    } else if (option === "option2") {
      handleMultilingual();
    } else if (option === "option3") {
      handleInternetQuery();
    } else if (option === "option4") {
      handlePromptModal();
    } 
    // else if (option === "option5") {
    // }
  };

  return (
    <Fragment>
      <IconButton
        aria-label="settings"
        aria-controls="settings-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          color: "white",
        }}
      >
        <SettingsIcon
          style={{
            fontSize: "30px",
            alignSelf: "center",
          }}
        />
      </IconButton>
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleOptionChange("option1")}>
          <Checkbox checked={options.option1} sx={{color: "#011636"}} /> Document Wizard
        </MenuItem>
        <MenuItem onClick={() => handleOptionChange("option2")}>
          <Checkbox checked={options.option2} sx={{color: "#011636"}} /> Multi-language Support
        </MenuItem>
        <MenuItem onClick={() => handleOptionChange("option3")}>
          <Checkbox checked={options.option3} sx={{color: "#011636"}} /> Web Search Support
        </MenuItem>
        {/* <MenuItem onClick={() => handleOptionChange("option4")}>
          <Checkbox checked={options.option4} /> Prompt Engine
        </MenuItem> */}
        {/* <MenuItem onClick={() => handleOptionChange("option5")}>
          <Checkbox checked={options.option5} /> Voice Engine
        </MenuItem> */}
      </Menu>
    </Fragment>
  );
};

export default SettingsMenu;
