import { useState, useEffect } from "react";
import {
  List,
  ListItem,
  MenuItem,
  FormControl,
  Select,
  Typography,
  TextField,
  Button,
  Stack,
  Slider,
  Checkbox,
  Divider,
} from "@mui/material";
import {
  imageAspectRatios,
  imageArtStyles,
  colorSchemes,
  tones,
} from "utils/constants.js";

const ImageFromTextDrawer = ({
  handleSlowerImageGen,
  handleFasterImageGen,
}) => {
  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        flexWrap="wrap"
        spacing={{ xs: 1, sm: 1, md: 2 }}
        useFlexGap
      >
        <div>
          <Button
            id="basic-button-1"
            variant="outlined"
            aria-haspopup="true"
            size="large"
            style={{
              border: "1px solid white",
              backgroundColor: "#011636",
              color: "white",
              fontSize: "16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              borderRadius: "20px", 
              "&:hover": {
                backgroundColor: "#FFFFFF",
                borderColor: "#FFFFFF",
                color: "#011636",
                opacity: 0.9,
              },
            }}
            onClick={handleFasterImageGen}
          >
            Image Model 1
          </Button>
          <Typography
            style={{
              color: "#FFFFFF",
              textAlign: "center",
              fontSize: "12px",
              marginTop: "8px",
            }}
          >
            Super fast but Less Detailed
          </Typography>
        </div>

        <div>
          <Button
            id="basic-button-2"
            variant="outlined"
            aria-haspopup="true"
            size="large"
            style={{
              border: "1px solid white",
              backgroundColor: "#011636",
              color: "white",
              fontSize: "16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              borderRadius: "20px", 
              "&:hover": {
                backgroundColor: "#FFFFFF",
                borderColor: "#FFFFFF",
                color: "#011636",
                opacity: 0.9,
              },
            }}
            onClick={handleSlowerImageGen}
          >
            Image model 2
          </Button>
          <Typography
            style={{
              color: "#FFFFFF",
              textAlign: "center",
              fontSize: "12px",
              marginTop: "8px",
            }}
          >
            Slower but More Detailed
          </Typography>
        </div>
      </Stack>
    </div>
  );
};

export default ImageFromTextDrawer;
