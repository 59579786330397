import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Autocomplete,
  TextField,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MutliFileUploader from "components/FileUploader/MultipleFileUploader";
import { FileProvider } from "context/File";
import PersonaSelectContainer from "components/Common/Persona/PersonaSelector";
import BackgroundAnimation from "components/Background/BackgroundAnimations";
import { domains } from "utils/constants";
import { useAuth } from "hooks/User";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { useMediaQuery } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AuthContext } from "context/User";
import { generateUniqueNumber } from "utils/helper";

const GlassmorphicBox = styled(Box)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.1)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  backdropFilter: "blur(10px)",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  borderRadius: "5px",
  width: "50vw",
  color: "white",
  cursor: "pointer",
  transition: "background 0.3s ease, border-color 0.3s ease",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0)",
  color: "white",
  border: "2px solid rgba(255, 255, 255, 0.3)",
  borderRadius: "25px",
  padding: "5px 10px",
  width: "150px",
  fontSize: "16px", // Adjust the font size as needed
  transition: "background 0.3s ease, border-color 0.3s ease",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.2)",
    borderColor: "rgba(255, 255, 255, 0.5)",
  },
}));

const Train = () => {
  const sessionContext = useAuth();
  const { user } = useContext(AuthContext);
  const chatId = user.userId + "t" + generateUniqueNumber();
  const [domain, setDomain] = useState(sessionContext.selectedDomain);
  const isSmallScreen = useMediaQuery("(max-width:1200px)");
  const isIpad = useMediaQuery(
    "(min-width: 1024px) and (orientation: portrait)"
  );

  useEffect(() => {
    const debounceNavigate = debounce(() => {
      console.log("Redirecting to dashboard");
    }, 1000);

    window.receiveToken = function (data) {
      console.log(data);
      console.log(headers);
      const version = headers["Version"] || "";
      if (version === process.env.REACT_APP_MAPP_VERSION) {
        console.log("domain setting");
        Cookies.set("user", JSON.stringify(loggedInUser), {
          path: "/",
          domain: "localhost",
        });
        Cookies.set("DankUser", JSON.stringify(loggedInUser), {
          path: "/",
          domain: "localhost",
        });
        debounceNavigate();
      } else {
        console.error("Version mismatch:", version);
      }
    };

    function debounce(func, timeout = 300) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    }
  }, []);

  const handleDomainChange = (event, value) => {
    console.log(value);
    
    const label = value?.label ?? "";
    sessionContext.setDomain(label);
    setDomain(label);
  };

  return (
    <BackgroundAnimation>
      <div style={{ overflowY: "auto", maxHeight: "100vh" }}>
        <Box
          sx={{ position: "fixed", top: "20px", left: "20px", zIndex: 1200 }}
        >
          <Link to="/dashboard">
            <IconButton aria-label="back">
              <ArrowBackIcon
                style={{ width: "2em", height: "2em", color: "white" }}
              />
            </IconButton>
          </Link>
        </Box>
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <img src="Logo.png" alt="Logo" className="logo" />
        </Box>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <FileProvider>
            {isSmallScreen ? (
              <GlassmorphicBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  maxWidth: "90vw",
                  m: "25px auto",
                  mt: "20vh",
                  borderRadius: "40px",
                  minHeight: isIpad ? "30vh" : "60vh",
                  paddingTop: "0px", // Add padding to move the content up
                  paddingBottom: "20px",
                }}
              >
                <Box sx={{ marginBottom: "0.5vh" }}>
                  {" "}
                  {/* Adjust margin to fine-tune the position */}
                  <h1
                    style={{ color: "white", fontFamily: "Inter, sans-serif" }}
                  >
                    <b>Train</b>
                  </h1>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "80vw",
                      flexDirection: "row",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        width: "40vw",
                      }}
                    >
                      <>Select Domain</>
                      <Autocomplete
                        disablePortal
                        options={domains}
                        value={domain}
                        onChange={handleDomainChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Domain"
                            sx={{
                              bgcolor: "rgba(255, 255, 255, 0.1)",
                              borderRadius: "5px",
                              "& .MuiInputBase-input": {
                                color: "white",
                                fontSize: "1.2rem",
                              }, // Set the input text color and size
                              "& .MuiInputLabel-root": {
                                color: "white",
                                fontSize: "1.2rem",
                              }, // Set the label (placeholder) color and size
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(255, 255, 255, 0.3)",
                              }, // Outline color
                            }}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon
                                    style={{
                                      color: "rgba(255, 255, 255, 0.5)",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            
                          />
                        )}
                        ListboxProps={{
                          sx: {
                            "& .MuiAutocomplete-option": {
                              fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                              color: "black", // Set text color for the options
                              "&[aria-selected='true']": {
                                backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                              },
                              "&:hover": {
                                backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                              },
                            },
                          },
                        }}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        width: "40vw",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <>Pick a Persona</>
                      <PersonaSelectContainer />
                    </Box>
                  </Box>
                  <MutliFileUploader task={"TRAIN"} />
                </Box>
              </GlassmorphicBox>
            ) : (
              <GlassmorphicBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  maxWidth: "70vw",
                  m: "25px auto",
                  mt: "150px",
                  borderRadius: "40px",
                  minHeight: "60vh",
                  paddingTop: "0px", // Add padding to move the content up
                  paddingBottom: "20px",
                }}
              >
                <Box sx={{ marginBottom: "20px" }}>
                  {" "}
                  {/* Adjust margin to fine-tune the position */}
                  <h1
                    style={{ color: "white", fontFamily: "Inter, sans-serif" }}
                  >
                    <b>Train</b>
                  </h1>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 8 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                      width: "20vw",
                      mt: 2,
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      <h4>Select Domain</h4>
                      <Autocomplete
                        disablePortal
                        options={domains}
                        value={domain}
                        onChange={handleDomainChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Domain"
                            sx={{
                              bgcolor: "rgba(255, 255, 255, 0.1)",
                              borderRadius: "5px",
                              "& .MuiInputBase-input": {
                                color: "white",
                                fontSize: "1.2rem",
                              }, // Set the input text color and size
                              "& .MuiInputLabel-root": {
                                color: "white",
                                fontSize: "1.2rem",
                              }, // Set the label (placeholder) color and size
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(255, 255, 255, 0.3)",
                              }, // Outline color
                            }}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon
                                    style={{
                                      color: "rgba(255, 255, 255, 0.5)",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                        ListboxProps={{
                          sx: {
                            "& .MuiAutocomplete-option": {
                              fontSize: "1.5rem", // Increase the font size of the options in the dropdown
                              color: "black", // Set text color for the options
                              "&[aria-selected='true']": {
                                backgroundColor: "rgba(0, 123, 255, 0.12)", // Option hover/selected color
                              },
                              "&:hover": {
                                backgroundColor: "rgba(0, 123, 255, 0.08)", // Option hover effect
                              },
                            },
                          },
                        }}
                      />
                    </Box>

                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap:2 }}
                    >
                      <h4>Pick a Persona</h4>
                      <PersonaSelectContainer />
                    </Box>
                  </Box>
                  <MutliFileUploader task={"TRAIN"} />
                </Box>
              </GlassmorphicBox>
            )}
          </FileProvider>
          <Box sx={{ mt: 1, textAlign: "center" }}>
            <Link to={`/c/${chatId}`}>
              <StyledButton variant="contained">Skip</StyledButton>
            </Link>
          </Box>
        </div>
      </div>
    </BackgroundAnimation>
  );
};

export default Train;
