import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import CustomSnackbar from "components/Common/Snackbar/Snackbar";
import { useFileContext } from "hooks/File";
import { generateUniqueNumber } from "utils/helper";
import { useMediaQuery } from "@mui/material";

const glassmorphicStyle = {
  background: "rgba(255, 255, 255, 0.0)",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  borderRadius: "10px",
  background: "rgba(255, 255, 255, 0.1)",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  color: "white",
};

const buttonHoverStyle = {
  background: "rgba(255, 255, 255, 0.2)",
  borderColor: "rgba(255, 255, 255, 0.5)",
};

const MutliFileUploader = (props) => {
  const [dragging, setDragging] = useState(false);
  const fileHandler = useFileContext();
  const [files, setFiles] = useState([]);
  let navigate = useNavigate();
  const isSmallScreen = useMediaQuery(
    "(max-width: 768px) and (orientation: portrait)"
  );
  const isIpad = useMediaQuery(
    "(min-width: 1024px) and (orientation: portrait)"
  );
  const isShortPhone = useMediaQuery(
    "(max-width: 768px) and (max-height: 730px)"
  );
  const isLandscape = useMediaQuery(
    "(max-width: 1000px) and (orientation: landscape)"
  );

  const handleHover = (isHovering) => {
    const event = new CustomEvent("animateGradient", { detail: isHovering });
    window.dispatchEvent(event);
  };

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  }, []);

  const handleDragOver = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!dragging) {
        setDragging(true);
      }
    },
    [dragging]
  );

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles && droppedFiles.length > 0) {
      const docxFiles = Array.from(droppedFiles).filter(
        (file) =>
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      );
      if (docxFiles.length > 0) {
        setFiles((prevFiles) => [...prevFiles, ...docxFiles]);
      } else {
        alert("Only .docx files are allowed");
      }
    }
  }, []);

  const handleFileSelect = useCallback((e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const docxFiles = Array.from(selectedFiles).filter(
        (file) =>
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      );
      if (docxFiles.length > 0) {
        setFiles((prevFiles) => [...prevFiles, ...docxFiles]);
      } else {
        alert("Only .docx files are allowed");
      }
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (files.length > 0) {
      try {
        const formData = new FormData();
        files.forEach((file, index) => formData.append(`files`, file));
        formData.append("userId", await getUserId());

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + (await getToken()),
            },
          }
        );

        // Handle the response here
        
      } catch (error) {
        console.error("Error during file upload:", error);
      } finally {
        console.log("Redirecting to dashboard");
        navigate("/c?t=1");
      }
    } else {
      alert("Please select a file first.");
    }
  };

  return (
    <>
      {isSmallScreen ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "80vw",
            height: "230px",
            margin: "auto",
            borderRadius: "40px",
            background: "rgba(255, 255, 255, 0.1)",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
            overflowY: "auto", // Ensure overflow scrolls if content exceeds height
            position: "relative",
          }}
        >
          <div
            className={`dropzone ${dragging ? "dragging" : ""}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
            style={{
              border: dragging ? "2px dashed #333" : "2px dashed transparent",
              borderRadius: "10px",
              padding: "1px",
              width: "75vw",
              height: "206px",
              margin: "0 auto",
              transition: "border-color 0.3s ease",
              position: "relative",
              overflow: "hidden",
            }}
          >
            {files.length > 0 ? (
              <div
                className="ddtext"
                style={{ color: "white", overflowY: "auto" }}
              >
                Files selected: {files.map((file) => file.name).join(", ")}
              </div>
            ) : (
              <div
                className="ddtext"
                style={{ color: "white", overflowY: "auto" }}
              >
                Drag and drop .docx files here
              </div>
            )}
            <div className="ddtext-2" style={{ color: "white" }}>
              OR
            </div>
            <form onSubmit={handleSubmit}>
              <div style={{ width: "100%" }}>
                <input
                  type="file"
                  id="file-upload"
                  accept=".docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  className="ai-button"
                  onChange={handleFileSelect}
                  multiple
                  required={files.length === 0}
                  style={{
                    ...glassmorphicStyle,
                    // padding: '10px',
                    width: "70vw",
                    height: "6vh",
                    textAlign: "center",
                    marginBottom: "0.5vh",
                  }}
                />
              </div>
              <button
                type="submit"
                style={{
                  ...glassmorphicStyle,
                  padding: "10px 20px",
                  ...buttonHoverStyle,
                  width: "50%",
                  textAlign: "center",
                  borderRadius: "20px",
                  color: "white",
                }}
              >
                Go To Editor
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: isLandscape ? "80vw" : isIpad ? "80vw" : "40vw",
            height: isIpad ? "25vh" : "35vh",
            margin: "auto",
            borderRadius: "40px",
            background: "rgba(255, 255, 255, 0.1)",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
            overflowY: "auto", // Ensure overflow scrolls if content exceeds height
            position: "relative",
          }}
        >
          <div
            className={`dropzone ${dragging ? "dragging" : ""}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
            style={{
              border: dragging ? "2px dashed #333" : "2px dashed transparent",
              borderRadius: "10px",
              padding: "1px",
              width: isLandscape ? "70vw" : "40vw",
              height: isIpad ? "25vh" : "206px",
              margin: "0 auto",
              transition: "border-color 0.3s ease",
              position: "relative",
              overflow: "hidden",
            }}
          >
            {files.length > 0 ? (
              <div
                className="ddtext"
                style={{ color: "white", overflowY: "auto" }}
              >
                Files selected: {files.map((file) => file.name).join(", ")}
              </div>
            ) : (
              <div
                className="ddtext"
                style={{ color: "white", overflowY: "auto" }}
              >
                Drag and drop .docx files here
              </div>
            )}

            <div className="ddtext-2" style={{ color: "white" }}>
              OR
            </div>

            <form onSubmit={handleSubmit}>
              {/* Centered Choose File Section */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%", // Full width to ensure central alignment
                  marginBottom: isLandscape ? "2vh" : "3vh", // Space below the file input
                }}
              >
                <input
                  type="file"
                  id="file-upload"
                  accept=".docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  className="ai-button"
                  onChange={handleFileSelect}
                  multiple
                  required={files.length === 0}
                  style={{
                    ...glassmorphicStyle,
                    width: isLandscape ? "45vw" : "100%", // Responsiveness for file input
                    height: isLandscape ? "10vh" : "6vh",
                    textAlign: "center",
                    // marginBottom: isLandscape ? "1.5vh" : "3vh",
                    padding: "10px",
                  }}
                />
              </div>

              {/* Centered Go To Editor Button */}
              <button
                type="submit"
                style={{
                  ...glassmorphicStyle,
                  padding: "1vh 1.5vh",
                  ...buttonHoverStyle,
                  width: isLandscape ? "30%" : "50%",
                  height: isLandscape ? "12vh" : isIpad  ? "3vh":"5vh",
                  textAlign: "center",
                  borderRadius: "20px",
                  color: "white",
                }}
              >
                Go To Editor
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default MutliFileUploader;
