import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import "./Paint.css";
import PaintApp from "components/Canvas/Canvas";
import { IconButton, Tooltip } from "@mui/material";
import UpgradeSharpIcon from "@mui/icons-material/UpgradeSharp";
import SaveIcon from "@mui/icons-material/Save";
import { UploadSourceContext } from "context/UploadSource";
import { AuthContext } from "context/User";
import { useQueryParams } from "hooks/GetParams";
import { generateUniqueNumber } from "utils/helper";
import DownloadIcon from "@mui/icons-material/Download";
import CustomSnackbar from "components/Common/Snackbar/Snackbar";
import BackgroundAnimation from "components/Background/BackgroundAnimations";



const Canvas = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarContent, setSnackbarContent] = useState("");
  const { setUploadSource } = useContext(UploadSourceContext);
  const { user } = useContext(AuthContext);

  const [token, setToken] = useState(user.token);

  const { chatId } = useParams();

  const [currentUserId, setCurrentUserId] = useState(user.userId);

  const [currentChatId, setCurrentChatId] = useState(
    chatId ? chatId : currentUserId + "t" + generateUniqueNumber()
  );

  const exportCanvasAsImage = () => {
    try {
      const canvas = document.querySelector("canvas");
      if (!canvas) {
        console.error("Canvas element not found");
        return;
      }

      const dataURL = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.download = "canvas.png";
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setSnackbarSeverity("success");
      setSnackbarContent("Canvas exported successfully.");
    } catch (error) {
      console.error("Error exporting canvas as image:", error);
      setSnackbarSeverity("error");
      setSnackbarContent("Canvas export failed. Please try again.");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const saveCanvasImage = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}store-art`;
      const formData = new FormData();
      formData.append("userId", currentUserId);
      formData.append("chatId", currentChatId);
      const canvas = document.querySelector("canvas");

      // Convert canvas to blob and append to FormData
      canvas.toBlob((blob) => {
        formData.append("images", blob, "image.jpg");

        axios({
          method: "post",
          url: url,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        })
          .then((response) => console.log("Response:", response.data))
          .catch((error) => console.error("Error during API call:", error));
      }, "image/jpeg");

      setSnackbarSeverity("success");
      setSnackbarContent(
        <span>
          Canvas saved as an image successfully. Click{" "}
          <a
            href={`/c/library/${chatId}/04_PicassoAI`}
            style={{ color: "red" }}
          >
            here
          </a>{" "}
          to view
        </span>
      );
    } catch {
      console.error("Error exporting canvas as image:", error);
      setSnackbarSeverity("error");
      setSnackbarContent("Saving canvas failed. Please try again.");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleExportButtonClick = () => {
    setUploadSource("export");
    exportCanvasAsImage();
  };

  const handleSaveButtonClick = () => {
    setUploadSource("save");
    saveCanvasImage();
  };

  return (
    <BackgroundAnimation>
      <Stack
  direction="row"
  spacing={1}
  alignItems="center"
  justifyContent="space-between"
  useFlexGap
  className="go-back"
  padding={2}
  sx={{
    height: "10vh",
    background: "rgba(255, 255, 255, 0.1)", // semi-transparent white
    backdropFilter: "blur(10px)", // blur effect
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", // subtle shadow
    // borderRadius: "15px", // rounded corners
    // border: "1px solid rgba(255, 255, 255, 0.3)", // light border
  }}
>
  <Link to={`/c/${chatId}`}>
    <ArrowBackIcon style={{ color: "white", fontSize: 30 }} />
  </Link>
  <Typography
    variant="h2"
    component="div"
    sx={{ flexGrow: 1, color: "white" }}
  >
    Picasso AI
  </Typography>
  {/* Export as PNG Button */}
  <Tooltip title="Download as PNG" arrow>
    <IconButton onClick={handleExportButtonClick} sx={{ color: "white" }}>
      <DownloadIcon fontSize="large" style={{ fontSize: 30 }} />
    </IconButton>
  </Tooltip>
  {/* Save as PNG Button */}
  <Tooltip title="Export and Save as PNG" arrow>
    <IconButton onClick={handleSaveButtonClick} sx={{ color: "white" }}>
      <SaveIcon fontSize="large" style={{ fontSize: 30 }} />
    </IconButton>
  </Tooltip>
</Stack>

      <PaintApp background="#FFFFFF" />
      <CustomSnackbar
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
        snackbarSeverity={snackbarSeverity}
        snackbarContent={snackbarContent}
      />
    </BackgroundAnimation>
  );
};

export default Canvas;