import React, { useState, useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import { styled } from '@mui/material/styles';

const GlassmorphicContainer = styled('div')(({ theme }) => ({
  padding: '10px',
  borderRadius: '16px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)', // Glassmorphic background
  backdropFilter: 'blur(10px)', // Glassmorphic effect
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  color: 'white',
}));

const GlassmorphicButton = styled('button')(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  color: 'white',
  border: 'none',
  borderRadius: '8px',
  padding: '10px 20px',
  cursor: 'pointer',
  fontSize: '1rem',
  marginTop: '10px',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  '&:disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    cursor: 'not-allowed',
  },
}));

function AudioPlayer({
  audioData,
  audioDataForDownload,
  handleAudioDownload,
}) {
  const [audioPlayerState, setAudioPlayerState] = useState({
    audioSource: "",
    audioBlobURL: null,
    playerType: "audio",
    autoPlay: false,
    isReady: false,
    isStreaming: false,
  });

  useEffect(() => {
    if (typeof audioData === "string") {
      const isStreaming = audioData.startsWith("http://") || audioData.startsWith("https://c");
const isAudio = audioData.startsWith("https://w");
      
      
      if (isStreaming) {
        
        setAudioPlayerState((prevState) => ({
          ...prevState,
          audioSource: audioData,
          playerType: "audio",
          autoPlay: false,
          isReady: true,
          isStreaming: true,
        }));
      } else if (isAudio) {
        
        setAudioPlayerState((prevState) => ({
          ...prevState,
          audioSource: audioData,
          playerType: "audio",
          autoPlay: false,
          isReady: true,
          isStreaming: false,
        }));
      } else {
        
        const audioBlob = new Blob([Buffer.from(audioData, "base64")], {
          type: "audio/mp3",
        });
        const audioURL = URL.createObjectURL(audioBlob);
        setAudioPlayerState((prevState) => ({
          ...prevState,
          audioSource: audioURL,
          audioBlobURL: audioURL,
          playerType: "audio",
          autoPlay: false,
          isReady: true,
          isStreaming: false,
        }));
      }
    }

    return () => {
      if (audioPlayerState.audioBlobURL) {
        URL.revokeObjectURL(audioPlayerState.audioBlobURL);
      }
    };
  }, [audioData]);

  const handleAudioEnd = () => {
    
    setAudioPlayerState((prevState) => ({
      ...prevState,
      isReady: true,
      isStreaming: false,
    }));
  };

  const handleError = (e) => {
    console.error("Error playing audio:", e);
    setAudioPlayerState((prevState) => ({
      ...prevState,
      isReady: false,
      isStreaming: false,
    }));
  };

  

  return (
    <>
      <>
      {audioData && (
  audioPlayerState.playerType === "audio" ? (
    <ReactAudioPlayer
      src={audioPlayerState.audioSource}
      controls
      autoPlay={false}  // Ensure autoplay is disabled
      onEnded={handleAudioEnd}
      onError={handleError}
    />
  ) : (
    <ReactPlayer
      url={audioPlayerState.audioSource}
      playing={false}  // Prevent autoplay
      controls={true}
      width="100%"
      height="auto"
      onEnded={handleAudioEnd}
      onError={handleError}
    />
  )
)}

         <div className="download-buttons">
        <button
          className="download-button"
          onClick={() => handleAudioDownload(audioDataForDownload)}
          disabled={!audioPlayerState.isReady}
        >
          Download
        </button>
      </div>
        
      </>
     
    </>
  );
}

export default AudioPlayer;
